import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { InferType, object, string } from 'yup';
import FormTextarea from '~/components/form/FormTextarea';
import i18n from '~/i18n';

const requiredMessage = i18n.t('validation.required');

export const reviewSchema = object({
  text: string().required(requiredMessage),
});

export type PublicReviewFormValues = InferType<typeof reviewSchema>;

export const defaultValues: PublicReviewFormValues = {
  text: '',
};

type PublicReviewFormProps = {
  isDisabled?: boolean;
  onSubmit: (values: PublicReviewFormValues) => void;
};

export default function PublicReviewForm({ isDisabled, onSubmit }: PublicReviewFormProps) {
  const { t } = useTranslation();

  const { control, handleSubmit } = useForm<PublicReviewFormValues>({
    defaultValues,
    resolver: yupResolver<PublicReviewFormValues>(reviewSchema),
    shouldFocusError: true,
  });

  const submit = async (values: PublicReviewFormValues) => {
    onSubmit(values);
  };

  return (
    <div className="flex justify-center">
      <div className="flex flex-col gap-6 w-full xl:w-4/6">
        <div className="text-xl text-white">{t('publicReviews.letUsKnow')}</div>

        <form
          className="flex flex-col gap-3 p-4 bg-primary rounded-lg"
          onSubmit={handleSubmit(submit)}
        >
          <FormTextarea
            control={control}
            name="text"
            label={t('publicReviews.yourFeedback')}
            placeholder={t('publicReviews.feedback')}
            rows={5}
            maxLength={300}
          />

          <button
            className="btn-rounded h-12 w-fit bg-pink transition duration-150 ease-linear hover:bg-[#C60D4F] px-6"
            disabled={isDisabled}
            type="submit"
          >
            {t('publicReviews.post')}
          </button>
        </form>
      </div>
    </div>
  );
}
