import classnames from 'classnames';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useSearchParams } from 'react-router-dom';
import { DropdownConfig } from '~/components/Dropdown/DropdownConfig';
import { Icon } from '~/components/Icon/Icon';
import { localStorageKeys } from '~/config/consts';
import { LanguageType, languages } from '~/i18n/i18nConfig';

export function LangDropdown() {
  const dropdownElementRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<DropdownConfig | null>(null);

  const { i18n } = useTranslation();
  const currentLanguage = i18n.language as LanguageType;

  const changeLanguage = (language: LanguageType) => {
    i18n.changeLanguage(language);
    localStorage.setItem(localStorageKeys.language, language);
    dropdownRef.current?.onlyClose();
  };

  useEffect(() => {
    if (dropdownElementRef.current) {
      dropdownRef.current = new DropdownConfig(dropdownElementRef.current);
    }
  }, [dropdownElementRef]);

  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (dropdownRef.current) {
      dropdownRef.current.onlyClose();
    }
  }, [pathname, searchParams]);

  const flagMap = {
    lt: 'lt',
    en: 'gb',
    uk: 'ua',
    ru: 'ru',
    pl: 'pl',
  };

  const getFlag = (lang: LanguageType) => flagMap[lang] || lang;

  return (
    <div className="dropdown relative z-[99]" ref={dropdownElementRef}>
      <button className="dropdown-toggle dropdown-header inline-flex items-center justify-center gap-1.5 rounded-lg bg-primary px-3.5 py-2.5 !text-white">
        <Icon alt="Globe" icon="icon-language" className="h-3.5 w-3.5 text-[#999999]" />
        <span className="text-sm font-semibold">{currentLanguage.toUpperCase()}</span>
        <Icon
          alt="caret"
          id="caret"
          icon="icon-caret"
          className="h-2.5 w-2.5 text-[#999999] dropdown-caret"
        />
      </button>
      <ul className="dropdown-content dropdown-content absolute z-50 hidden w-full flex-col justify-center rounded-b-lg bg-secondary pb-2 shadow-[0px_0px_30px_rgba(0,0,0,0.25)]">
        {languages.map((language) => (
          <li key={language}>
            <button
              onClick={() => changeLanguage(language)}
              className={classnames(
                'inline-flex w-full flex-1 items-center justify-center gap-2 bg-secondary py-1.5 px-3.5 text-white transition duration-150 ease-linear hover:bg-third',
                {
                  active: currentLanguage === language,
                },
              )}
            >
              <span className={`fi fi-${getFlag(language)} h-3.5 w-3.5`} />
              <span className="text-sm font-semibold">{language.toUpperCase()}</span>
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
}
