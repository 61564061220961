import { toast } from 'material-react-toastify';
import { useNavigate } from 'react-router-dom';
import { prepareFormDataForImages } from '~/features/ads/utils/images';
import { reviewModel } from '~/models/reviewModel/reviewModel';
import { ROUTES } from '~/router/Routes';
import ReviewForm from '../ReviewForm/ReviewForm';
import { ReviewFormValues } from '../ReviewForm/reviewSchema';
import { RateTime } from '~/api/data-contracts';
import { useTranslation } from 'react-i18next';

export default function CreateReview() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleSubmit = async (values: ReviewFormValues) => {
    try {
      const { id } = await reviewModel.createReviewExternal({
        currencyNo: values.currencyNo,
        description: values.description,
        externalUrl: values.externalUrl || '',
        locationNo: values.locationNo,
        name: values.name,
        rates: values.rates
          .filter((rate) => rate?.incall && rate?.time)
          .map((rate) => ({
            incall: Number(rate.incall),
            time: rate.time || RateTime.HalfAHour,
          })),
        sections: values.sections
          .filter((section) => section?.rate && section.sectionNo)
          .map((section) => ({
            rate: section.rate!,
            sectionNo: section.sectionNo!,
          })),
      });

      await reviewModel.reviewImages(id, prepareFormDataForImages(values.files));

      toast.success(t('reviews.createReviewSuccess'));
      navigate(ROUTES.REVIEW_LIST_PERSONAL);
    } catch (e) {
      toast.error(t('general.error'));
    }
  };

  const defaultValues: ReviewFormValues = {
    externalUrl: '',
    countryNo: 0,
    currencyNo: 0,
    description: '',
    files: [],
    locationNo: 0,
    name: '',
    rates: [],
    sections: [],
  };

  return (
    <ReviewForm
      title={t('reviews.createReview')}
      defaultValues={defaultValues}
      onSubmit={handleSubmit}
    />
  );
}
