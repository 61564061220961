import { useTranslation } from 'react-i18next';
import { ReactComponent as TwoFactor } from '~/assets/twoFactor.svg';

type SetupSuccessProps = {
  message: string;
  onClose: () => void;
};

export default function SetupSuccess({ message, onClose }: SetupSuccessProps) {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col justify-center items-center">
      <TwoFactor className="w-[170px] h-[170px] mt-14 mb-7" />

      <div className="w-full p-3.5 text-center text-2xl text-white">
        {t('twoFactor.confirmationSuccess')}
      </div>

      <p className="text-gray text-center mb-3.5 lg:w-[560px]">{message}</p>

      <div className="flex w-full p-4">
        <button
          type="button"
          className="!h-[40px] btn-rounded border-transparent bg-pink transition duration-150 ease-linear hover:bg-[#C60D4F]"
          onClick={onClose}
        >
          {t('twoFactor.close')}
        </button>
      </div>
    </div>
  );
}
