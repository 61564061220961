import { useMutation } from '@tanstack/react-query';
import { toast } from 'material-react-toastify';
import { useEffect, useState } from 'react';
import { Loader } from '~/components/Loader/Loader';
import { prepareFormDataForImages } from '~/features/ads/utils/images';
import { reviewModel } from '~/models/reviewModel/reviewModel';
import { getReviewImageUrl } from '../../utils/images';
import ReviewForm from '../ReviewForm/ReviewForm';
import { ReviewFormValues } from '../ReviewForm/reviewSchema';
import { RateTime } from '~/api/data-contracts';
import { useTranslation } from 'react-i18next';

type EditReviewProps = {
  id: string;
};

export default function EditReview(props: EditReviewProps) {
  const { t } = useTranslation();
  const [defaultValues, setDefaultValues] = useState<ReviewFormValues | null>(null);
  const { mutate: reviewActivate } = useMutation(reviewModel.reviewActivate);
  const { mutate: reviewDeactivate } = useMutation(reviewModel.reviewDeactivate);

  const loadReview = async () => {
    const review = await reviewModel.reviewDetailedPersonal(props.id);
    const rates = await reviewModel.reviewRates();
    const sections = await reviewModel.reviewSections();

    const values: ReviewFormValues = {
      adId: review.ad.internalAdId || '',
      status: review.status,
      description: review.description,
      countryNo: review.location.countryNo || 0,
      currencyNo: review.currency.currencyNo || 0,
      locationNo: review.location.townNo || 0,
      name: review.name,
      externalUrl: review.ad.externalAdUrl || '',
      files: review.images.map((file) => ({
        file,
        url: getReviewImageUrl(props.id, file),
      })),
      sections:
        sections.items?.map((section) => {
          const found = review.sections.find((s) => s.sectionNo === section.no);

          if (found) {
            return {
              rate: found.rate || 0,
              sectionNo: section.no || 0,
            };
          }

          return {
            rate: 0,
            sectionNo: section.no || 0,
          };
        }) || [],
      rates: rates.types.map((rate) => {
        const found = review.rates.find((r) => r.rateTime === rate);

        if (found) {
          return {
            incall: found.incall ? found.incall.toString() : '',
            outcall: found.outcall ? found.outcall.toString() : '',
            time: rate,
            isChecked: true,
          };
        }

        return {
          time: rate,
          incall: '',
        };
      }),
    };
    setDefaultValues(values);
  };

  const handleSubmit = async (values: ReviewFormValues) => {
    try {
      await reviewModel.editReview(props.id, {
        currencyNo: Number(values.currencyNo),
        description: values.description,
        locationNo: Number(values.locationNo),
        name: values.name,
        rates: values.rates
          .filter((rate) => rate?.incall && rate.time)
          .map((rate) => ({
            incall: Number(rate.incall),
            time: rate.time || RateTime.HalfAHour,
          })),
        sections: values.sections
          .filter((section) => section?.rate && section.sectionNo)
          .map((section) => ({
            rate: section.rate!,
            sectionNo: section.sectionNo!,
          })),
      });

      await reviewModel.reviewImages(props.id, prepareFormDataForImages(values.files));

      loadReview();

      toast.success(t('reviews.editReviewSuccess'));
    } catch (e) {
      toast.error(t('general.error'));
    }
  };

  const handleActivateClick = async () => {
    reviewActivate(props.id, {
      onSuccess: async () => {
        await loadReview();
        toast.success(t('reviews.activateSuccess'));
      },
      onError: () => toast.error(t('general.error')),
    });
  };

  const handleDeactivateClick = async () => {
    reviewDeactivate(props.id, {
      onSuccess: async () => {
        await loadReview();
        toast.success(t('reviews.deactivateSuccess'));
      },
      onError: () => toast.error(t('general.error')),
    });
  };

  useEffect(() => {
    loadReview();
  }, []);

  if (!defaultValues) {
    return <Loader />;
  }

  return (
    <ReviewForm
      title={t('reviews.editReview')}
      defaultValues={defaultValues}
      onSubmit={handleSubmit}
      onActivateClick={handleActivateClick}
      onDeactivateClick={handleDeactivateClick}
    />
  );
}
