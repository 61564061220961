import classNames from 'classnames';
import { TextareaHTMLAttributes } from 'react';
import { Controller, FieldPath, FieldValues, UseControllerProps } from 'react-hook-form';
import FormFieldError from './FormFieldError';

type FormFieldProps<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = UseControllerProps<TFieldValues, TName> & {
  onChange?: (value: string | number | null) => void;
  label?: string;
} & Omit<TextareaHTMLAttributes<HTMLTextAreaElement>, 'value' | 'onChange'>;

const FormTextarea = <
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  control,
  name,
  label,
  onChange,
  ...inputProps
}: FormFieldProps<TFieldValues, TName>) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => {
        return (
          <div>
            {label && (
              <label htmlFor={name} className="text-[#999999] text-sm pl-5">
                {label}
              </label>
            )}
            <div className="flex flex-row gap-3.5 items-center relative">
              <textarea
                id={name}
                className={classNames(
                  'form-control-input block w-full rounded-lg border-transparent border-[#272727] bg-secondary px-[20px] py-[17px] text-white placeholder:text-[#999999] focus:border-transparent',
                  {
                    '!border-red-500': fieldState.error,
                  },
                )}
                value={field.value}
                onChange={(e) => {
                  field.onChange(e.target.value);
                  onChange && onChange(e.target.value);
                }}
                ref={field.ref}
                {...inputProps}
              />
            </div>
            {fieldState.error && <FormFieldError error={fieldState.error} />}
          </div>
        );
      }}
    />
  );
};

export default FormTextarea;
