import { PropsWithChildren, createContext, useContext, useState } from 'react';
import Modal from '~/components/Modal/Modal';
import AgeVerifyModal from '~/components/modals/AgeVerifyModal';
import LoginModal from '~/components/modals/LoginModal';
import PasswordResetModal from '~/components/modals/PasswordResetModal';
import RegisterModal from '~/components/modals/RegisterModal';
import WarningModal from '~/components/modals/WarningModal';
import { MODAL_IDS } from '~/config/consts';
import BuyCreditsModal from '~/features/credits/components/BuyCreditsModal/BuyCreditsModal';
import BuyPremiumModal from '~/features/credits/components/BuyPremiumModal/BuyPremiumModal';
import TransferCreditsModal, {
  TransferCreditsModalProps,
} from '~/features/credits/components/TransferCreditsModal/TransferCreditsModal';
// import SetupTwoFactorModal from '~/features/twoFactor/components/SetupTwoFactorModal/SetupTwoFactorModal';

interface ModalContextType {
  showModal(
    id: (typeof MODAL_IDS)['transferCredits'],
    ctx: TransferCreditsModalProps['context'],
  ): void;
  showModal(id: string): void;
  hideModal: () => void;
}

export const ModalContext = createContext<ModalContextType>(undefined!);

export const ModalProvider = ({ children }: PropsWithChildren) => {
  const [current, setCurrent] = useState<string | null>(null);
  const [context, setContext] = useState<unknown | null>(null);

  const showModal = <Id extends string>(id: Id, ctx?: TransferCreditsModalProps['context']) => {
    if (current) {
      setCurrent(null);
      setTimeout(() => {
        ctx && setContext(ctx);
        setCurrent(id);
      }, 400);
    } else {
      ctx && setContext(ctx);
      setCurrent(id);
    }
  };

  const hideModal = () => {
    setCurrent(null);
    setContext(null);
  };

  return (
    <ModalContext.Provider
      value={{
        showModal,
        hideModal,
      }}
    >
      {children}
      <Modal isOpen={current === MODAL_IDS.ageVerifyModal} onClose={hideModal} dismissable={false}>
        <AgeVerifyModal />
      </Modal>

      <Modal isOpen={current === MODAL_IDS.loginModal} onClose={hideModal}>
        <LoginModal />
      </Modal>

      <Modal isOpen={current === MODAL_IDS.passwordResetModal} onClose={hideModal}>
        <PasswordResetModal />
      </Modal>

      <Modal isOpen={current === MODAL_IDS.registerModal} onClose={hideModal}>
        <RegisterModal />
      </Modal>

      <Modal isOpen={current === MODAL_IDS.warningModal} onClose={hideModal}>
        <WarningModal />
      </Modal>

      <Modal isOpen={current === MODAL_IDS.buyPremium} onClose={hideModal} size="lg">
        <BuyPremiumModal />
      </Modal>

      <Modal isOpen={current === MODAL_IDS.buyCredits} onClose={hideModal} size="lg">
        <BuyCreditsModal />
      </Modal>

      <Modal isOpen={current === MODAL_IDS.transferCredits} onClose={hideModal} size="lg">
        <TransferCreditsModal context={context as TransferCreditsModalProps['context']} />
      </Modal>
      {/* 
      <Modal isOpen={current === MODAL_IDS.setupTwoFactor} onClose={hideModal} size="lg">
        <SetupTwoFactorModal />
      </Modal> */}
      <div id="dialog-root" />
    </ModalContext.Provider>
  );
};

export const useModals = () => useContext(ModalContext);
