import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useAuth } from '~/context/AuthContext';

export const NotFound = () => {
  const { t } = useTranslation();
  const { getHomePath } = useAuth();

  return (
    <div className="py-16">
      <h1 className="text-white text-center mb-3">{t('notFoundPage.title')}</h1>
      <div className="text-white text-center">
        <Trans i18nKey="notFoundPage.mainScreen">
          Go back to
          <Link to={getHomePath()} className="link pt-5">
            Main screen
          </Link>
        </Trans>
      </div>
    </div>
  );
};
