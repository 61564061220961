import { yupResolver } from '@hookform/resolvers/yup';
import { useQuery } from '@tanstack/react-query';
import { useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AdType } from '~/api/data-contracts';
import { Icon } from '~/components/Icon/Icon';
import FormCheckbox from '~/components/form/FormCheckbox';
import FormField from '~/components/form/FormField';
import FormFiles from '~/components/form/FormFiles';
import FormNumberField from '~/components/form/FormNumberField';
import FormSelect from '~/components/form/FormSelect';
import FormTextarea from '~/components/form/FormTextarea';
import { adModel } from '~/models/adModel/adModel';
import { currencyModel } from '~/models/currencyModel/currencyModel';
import { locationModel } from '~/models/locationModel/locationModel';
import {
  AD_TYPE_OPTIONS,
  AVAILABLE_FOR_OPTIONS,
  BREAST_SIZE_OPTIONS,
  BREAST_TYPE_OPTIONS,
  ETHNICITY_OPTIONS,
  EYE_COLOR_OPTIONS,
  HAIR_COLOR_OPTIONS,
  HAIR_LENGTH_OPTIONS,
  MEETING_WITH_OPTIONS,
  ORIENTATION_OPTIONS,
  PIERCING_OPTIONS,
  PUBIC_HAIR_OPTIONS,
  SMOKER_OPTIONS,
  TRAVEL_OPTIONS,
} from '../../consts';
import { AdStatusButton } from '../AdStatusButton/AdStatusButton';
import { AdFormValues, adSchema } from './adSchema';
import FormFieldArrayError from '~/components/form/FormFieldArrayError';

type AdFormProps = {
  defaultValues: AdFormValues;
  onActivateClick?: () => Promise<void>;
  onDeactivateClick?: () => Promise<void>;
  onSubmit: (values: AdFormValues) => Promise<void>;
  refetch?: () => void;
  title: string;
  id?: string;
};

export const AdForm = ({ id, defaultValues, onSubmit, title, refetch }: AdFormProps) => {
  const { t } = useTranslation();

  const {
    control,
    handleSubmit,
    trigger,
    watch,
    formState: { errors, isSubmitting },
  } = useForm<AdFormValues>({
    defaultValues,
    resolver: yupResolver<AdFormValues>(adSchema),
    shouldFocusError: true,
  });

  const countryNo = watch('countryNo');
  const currencyNo = watch('currencyNo');

  const { data: locations } = useQuery(['locations'], () => locationModel.locations('lithuania'));
  const { data: locations2 } = useQuery(['locations2'], () => locationModel.locations(''));
  const { data: currencies } = useQuery(['currencies'], () => currencyModel.currencies());
  const { data: towns } = useQuery(
    ['towns', countryNo],
    () => locationModel.towns({ nationalityNo: Number(countryNo) || 0 }),
    { enabled: !!countryNo },
  );
  const { data: languages } = useQuery(['languages'], () =>
    locationModel.languages({ Limit: 255 }),
  );

  const { data: rates } = useQuery(['review sections'], adModel.adRates);
  const { data: services } = useQuery(['review rates'], adModel.adServices);

  const countryOptions = locations?.items?.map((location) => ({
    label: location.name || '',
    value: location.no || 0,
  }));

  const nationalityOptions = languages?.items?.map((language) => ({
    label: language.name || '',
    value: language.no || 0,
  }));

  const currencyOptions = currencies?.items.map((currency) => ({
    label: currency.currencyCode,
    value: currency.currencyNo,
  }));

  const townOptions = towns?.items.map((town) => ({
    label: town.name,
    value: town.no,
  }));

  const languageOptions = languages?.items?.map((language) => ({
    label: language.name || '',
    value: language.no || 0,
  }));

  const currency = currencies?.items.find((c) => c.currencyNo === Number(currencyNo));

  const {
    fields: serviceFields,
    append: appendService,
    remove: removeService,
  } = useFieldArray({
    control,
    name: 'services',
  });

  const defaultService = {
    price: null,
    serviceNo: 0,
  };

  const getServiceOptions = (serviceNo?: number) => {
    const result = services?.items
      ?.filter(
        (service) =>
          service.no === serviceNo ||
          !serviceFields.find((field) => field?.serviceNo === service.no),
      )
      .map((service) => ({
        label: t(`services.${service.name}`),
        value: service.no,
      }));

    return result || [];
  };

  const {
    fields: rateFields,
    append: appendRate,
    remove: removeRate,
  } = useFieldArray({
    control,
    name: 'rates',
  });

  const getDefaultRate = () => {
    const found = rates?.types?.find((rate) => !rateFields.find((r) => r.time === rate));

    if (!found) {
      return null;
    }

    return { time: found, incall: null, outcall: null };
  };

  const defaultRate = getDefaultRate();

  const getRateOptions = (rate: string | null) => {
    const result = rates?.types
      ?.filter((r) => rate === r || !rateFields.find((field) => field?.time === r))
      .map((r) => ({
        label: t(`rateTime.${r}`),
        value: r,
      }));

    return result || [];
  };

  const locationOptions = locations2?.items?.map((location) => ({
    label: `+${location.countryPhoneCode}`,
    value: location.no,
  }));

  const isValid =
    defaultValues.languages.length > 0 &&
    defaultValues.files.length > 0 &&
    defaultValues.phones.length > 0 &&
    defaultValues.services.length > 0 &&
    defaultValues.rates.length > 0;

  return (
    <form
      className="relative flex flex-1 flex-col gap-14 py-14 px-2.5 lg:px-18"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="flex justify-between align-center gap-7">
        <span className="text-lg font-medium text-white lg:text-2xl shrink-0">{title}</span>

        {defaultValues.status && id && (
          <AdStatusButton
            id={id}
            status={defaultValues.status}
            isDisabled={!isValid}
            refetch={refetch}
          />
        )}
      </div>

      <div className="flex flex-col">
        <div className="grid grid-cols-1 gap-2.5 rounded-lg bg-primary p-7 lg:flex-initial lg:grid-cols-2 lg:p-14">
          <div className="grid gap-2.5 lg:col-span-2">
            <FormField name="name" control={control} type="text" placeholder={t('ads.title')} />
          </div>

          <FormSelect
            name="countryNo"
            control={control}
            options={countryOptions}
            label={t('ads.country')}
          />
          <FormSelect
            name="locationNo"
            control={control}
            options={townOptions}
            isDisabled={!countryNo}
            label={t('ads.town')}
          />
          <FormSelect
            name="nationalityNo"
            control={control}
            options={nationalityOptions}
            label={t('ads.nationality')}
          />
          <FormSelect
            name="adType"
            control={control}
            label={t('ads.adType')}
            options={AD_TYPE_OPTIONS}
          />
          <FormSelect
            name="availableFor"
            control={control}
            label={t('ads.availableFor')}
            options={AVAILABLE_FOR_OPTIONS}
          />
          <FormSelect
            name="meetingWith"
            control={control}
            label={t('ads.meetingWith')}
            options={MEETING_WITH_OPTIONS}
          />
          <FormSelect
            name="orientation"
            control={control}
            label={t('ads.orientation')}
            options={ORIENTATION_OPTIONS}
          />
          <FormSelect
            name="travel"
            control={control}
            label={t('ads.travel')}
            options={TRAVEL_OPTIONS}
          />
          <FormSelect
            name="currencyNo"
            control={control}
            label={t('ads.currency')}
            options={currencyOptions}
          />
          <FormSelect
            name="languages"
            control={control}
            label={t('ads.languages')}
            options={languageOptions}
            isMulti
          />

          <div className="flex items-center" style={{ height: 84 }}>
            <FormCheckbox control={control} name="isPornStar" label={t('ads.isPornstar')} />
          </div>

          <div className="flex items-center" style={{ height: 84 }}>
            <FormCheckbox control={control} name="tattoo" label={t('ads.tattoo')} />
          </div>

          {(watch('adType') === AdType.Female || watch('adType') === AdType.Trans) && (
            <FormSelect
              name="breastSize"
              control={control}
              label={t('ads.breastSize')}
              options={BREAST_SIZE_OPTIONS}
            />
          )}

          {(watch('adType') === AdType.Female || watch('adType') === AdType.Trans) && (
            <FormSelect
              name="breastType"
              control={control}
              label={t('ads.breastType')}
              options={BREAST_TYPE_OPTIONS}
            />
          )}
          <FormSelect
            name="eyeColor"
            control={control}
            label={t('ads.eyeColor')}
            options={EYE_COLOR_OPTIONS}
          />
          <FormSelect
            name="ethnicity"
            control={control}
            label={t('ads.ethnicity')}
            options={ETHNICITY_OPTIONS}
          />
          <FormSelect
            name="hairColor"
            control={control}
            label={t('ads.hairColor')}
            options={HAIR_COLOR_OPTIONS}
          />
          <FormSelect
            name="hairLenght"
            control={control}
            label={t('ads.hairLength')}
            options={HAIR_LENGTH_OPTIONS}
          />
          <FormSelect
            name="piercing"
            control={control}
            label={t('ads.piercing')}
            options={PIERCING_OPTIONS}
          />
          <FormSelect
            name="pubicHair"
            control={control}
            label={t('ads.pubicHair')}
            options={PUBIC_HAIR_OPTIONS}
          />
          <FormSelect
            name="smoker"
            control={control}
            label={t('ads.smoker')}
            options={SMOKER_OPTIONS}
          />
          <FormNumberField name="age" control={control} label={t('ads.age')} />
          <FormNumberField name="height" control={control} label={t('ads.height')} suffix="cm" />
          <FormNumberField name="weight" control={control} label={t('ads.weight')} suffix="kg" />
        </div>
      </div>

      <div className="flex flex-col">
        <span className="mb-5 text-base font-medium text-white">{t('ads.rates')}</span>
        <div className="flex flex-1 flex-shrink-0 flex-col gap-7 rounded-lg bg-primary p-7 lg:flex-initial lg:p-14">
          {rateFields.map((field, index) => (
            <div className="flex gap-7" key={field.id}>
              <div className="flex flex-col gap-1 flex-1 lg:flex-row lg:gap-7">
                <div className="flex-1">
                  <FormSelect
                    name={`rates.${index}.time`}
                    control={control}
                    label={t('ads.rateTime')}
                    options={getRateOptions(field.time)}
                  />
                </div>

                <div className="flex-1 flex flex-row gap-3.5 lg:gap-7">
                  <div className="flex-1">
                    <FormNumberField
                      control={control}
                      name={`rates.${index}.incall`}
                      label={t('ads.rateIncall')}
                      suffix={currency?.currencyCode}
                      onChange={() => trigger(`rates.${index}.outcall`)}
                    />
                  </div>
                  <div className="flex-1">
                    <FormNumberField
                      control={control}
                      name={`rates.${index}.outcall`}
                      label={t('ads.rateOutcall')}
                      suffix={currency?.currencyCode}
                      onChange={() => trigger(`rates.${index}.incall`)}
                    />
                  </div>
                </div>
              </div>

              <div className="flex-0 flex items-center">
                <button
                  type="button"
                  onClick={() => removeRate(index)}
                  disabled={rateFields.length <= 1}
                >
                  <Icon
                    alt="Remove rate"
                    icon="icon-close-alt2"
                    className="h-3 w-3 text-[#999999]"
                  />
                </button>
              </div>
            </div>
          ))}
          <button
            className="btn-rounded h-12 w-auto bg-[#0E9B57] transition duration-150 ease-linear hover:bg-[#0C844A] !w-fit px-5"
            disabled={!defaultRate}
            onClick={() => defaultRate && appendRate(defaultRate)}
            type="button"
          >
            {t('ads.addRate')}
          </button>
        </div>
        <FormFieldArrayError error={errors.rates} />
      </div>

      <div className="flex flex-col">
        <span className="mb-5 text-base font-medium text-white">{t('ads.services')}</span>
        <div className="flex flex-1 flex-shrink-0 flex-col gap-7 rounded-lg bg-primary p-7 lg:flex-initial lg:p-14">
          {serviceFields.map((field, index) => (
            <div className="flex gap-7" key={field.id}>
              <div className="flex flex-col gap-1 flex-1 lg:flex-row lg:gap-7">
                <div className="flex-1">
                  <FormSelect
                    name={`services.${index}.serviceNo`}
                    control={control}
                    label={t('ads.service')}
                    options={getServiceOptions(field.serviceNo)}
                  />
                </div>

                <div className="flex-1">
                  <FormNumberField
                    control={control}
                    name={`services.${index}.price`}
                    label={t('ads.extra')}
                    suffix={currency?.currencyCode}
                  />
                </div>
              </div>

              <div className="flex-0 flex items-center">
                <button
                  type="button"
                  onClick={() => removeService(index)}
                  disabled={serviceFields.length <= 1}
                >
                  <Icon
                    alt="Remove service"
                    icon="icon-close-alt2"
                    className="h-3 w-3 text-[#999999]"
                  />
                </button>
              </div>
            </div>
          ))}
          <button
            className="btn-rounded h-12 w-auto bg-[#0E9B57] transition duration-150 ease-linear hover:bg-[#0C844A] !w-fit px-5"
            onClick={() => appendService(defaultService)}
            type="button"
          >
            {t('ads.addService')}
          </button>
        </div>
        <FormFieldArrayError error={errors.services} />
      </div>

      <div className="flex flex-col">
        <span className="mb-5 text-base font-medium text-white">{t('ads.contacts')}</span>
        <div className="flex flex-1 flex-shrink-0 flex-col gap-7 rounded-lg bg-primary p-7 lg:flex-initial lg:p-14">
          <div className="flex gap-3.5 lg:gap-7">
            <div className="w-32">
              <FormSelect
                name="phones.0.countryNo"
                control={control}
                label={t('ads.countryCode')}
                options={locationOptions}
              />
            </div>

            <div className="flex-1 max-w-md">
              <FormField
                control={control}
                name="phones.0.number"
                label={t('ads.number')}
                type="number"
                minLength={4}
                maxLength={12}
              />
            </div>
          </div>

          <div className="grid grid-cols-2 lg:grid-cols-6 gap-y-3.5">
            <FormCheckbox control={control} name={`phones.0.isTelegram`} label="Telegram" />
            <FormCheckbox control={control} name={`phones.0.isWhatsApp`} label="Whatsapp" />
            <FormCheckbox control={control} name={`phones.0.isWeChat`} label="WeChat" />
            <FormCheckbox control={control} name={`phones.0.isViber`} label="Viber" />
            <FormCheckbox control={control} name={`phones.0.isLine`} label="Line" />
            <FormCheckbox control={control} name={`phones.0.isSignal`} label="Signal" />
          </div>
        </div>
      </div>

      <div className="flex flex-col">
        <span className="mb-5 text-base font-medium text-white">{t('ads.adDescription')}</span>
        <div className="flex flex-1 flex-shrink-0 flex-col gap-2.5 rounded-lg bg-primary p-7 lg:flex-initial lg:p-14">
          <FormTextarea
            control={control}
            name="description"
            placeholder={t('ads.yourDescription')}
            rows={15}
          />
        </div>
      </div>

      <div className="flex flex-col">
        <span className="mb-5 text-base font-medium text-white">{t('ads.uploadImages')}</span>
        <FormFiles control={control} name="files" />
      </div>

      <div className="-mt-10 flex flex-1 justify-end">
        <button
          className="btn-rounded bg-[#0E9B57] text-base transition duration-150 ease-linear hover:bg-[#0C844A] md:w-1/2 2xl:w-1/3"
          disabled={isSubmitting}
          type="submit"
        >
          {t('ads.uploadAd')}
        </button>
      </div>
    </form>
  );
};

export default AdForm;
