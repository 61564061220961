import classNames from 'classnames';
import { Icon } from '~/components/Icon/Icon';
import { MessagingApp } from '~/config/consts';

const MessagingAppIcons = {
  [MessagingApp.Telegram]: 'icon-telegram',
  [MessagingApp.WhatsApp]: 'icon-whatsapp',
  [MessagingApp.WeChat]: 'icon-wechat',
  [MessagingApp.Viber]: 'icon-viber',
  [MessagingApp.Line]: 'icon-line',
  [MessagingApp.Signal]: 'icon-signal',
};

type AvailableApps = {
  [key in MessagingApp]?: boolean;
};

interface ContactParamProps {
  code: number;
  number: string;
  availableApps: AvailableApps;
}

export default function ContactParam({ availableApps, code, number }: ContactParamProps) {
  const availableAppsArray = Object.entries(availableApps)
    .filter(([, v]) => v)
    .map(([key]) => key as MessagingApp);

  const numberFull = `+${code}${number}`;
  const numberInternational = `${code}${number}`;

  const getAppUrl = (app: MessagingApp) => {
    switch (app) {
      case MessagingApp.WhatsApp:
        return `https://wa.me/${numberInternational}?text=Hey, I'm interested in your ad I've found on Daddy Wonderland`;
      case MessagingApp.Telegram:
        return `https://t.me/%2B${numberInternational}`;
      case MessagingApp.Viber:
        return `viber://chat?number=%2B${numberInternational}`;
      case MessagingApp.Signal:
        return `https://signal.me/#p/+${numberInternational}`;
    }
  };

  return (
    <div className="flex flex-wrap gap-2.5">
      <span className="flex items-center gap-2.5">
        <Icon alt="Phone" icon="icon-phone" className="w-3.5 h-5 text-[#999999]" />
        <span className="text-base font-medium text-white">
          <a href={`tel:${numberFull}`}>{numberFull}</a>
        </span>
      </span>
      <span className="flex gap-2.5">
        {availableAppsArray.map((app) => {
          const appUrl = getAppUrl(app);

          const icon = (
            <Icon
              key={app}
              alt={app}
              icon={MessagingAppIcons[app]}
              className={classNames('w-6 h-6 text-[#999999] transition duration-150 ease-linear', {
                'hover:text-pink': appUrl,
              })}
            />
          );

          if (appUrl) {
            return (
              <a
                href={appUrl}
                key={app}
                target="_blank"
                rel="noreferrer"
                className="text-gray-700 transition duration-150 ease-linear"
                title={app}
              >
                {icon}
              </a>
            );
          }

          return icon;
        })}
      </span>
    </div>
  );
}
