import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

interface LoaderProps {
  className?: string;
}

export const Loader = ({ className }: LoaderProps) => {
  const { t } = useTranslation();

  return <p className={classNames('text-white', className)}>{t('general.loading')}</p>;
};
