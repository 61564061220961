import { useNavigate, useParams } from 'react-router-dom';

import EditAd from '~/features/ads/components/EditAd/EditAd';
import { ROUTES } from '~/router/Routes';

export default function AdEditPage() {
  const { id } = useParams();
  const navigate = useNavigate();

  if (!id) {
    navigate(ROUTES.AD_LIST);
    return null;
  }

  return <EditAd id={id} />;
}
