import { useMutation, useQuery } from '@tanstack/react-query';
import classNames from 'classnames';
import { toast } from 'material-react-toastify';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, generatePath, useNavigate } from 'react-router-dom';
import Lightbox from 'yet-another-react-lightbox';
import Fullscreen from 'yet-another-react-lightbox/plugins/fullscreen';
import Zoom from 'yet-another-react-lightbox/plugins/zoom';
import { UserType } from '~/api/data-contracts';
import { Icon } from '~/components/Icon/Icon';
import { MODAL_IDS, MessagingApp } from '~/config/consts';
import { useAuth } from '~/context/AuthContext';
import { useModals } from '~/context/ModalContext';
import { adModel } from '~/models/adModel/adModel';
import { followModel } from '~/models/followModel/followModel';
import { ROUTES } from '~/router/Routes';
import { cmToFt, kgToLbs } from '~/utils';
import { formatDateTime } from '~/utils/formatDate';
import { getAdImageUrl } from '../../utils/images';
import AdParams from '../AdParams';
import ContactParam from '../ContactParam';

interface AdDetailsProps {
  id: string;
}

export default function AdDetails({ id }: AdDetailsProps) {
  const {
    data: ad,
    error,
    refetch: refetchAd,
  } = useQuery(['ad', id], () => adModel.adDetailed(id));
  const navigate = useNavigate();
  const { showModal } = useModals();
  const { hasOneOfRoles } = useAuth();
  const { mutate: followAd } = useMutation(followModel.followAd);
  const { mutate: unfollowAd } = useMutation(followModel.unfollowAd);

  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);

  if (error) {
    toast.error('Error loading ad');
    navigate(ROUTES.AD_LIST);
  }

  if (!ad) {
    return null;
  }

  const imageUrlList = ad.images.map((filename) => getAdImageUrl(id, filename));

  const firstImage = imageUrlList[0];
  const thumbs = imageUrlList.filter((_, index) => index !== 0);

  const handleFollowClick = async () => {
    if (ad.followers.isCurrentUserIsFollwing) {
      unfollowAd(id, {
        onSuccess: () => refetchAd(),
        onError: () => toast.error(t('general.error')),
      });
    } else {
      followAd(id, {
        onSuccess: () => refetchAd(),
        onError: () => toast.error(t('general.error')),
      });
    }
  };

  return (
    <div className="relative flex flex-1 flex-col gap-14 py-14 px-2.5 lg:px-14 xl:gap-16">
      <Lightbox
        open={isOpen}
        close={() => setIsOpen(false)}
        slides={imageUrlList.map((src) => ({ src }))}
        plugins={[Fullscreen, Zoom]}
      />

      <div className="flex flex-1 flex-col gap-7 lg:flex-row">
        <div className="flex flex-1 flex-col gap-2.5 lg:w-5/12 lg:flex-initial 2xl:w-4/12">
          <div className="relative flex flex-1 flex-col gap-2 rounded-lg bg-primary py-5 px-7 lg:hidden">
            <span className="text-lg font-semibold text-pink">{ad.name}</span>
            <span className="inline-flex items-center gap-1 text-xs">
              <Icon alt="Location" icon="icon-location" className="h-3.5 w-3.5 text-[#999999]" />
              <span className="font-medium text-white">
                {ad.location.town}, {ad.location.country}
              </span>
            </span>
            <button
              type="button"
              className="absolute top-2.5 right-2.5 inline-flex gap-2 rounded-full bg-secondary py-1.5 px-3 text-white"
              onClick={handleFollowClick}
              disabled={!hasOneOfRoles([UserType.Reviewer])}
            >
              <span className="font-medium">{ad.followers.followers}</span>
              <Icon
                alt="Heart"
                icon="icon-heart"
                className={classNames(
                  'h-5 w-5',
                  ad.followers.isCurrentUserIsFollwing ? 'pink-color' : 'text-white',
                )}
              />
            </button>
          </div>
          <div className="flex flex-1 flex-col gap-2.5">
            {firstImage && (
              <button
                onClick={() => setIsOpen(true)}
                className="group aspect-w-5 aspect-h-6 relative overflow-hidden md:aspect-h-7 2xl:aspect-h-6"
              >
                <img
                  alt="Profile Picture"
                  src={firstImage}
                  className="object-cover h-full w-full rounded-lg"
                />
                <div className="absolute z-10 hidden h-full w-full items-center justify-center bg-black bg-opacity-20 transition duration-150 ease-linear group-hover:inline-flex">
                  <Icon alt="Zoom-in" icon="icon-zoom-in" className="h-8 w-8 text-white" />
                </div>
              </button>
            )}

            {thumbs.length > 0 && (
              <div className="grid grid-cols-3 gap-2.5 md:grid-cols-3 xl:grid-cols-4">
                {thumbs.map((thumb) => (
                  <button
                    key={thumb}
                    onClick={() => setIsOpen(true)}
                    className="group aspect-w-5 aspect-h-4"
                  >
                    <img
                      alt="Profile Picture"
                      src={thumb}
                      className="object-cover	h-full w-full rounded-lg"
                    />
                    <div className="absolute z-10 hidden h-full w-full items-center justify-center bg-black bg-opacity-20 transition duration-150 ease-linear group-hover:inline-flex">
                      <Icon alt="Zoom-in" icon="icon-zoom-in" className="h-8 w-8 text-white" />
                    </div>
                  </button>
                ))}
              </div>
            )}

            <div className="flex flex-col gap-3">
              {hasOneOfRoles([UserType.Reviewer]) && (
                <Link
                  className="btn-rounded h-12 w-fit bg-pink transition duration-150 ease-linear hover:bg-[#C60D4F] px-6"
                  to={generatePath(ROUTES.REVIEW_CREATE_INTERNAL, {
                    adId: id,
                  })}
                  role="button"
                >
                  {t('ads.addReview')}
                </Link>
              )}
              {hasOneOfRoles([UserType.Reviewer]) && (
                <button
                  className="btn-rounded h-12 w-fit border !border-pink transition duration-150 ease-linear hover:border-[#C60D4F] px-6"
                  onClick={() =>
                    showModal(MODAL_IDS.transferCredits, {
                      userId: ad.creator.id,
                      userName: ad.creator.nickName || '',
                    })
                  }
                  role="button"
                >
                  {t('credits.support')}
                </button>
              )}
            </div>
          </div>
        </div>

        <div className="flex flex-1 flex-col gap-2.5 lg:w-7/12 lg:flex-initial 2xl:w-8/12 select-text">
          <div className="flex flex-1 flex-shrink-0 flex-col rounded-lg bg-primary lg:flex-initial">
            <div className="relative hidden flex-1 flex-col gap-2 rounded-lg bg-primary py-5 px-7 lg:flex">
              <span className="text-lg font-semibold text-pink">{ad.name}</span>
              <span className="inline-flex items-center gap-1 text-xs">
                <Icon alt="Location" icon="icon-location" className="h-3.5 w-3.5 text-[#999999]" />
                <span className="font-medium text-white">
                  {ad.location.town}, {ad.location.country}
                </span>
              </span>
              <button
                type="button"
                className="group absolute top-2.5 right-2.5 inline-flex gap-2 rounded-full bg-secondary py-1.5 px-3 text-white"
                onClick={handleFollowClick}
                disabled={!hasOneOfRoles([UserType.Reviewer])}
              >
                <span className="text-sm font-medium">{ad.followers.followers}</span>
                <Icon
                  alt="Heart"
                  icon="icon-heart"
                  className={classNames(
                    'h-4 w-4  group-hover:text-pink transition duration-150 ease-linear',
                    ad.followers.isCurrentUserIsFollwing ? 'pink-color' : 'text-white',
                  )}
                />
              </button>
            </div>

            <div className="p-7 text-sm">
              <p className="text-white">{ad.description}</p>
            </div>

            <div className="lg:iems-center flex flex-1 flex-col lg:flex-row lg:justify-between lg:px-7 lg:pb-7">
              <div className="inline-flex items-center gap-5 px-7 pt-5 pb-5 lg:px-0 lg:pb-0 lg:pt-0">
                <span className="inline-flex items-center gap-1 text-xs text-white">
                  <Icon alt="Time" icon="icon-time" className="h-4 w-4 text-[#999999]" />
                  {ad.created && <span>{formatDateTime(new Date(ad.created))}</span>}
                </span>
                <Link
                  className="inline-flex items-center gap-1 text-xs text-white cursor-pointer hover:text-pink"
                  to={generatePath(ROUTES.USER, { id: ad.creator.id })}
                >
                  <Icon alt="User" icon="icon-user" className="h-4 w-4 text-[#999999]" />
                  <span>{ad.creator.nickName}</span>
                </Link>
              </div>
            </div>
          </div>

          <div className="flex flex-1 flex-shrink-0 flex-col overflow-hidden rounded-lg bg-primary lg:flex-initial">
            <span className="whitespace-nowrap py-3.5 text-center text-xs font-medium text-[#999999]">
              {t('ads.about')}
            </span>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-5 px-3.5 pb-3.5">
              <AdParams type={t('ads.gender')} value={t(`adType.${ad.type}`)} />
              <AdParams type={t('ads.age')} value={ad.age} />
              <AdParams type={t('ads.height')} value={`${ad.height} cm / ${cmToFt(ad.height)}`} />
              <AdParams
                type={t('ads.weight')}
                value={`${ad.weight} kg / ${kgToLbs(ad.weight)} lbs`}
              />
              <AdParams type={t('ads.nationality')} value={ad.nationality} />
              <AdParams
                type={t('ads.languages')}
                value={ad.languages.map((language) => language.name).join(', ')}
              />
              <AdParams type={t('ads.availableFor')} value={t(`availableFor.${ad.availableFor}`)} />
              <AdParams type={t('ads.meetingWith')} value={t(`meetingWith.${ad.meetingWith}`)} />
              <AdParams type={t('ads.travel')} value={t(`travel.${ad.travel}`)} />
              <AdParams type={t('ads.ethnicity')} value={t(`ethnicity.${ad.ethnicity}`)} />
              <AdParams type={t('ads.orientation')} value={t(`orientation.${ad.orientation}`)} />
              <AdParams type={t('ads.isPornStar')} value={ad.isPornStar} />
              <AdParams type={t('ads.breastSize')} value={t(`breastSize.${ad.breastSize}`)} />
              <AdParams type={t('ads.breastType')} value={t(`breastType.${ad.breastType}`)} />
              <AdParams type={t('ads.hairColor')} value={t(`hairColor.${ad.hairColor}`)} />
              <AdParams type={t('ads.hairLength')} value={t(`hairLength.${ad.hairLenght}`)} />
              <AdParams type={t('ads.smoker')} value={t(`smoker.${ad.smoker}`)} />
              <AdParams type={t('ads.piercing')} value={t(`piercing.${ad.piercing}`)} />
              <AdParams type={t('ads.tattoo')} value={ad.tattoo} />
              <AdParams type={t('ads.pubicHair')} value={t(`pubicHair.${ad.pubicHair}`)} />
            </div>
          </div>

          <div className="flex flex-1 flex-shrink-0 flex-col overflow-hidden rounded-lg bg-primary lg:flex-initial">
            <table className="w-full divide-y divide-secondary table-fixed">
              <tbody className="divide-y divide-secondary">
                <tr className="w-full divide-x divide-secondary">
                  <th
                    colSpan={3}
                    scope="colgroup"
                    className="whitespace-nowrap p-3.5 lg:px-7 text-center text-xs font-medium text-[#999999]"
                  >
                    {t('ads.rates')}
                  </th>
                </tr>
                <tr className="divide-x divide-secondary transition duration-150 ease-linear hover:bg-secondary text-sm">
                  <th className="p-3.5 lg:px-7 text-white text-left">{t('ads.rateTime')}</th>
                  <th className="p-3.5 lg:px-7 text-white text-left">{t('ads.rateIncall')}</th>
                  <th className="p-3.5 lg:px-7 text-white text-left">{t('ads.rateOutcall')}</th>
                </tr>

                {ad.rates.map((rate) => (
                  <tr
                    key={rate.rateTime}
                    className="divide-x divide-secondary transition duration-150 ease-linear hover:bg-secondary text-sm"
                  >
                    <td className="p-3.5 lg:px-7 text-white">{t(`rateTime.${rate.rateTime}`)}</td>
                    <td className="p-3.5 lg:px-7 font-bold text-white">
                      {rate.incall ? `${rate.incall} ${ad.currency.name}` : '-'}
                    </td>
                    <td className="p-3.5 lg:px-7 font-bold text-white">
                      {rate.outcall ? `${rate.outcall} ${ad.currency.name}` : '-'}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="flex flex-1 flex-shrink-0 flex-col overflow-hidden rounded-lg bg-primary lg:flex-initial">
            <table className="w-full divide-y divide-secondary table-fixed">
              <tbody className="divide-y divide-secondary">
                <tr className="w-full divide-x divide-secondary">
                  <th
                    colSpan={2}
                    scope="colgroup"
                    className="whitespace-nowrap py-3.5 text-center text-xs font-medium text-[#999999]"
                  >
                    {t('ads.services')}
                  </th>
                </tr>

                {ad.services.map((service) => (
                  <tr
                    key={service.serviceName}
                    className="divide-x divide-secondary transition duration-150 ease-linear hover:bg-secondary text-sm"
                  >
                    <td className="p-3.5 lg:px-7 text-white">
                      {t(`services.${service.serviceName}`)}
                    </td>
                    <td className="p-3.5 lg:px-7 font-bold text-white">{`${service.price} ${ad.currency.name}`}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="flex flex-1 flex-shrink-0 flex-col overflow-hidden rounded-lg bg-primary lg:flex-initial">
            <div className="inline-flex h-12 w-full items-center justify-center border-b-[1px] border-secondary text-[#999999] text-xs font-medium">
              {t('ads.contacts')}
            </div>
            <div className="flex flex-col divide-y-[1px] divide-secondary md:flex-row md:divide-y-0 md:divide-x-[1px]">
              {ad.phones.map((phone) => (
                <div
                  key={phone.number}
                  className="group relative inline-flex items-center gap-2.5 py-8 px-7 transition duration-150 ease-linear hover:bg-secondary md:w-1/2 md:py-6"
                >
                  <ContactParam
                    key={phone.number}
                    code={phone.countryCode}
                    number={phone.number || ''}
                    availableApps={{
                      [MessagingApp.Line]: phone.isLine,
                      [MessagingApp.Telegram]: phone.isTelegram,
                      [MessagingApp.WhatsApp]: phone.isWhatsApp,
                      [MessagingApp.Viber]: phone.isViber,
                      [MessagingApp.WeChat]: phone.isWeChat,
                      [MessagingApp.Signal]: phone.isSignal,
                    }}
                  />
                </div>
              ))}

              <div className="group inline-flex items-center gap-2.5 py-8 px-7 transition duration-150 ease-linear hover:bg-secondary md:w-1/2">
                <Icon alt="Location" icon="icon-location" className="w-5 h-5 text-[#999999]" />
                <span className="text-base font-medium text-white">
                  {ad.location.town}, {ad.location.country}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
