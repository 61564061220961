import classNames from 'classnames';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  GetReviewCommentsQueryResult,
  GetReviewCommentsQueryResultInnerResult,
} from '~/api/data-contracts';
import { ReactComponent as Badge } from '~/assets/badges/badge (14).svg';
import { getDayDifferenceText } from '~/utils/formatDate';
import { getAvatarImageUrl } from '~/utils/images';
import { Icon } from '../Icon/Icon';

export type CommentType = GetReviewCommentsQueryResult | GetReviewCommentsQueryResultInnerResult;

type CommentProps = {
  comment: CommentType;
  handleLike: (currentComment: CommentType) => Promise<void>;
  handleDislike: (currentComment: CommentType) => Promise<void>;
  onCreate: (comment: string, parentNo?: number) => Promise<void>;
  parentNo?: number;
};

const Comment = ({ comment, handleLike, handleDislike, onCreate, parentNo }: CommentProps) => {
  const [addComment, setAddComment] = useState(false);
  const [commentText, setCommentText] = useState('');

  const { t } = useTranslation();

  return (
    <div className="inline-flex flex-1 gap-2.5 border-t-[1px] border-secondary p-7">
      <div className="flex-shrink-0">
        <img
          className="mt-1 inline-block h-8 w-8 rounded-full"
          src={
            comment.avatar
              ? getAvatarImageUrl(comment.avatar)
              : '/assets/images/user-placeholder.jpg'
          }
          alt={t('comments.profilePicture')}
        />
      </div>
      <div className="flex flex-1 flex-col">
        <div className="relative inline-flex h-10 w-full items-center gap-1">
          {/* <span className="absolute top-1/2 right-0 -translate-y-1/2 transform opacity-0 transition duration-150 ease-linear group-hover:opacity-100">
                  <Icon alt="Flag" icon="icon-flag" className="w-3.5 h-3.5 text-[#999999]" />
                </span> */}
          <span className="text-sm font-semibold text-pink backface-hidden">
            {comment.createdBy}
          </span>

          {comment.isVeteran && <Badge className="text-pink h-6 w-6" title={t('honor.veteran')} />}

          <span className="ml-1 text-xs text-[#999999]">
            {comment.created && getDayDifferenceText(comment.created)}
          </span>
        </div>
        <p className="pl-1 text-sm text-white">{comment.text}</p>
        <div className="inline-flex items-center gap-5 pt-2.5">
          <a
            onClick={() => setAddComment(true)}
            className="text-sm text-[#999999] hover:underline hover:cursor-pointer"
          >
            {t('comments.reply')}
          </a>
          <div className="inline-flex items-center justify-center gap-2.5 text-white">
            <button
              className="group inline-flex items-center gap-1"
              onClick={() => handleLike(comment)}
            >
              <Icon
                alt="Like"
                icon="icon-like"
                className={classNames(
                  'w-4 h-4 transform transition duration-150 ease-linear group-hover:text-[#118DF0] group-hover:-translate-y-0.5',
                  comment.isCurrentUserLiked === true ? 'text-[#118DF0]' : 'text-white',
                )}
              />
              <span className="lg:text-sm">{comment.likes}</span>
            </button>

            <span className="h-2.5 w-[1px] bg-[#272727]"></span>

            <button
              className="group inline-flex items-center gap-1"
              onClick={() => handleDislike(comment)}
            >
              <Icon
                alt="Dislike"
                icon="icon-dislike"
                className={classNames(
                  'w-4 h-4 transform transition duration-150 ease-linear group-hover:text-[#FF304F] group-hover:translate-y-0.5',
                  comment.isCurrentUserLiked === false ? 'text-[#FF304F]' : 'text-white',
                )}
              />
              <span className="lg:text-sm">{comment.dislikes}</span>
            </button>
          </div>
        </div>
        {addComment && (
          <>
            <textarea
              name="comment"
              id="comment"
              rows={3}
              className="form-control-input w-full rounded-lg border-[#272727] bg-secondary text-left text-white placeholder:text-[#999999] mt-5"
              placeholder={t('comments.whatYouThink')}
              value={commentText}
              onChange={(e) => {
                setCommentText(e.target.value);
              }}
            />
            {commentText && (
              <button
                className="btn-rounded float-right w-1/2 border-transparent bg-[#0E9B57] py-2.5 text-sm transition duration-150 ease-linear hover:bg-[#0C844A] mt-3"
                onClick={async () => {
                  await onCreate(commentText, parentNo || comment.no);
                  setCommentText('');
                  setAddComment(false);
                }}
              >
                {t('comments.post')}
              </button>
            )}
          </>
        )}
        {'innerComments' in comment &&
          comment.innerComments.map((innerComment) => {
            return (
              <Comment
                key={innerComment.no}
                comment={innerComment}
                handleDislike={handleDislike}
                handleLike={handleLike}
                onCreate={onCreate}
                parentNo={comment.no}
              />
            );
          })}
      </div>
    </div>
  );
};

export default Comment;
