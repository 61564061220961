import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Icon } from '~/components/Icon/Icon';
import { Tracker } from '~/components/Tracker/Tracker';
import { ROUTES } from '~/router/Routes';

export default function Footer() {
  const { t } = useTranslation();

  return (
    <footer className="z-30 mt-20 flex flex-col gap-6 bg-secondary p-7">
      <div className="inline-flex flex-wrap justify-center gap-4">
        <a
          href=""
          className="whitespace-nowrap border-b border-transparent text-xs font-medium text-white transition duration-150 ease-linear hover:border-white"
        >
          {t('footer.privacyPolicy')}
        </a>
        <a
          href=""
          className="whitespace-nowrap border-b border-transparent text-xs font-medium text-white transition duration-150 ease-linear hover:border-white"
        >
          {t('footer.cookiePolicy')}
        </a>
        <a
          href=""
          className="whitespace-nowrap border-b border-transparent text-xs font-medium text-white transition duration-150 ease-linear hover:border-white"
        >
          {t('footer.subscriptions')}
        </a>
        <a
          href=""
          className="whitespace-nowrap border-b border-transparent text-xs font-medium text-white transition duration-150 ease-linear hover:border-white"
        >
          {t('footer.rules')}
        </a>
        <a
          href=""
          className="whitespace-nowrap border-b border-transparent text-xs font-medium text-white transition duration-150 ease-linear hover:border-white"
        >
          {t('footer.faq')}
        </a>
      </div>
      <p className="text-center text-xs text-[#999999]">{t('footer.aboutUs')}</p>
      <div className="-ml-7 h-px w-full bg-third"></div>
      <div className="relative flex flex-col items-center justify-center gap-6">
        <a
          href="https://t.me/escortai"
          target="_blank"
          rel="noreferrer"
          className="text-gray-700 hover:text-gray-900 dark:hover:text-gray-100 right-5 top-1/2 transform transition duration-150 ease-linear dark:text-white lg:absolute lg:-translate-y-1/2"
        >
          <Icon
            alt="Telegram"
            icon="icon-telegram"
            className="w-8 h-8 text-[#999999] hover:text-pink lg:h-6 lg:w-6 transition duration-150 ease-linear"
          />
        </a>
        <p className="text-center text-xs text-[#999999]">
          © {new Date().getFullYear()} Copyright{' '}
          <Link className="text-white text-reset fw-bold" to={ROUTES.DEFAULT}>
            daddywonderland.com
          </Link>
          . All Rights Reserved.
        </p>
      </div>
      <Tracker />
    </footer>
  );
}
