import {
  EmailResetPasswordRequest,
  RefreshAccessTokenRequest,
  RegisterRequest,
  ResetPasswordRequest,
  SignInRequest,
  V1AuthLoginCreateData,
  V1AuthRefreshCreateData,
} from '~/api/data-contracts';
import { CurrentUser } from '~/types/auth';
import { api } from '~/utils/api';

const URL = {
  LOGIN: '/auth/login',
  REFRESH: '/auth/refresh',
  REGISTER: '/auth/register',
  RESET_PASSWORD: '/auth/reset',
  CURRENT_USER: '/users/current-user',
  PASSWORD: '/auth/password',
  SIGN_OUT: '/users/signOut',
};

export const authModel = {
  login: (request: SignInRequest) =>
    api.post<V1AuthLoginCreateData>(URL.LOGIN, JSON.stringify(request)),
  refresh: (request: RefreshAccessTokenRequest) =>
    api.post<V1AuthRefreshCreateData>(URL.REFRESH, JSON.stringify(request)),
  register: (request: RegisterRequest) => api.post(URL.REGISTER, JSON.stringify(request)),
  reset: (request: EmailResetPasswordRequest) =>
    api.post<null>(URL.RESET_PASSWORD, JSON.stringify(request)),
  currentUser: () => api.get<CurrentUser>(URL.CURRENT_USER),
  password: (request: ResetPasswordRequest) =>
    api.post<CurrentUser>(URL.PASSWORD, JSON.stringify(request)),
  signOut: () => api.post<void>(URL.SIGN_OUT),
};
