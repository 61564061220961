import ReviewList from '~/features/reviews/components/ReviewList/ReviewList';
import { reviewModel } from '~/models/reviewModel/reviewModel';
import { useTranslation } from 'react-i18next';

export default function ReviewsPage() {
  const { t } = useTranslation();

  return (
    <ReviewList
      adHref={(id) => `/reviews/${id}`}
      loadFn={reviewModel.reviews}
      title={t('reviews.reviews')}
    />
  );
}
