import { useNavigate, useParams } from 'react-router-dom';

import AdDetails from '~/features/ads/components/AdDetails/AdDetails';
import { ROUTES } from '~/router/Routes';

export default function AdDetailsPage() {
  const { id } = useParams();
  const navigate = useNavigate();

  if (!id) {
    navigate(ROUTES.AD_LIST);
    return null;
  }

  return <AdDetails id={id} />;
}
