import { useMutation } from '@tanstack/react-query';
import { toast } from 'material-react-toastify';
import { useForm } from 'react-hook-form';
import FormError from '~/components/form/FormError';
import FormField from '~/components/form/FormField';
import { usersModel } from '~/models/usersModel/usersModel';
import { processFormError } from '~/utils/error';
import { useTranslation } from 'react-i18next';

type ChangePasswordFormValues = {
  currentPassword: string;
  newPassword: string;
  repeatNewPassword: string;
};

const defaultValues: ChangePasswordFormValues = {
  currentPassword: '',
  newPassword: '',
  repeatNewPassword: '',
};

export default function UserSettingsPassword() {
  const { t } = useTranslation();
  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<ChangePasswordFormValues>({
    defaultValues,
  });
  const { mutate, isLoading } = useMutation(usersModel.userPassword);

  const onSubmit = (values: ChangePasswordFormValues) => {
    mutate(values, {
      onError: processFormError(setError),
      onSuccess: () => {
        toast.success(t('userProfile.savePasswordSuccess'));
      },
    });
  };

  return (
    <form
      className="flex w-full flex-col divide-y-[1px] divide-secondary overflow-hidden rounded-lg bg-primary"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="w-full py-3.5 text-center text-xs font-medium text-[#999999]">
        {t('userProfile.changePassword')}
      </div>
      <div className="flex flex-col items-center gap-5 p-7 lg:p-14">
        <div className="w-full space-y-2">
          <FormField
            control={control}
            name="currentPassword"
            placeholder={t('userProfile.password')}
            type="password"
            icon="icon-solid-closed"
          />
          <FormField
            control={control}
            name="newPassword"
            placeholder={t('userProfile.newPassword')}
            type="password"
            icon="icon-solid-closed"
          />
          <FormField
            control={control}
            name="repeatNewPassword"
            placeholder={t('userProfile.repeatNewPassword')}
            type="password"
            icon="icon-solid-closed"
          />

          <FormError error={errors.root} />
        </div>

        <button
          type="submit"
          className="btn-rounded border-transparent bg-[#0E9B57] transition duration-150 ease-linear hover:bg-[#0C844A]"
          disabled={isLoading}
        >
          {t('userProfile.savePassword')}
        </button>
      </div>
    </form>
  );
}
