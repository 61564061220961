import { toast } from 'material-react-toastify';
import { useNavigate } from 'react-router-dom';
import { reviewModel } from '~/models/reviewModel/reviewModel';
import { ROUTES } from '~/router/Routes';
import ReviewFormInternal from '../ReviewForm/ReviewFormInternal';
import { ReviewInternalFormValues } from '../ReviewForm/reviewSchema';
import { useTranslation } from 'react-i18next';

type CreateReviewInternalProps = {
  adId: string;
};

export default function CreateReviewInternal({ adId }: CreateReviewInternalProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleSubmit = async (values: ReviewInternalFormValues) => {
    try {
      await reviewModel.createReviewInternal({
        adId,
        description: values.description,
        locationNo: Number(values.locationNo),
        name: values.name,
        sections: values.sections
          .filter((section) => section?.rate && section.sectionNo)
          .map((section) => ({
            rate: section.rate!,
            sectionNo: section.sectionNo!,
          })),
      });

      toast.success(t('reviews.createReviewSuccess'));
      navigate(ROUTES.REVIEW_LIST_PERSONAL);
    } catch (e) {
      toast.error(t('general.error'));
    }
  };

  const defaultValues: ReviewInternalFormValues = {
    description: '',
    locationNo: 0,
    countryNo: 0,
    name: '',
    sections: [],
  };

  return (
    <ReviewFormInternal
      title={t('reviews.createReview')}
      defaultValues={defaultValues}
      onSubmit={handleSubmit}
    />
  );
}
