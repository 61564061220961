import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { AdStatus, GetAdsQueryResult, GetPersonalAdsQueryResult } from '~/api/data-contracts';
import { getAdImageUrl } from '../../utils/images';

type AdButtonProps = {
  ad: GetAdsQueryResult | GetPersonalAdsQueryResult;
  adHref: string;
};

export default function AdAlt({ ad, adHref }: AdButtonProps) {
  const { id, image, country, description, name, town } = ad;

  if (!id) {
    return null;
  }

  const content = (
    <>
      <div className="block overflow-hidden relative w-[120px] max-w-[120px] md:w-[160px] md:max-w-[160px] shrink-0 rounded-l-lg">
        <img
          alt="Profile Picture"
          src={image ? getAdImageUrl(id, image) : '/assets/images/image-placeholder.jpg'}
          className="object-cover object-center h-full w-full transform group-hover:scale-110 transition duration-150 ease-linear"
        />
      </div>
      <div className="flex flex-col justify-between m-2.5 md:m-5 text-white">
        <div>
          <span className="font-medium text-sm md:text-base line-clamp-2 leading-tight group-hover:text-pink transition duration-150 ease-linear">
            {name}
          </span>
          <span className="text-[#999999] text-xs md:text-sm mt-1 md:mt-2.5 line-clamp-1 leading-tight lg:line-clamp-2">
            {description}
          </span>
        </div>
        <div className="inline-flex items-center gap-1 mt-1 md:mt-4">
          <span className="text-xs md:text-sm backface-hidden">
            {country}, {town}
          </span>
        </div>
      </div>

      {'status' in ad && (
        <span
          className={classNames(
            'absolute right-1 top-1 text-white px-2 py-1 text-xs rounded-lg lg:top-3.5 lg:right-3.5',
            {
              'bg-pink': ad.status === AdStatus.Disabled,
              'bg-[#0E9B57]': ad.status === AdStatus.Active,
            },
          )}
        >
          {ad.status}
        </span>
      )}
    </>
  );

  return (
    <Link
      to={adHref}
      className="group inline-flex relative rounded-md overflow-visible max-h-[100px] md:max-h-[120px] lg:max-h-[150px] bg-primary hover:bg-secondary hover:shadow-[0px_0px_30px_rgba(0,0,0,0.25)] transition duration-150 ease-linear"
    >
      {content}
    </Link>
  );
}
