import classNames from 'classnames';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useSearchParams } from 'react-router-dom';
import { UserType } from '~/api/data-contracts';
import { DropdownConfig } from '~/components/Dropdown/DropdownConfig';
import { Icon } from '~/components/Icon/Icon';
import { useAuth } from '~/context/AuthContext';
import { ROUTES } from '~/router/Routes';

export default function UserSettings() {
  const { user, hasOneOfRoles } = useAuth();
  const { t } = useTranslation();
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (dropdownRef.current) {
      new DropdownConfig(dropdownRef.current);
    }
  }, [dropdownRef]);

  const [searchParams] = useSearchParams();

  const tab = searchParams.get('tab') || 'picture';

  return (
    <div className="dropdown relative z-50 w-full" ref={dropdownRef}>
      <div className="hidden h-auto w-full items-center justify-center rounded-lg border-b-[1px] border-secondary bg-primary px-7 py-3.5 text-[#999999] lg:inline-flex">
        <div className="inline-flex items-center gap-2.5">
          <span className="text-sm font-medium backface-hidden lg:text-xs">
            {t('userProfile.categories')}
          </span>
        </div>
      </div>
      <button className="dropdown-toggle inline-flex h-14 w-full items-center justify-between rounded-lg bg-primary px-7 text-[#999999] lg:hidden">
        <div className="inline-flex items-center gap-2.5">
          <Icon alt="Location" icon="icon-gear" className="block w-6 h-6 lg:hidden" />
          <span className="text-sm font-medium backface-hidden lg:text-xs">
            {t('userProfile.categories')}
          </span>
        </div>

        <Icon
          alt="caret"
          icon="icon-caret"
          id="caret"
          className="h-2.5 w-2.5 text-[#999999] dropdown-caret lg:hidden"
        />
      </button>
      <div className="dropdown-content hidden w-full divide-y-[1px] divide-secondary rounded-b-lg bg-primary shadow-[0px_0px_30px_rgba(0,0,0,0.25)] lg:block pb-2">
        <Link
          to={{ pathname: ROUTES.USER_SETTINGS, search: '?tab=picture' }}
          className={classNames('user-meniu-link', {
            'user-meniu-link-active': tab === 'picture',
          })}
        >
          <span>{t('userProfile.profilePicture')}</span>
          <div className="hidden-bg"></div>
        </Link>
        <Link
          to={{ pathname: ROUTES.USER_SETTINGS, search: '?tab=password' }}
          className={classNames('user-meniu-link', {
            'user-meniu-link-active': tab === 'password',
          })}
        >
          <span>{t('userProfile.changePassword')}</span>
          <div className="hidden-bg"></div>
        </Link>
        <Link
          to={{ pathname: ROUTES.USER_SETTINGS, search: '?tab=activate' }}
          className={classNames('user-meniu-link', {
            'user-meniu-link-active': tab === 'activate',
          })}
        >
          <span>{t('userProfile.activateAccount')}</span>
          <div className="hidden-bg"></div>
        </Link>
        {/* <Link
          to={{ pathname: ROUTES.USER_SETTINGS, search: '?tab=twoFactor' }}
          className={classNames('user-meniu-link', {
            'user-meniu-link-active': tab === 'twoFactor',
          })}
        >
          <span>{t('twoFactor.twoFactor')}</span>
          <div className="hidden-bg"></div>
        </Link> */}

        {hasOneOfRoles([UserType.Reviewer]) && (
          <>
            <Link to={ROUTES.REVIEW_LIST_PERSONAL} className="user-meniu-link">
              <span>{t('reviews.myReviews')}</span>
              <div className="inline-flex h-8 w-11 items-center justify-center rounded-md bg-secondary text-sm">
                {user?.reviewData?.currentlyCreated}
              </div>
              <div className="hidden-bg"></div>
            </Link>
            <Link
              to={{ pathname: ROUTES.REVIEWS_LIST, search: `?followed=${user?.id}` }}
              className="user-meniu-link"
            >
              <span>{t('userProfile.followedReviews')}</span>
              <div className="inline-flex h-8 w-11 items-center justify-center rounded-md bg-secondary text-sm">
                {user?.reviewData?.followingReviews}
              </div>
              <div className="hidden-bg"></div>
            </Link>
          </>
        )}
      </div>
    </div>
  );
}
