import { yupResolver } from '@hookform/resolvers/yup';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ReviewStatus } from '~/api/data-contracts';
import { Loader } from '~/components/Loader/Loader';
import FormField from '~/components/form/FormField';
import FormFieldArrayError from '~/components/form/FormFieldArrayError';
import FormFiles from '~/components/form/FormFiles';
import FormSelect from '~/components/form/FormSelect';
import FormTextarea from '~/components/form/FormTextarea';
import { currencyModel } from '~/models/currencyModel/currencyModel';
import { locationModel } from '~/models/locationModel/locationModel';
import { reviewModel } from '~/models/reviewModel/reviewModel';
import { ReviewFormValues, reviewSchema } from './reviewSchema';

const ratings = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

interface ReviewFormProps {
  onSubmit: (values: ReviewFormValues) => Promise<void>;
  defaultValues: ReviewFormValues;
  title: string;
  onActivateClick?: () => Promise<void>;
  onDeactivateClick?: () => Promise<void>;
}

export default function ReviewForm({
  onActivateClick,
  onDeactivateClick,
  onSubmit,
  defaultValues,
  title,
}: ReviewFormProps) {
  const [isLoading, setIsloading] = useState<boolean>();

  const handleActivateClick = async () => {
    if (!onActivateClick) {
      return;
    }

    try {
      setIsloading(true);
      await onActivateClick();
    } finally {
      setIsloading(false);
    }
  };

  const handleDeactivateClick = async () => {
    if (!onDeactivateClick) {
      return;
    }

    try {
      setIsloading(true);
      await onDeactivateClick();
    } finally {
      setIsloading(false);
    }
  };

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors, isSubmitting },
  } = useForm<ReviewFormValues>({
    defaultValues,
    resolver: yupResolver<ReviewFormValues>(reviewSchema),
    shouldFocusError: true,
  });

  const { t } = useTranslation();

  const countryNo = watch('countryNo');
  const currencyNo = watch('currencyNo');

  const { data: locations } = useQuery(['locations'], () => locationModel.locations('lithuania'));
  const { data: currencies } = useQuery(['currencies'], () => currencyModel.currencies());
  const { data: sections } = useQuery(['review sections'], reviewModel.reviewSections);
  const { data: rates } = useQuery(['review rates'], reviewModel.reviewRates);
  const { data: towns } = useQuery(
    ['towns', countryNo],
    () => locationModel.towns({ nationalityNo: Number(countryNo) || 0 }),
    { enabled: !!countryNo },
  );

  if (!locations || !currencies || !rates || !sections) {
    return <Loader />;
  }

  const countryOptions = locations.items?.map((location) => ({
    label: location.name || '',
    value: location.no || 0,
  }));

  const currencyOptions = currencies.items.map((currency) => ({
    label: currency.currencyCode,
    value: currency.currencyNo || 0,
  }));

  const townOptions = towns?.items.map((town) => ({
    label: town.name,
    value: town.no || 0,
  }));

  const currency = currencies.items.find((c) => c.currencyNo === Number(currencyNo));

  return (
    <form
      className="relative flex flex-1 flex-col gap-14 py-14 px-2.5 lg:px-18"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="flex justify-between align-center">
        <span className="text-lg font-medium text-white lg:text-2xl">{title}</span>

        <div>
          {defaultValues.status === ReviewStatus.Active && (
            <button
              key="activate"
              type="button"
              className="btn-rounded h-12 w-auto bg-pink transition duration-150 ease-linear hover:bg-[#C60D4F] px-6"
              onClick={handleDeactivateClick}
              disabled={isLoading}
            >
              {t('reviews.deactivate')}
            </button>
          )}
          {defaultValues.status === ReviewStatus.Disabled && (
            <button
              key="activate"
              type="button"
              className="btn-rounded h-12 w-auto bg-[#0E9B57] transition duration-150 ease-linear hover:bg-[#0C844A] px-6"
              onClick={handleActivateClick}
              disabled={isLoading}
            >
              {t('reviews.activate')}
            </button>
          )}
        </div>
      </div>

      <div className="flex flex-col">
        <div className="grid grid-cols-1 gap-2.5 rounded-lg bg-primary p-7 lg:flex-initial lg:grid-cols-2 lg:p-14">
          <div className="grid gap-2.5 lg:col-span-2">
            <FormField control={control} name="name" placeholder={t('reviews.escortName')} />
          </div>

          <div className="grid gap-2.5">
            <FormSelect
              name="countryNo"
              control={control}
              options={countryOptions}
              placeholder={t('reviews.selectCountry')}
            />
          </div>

          <div className="grid gap-2.5">
            <FormSelect
              name="locationNo"
              control={control}
              options={townOptions}
              placeholder={t('reviews.selectTown')}
            />
          </div>

          {!defaultValues.adId && (
            <div className="grid gap-2.5 lg:col-span-2">
              <FormField
                control={control}
                name="externalUrl"
                placeholder={t('reviews.externalLink')}
              />
            </div>
          )}
        </div>
      </div>

      <Controller
        render={({ field }) => (
          <div className="flex flex-col" ref={field.ref} tabIndex={-1}>
            <span className="mb-5 text-base font-medium text-white">
              {t('reviews.serviceRatings')}
            </span>
            <div className="grid grid-cols-1 gap-7 rounded-lg bg-primary p-7 lg:grid-cols-2 lg:p-14">
              {sections.items?.map((section, index) => (
                <div className="flex flex-col gap-2.5" key={index}>
                  <span className="text-sm text-[#999999]">
                    {t(`sections.${section.name}`)} (({t('reviews.rate')})):
                  </span>
                  <div className="inline-flex flex-1 overflow-hidden rounded-lg bg-secondary">
                    {ratings.map((rating, i) => (
                      <label className="radio-rating-container" key={i}>
                        <input
                          type="radio"
                          checked={field.value[index]?.rate === rating}
                          onChange={() => {
                            const newValue = [...field.value];

                            newValue[index] = {
                              sectionNo: section.no,
                              rate: rating,
                            };

                            field.onChange(newValue);
                          }}
                        />
                        <span>{rating}</span>
                        <div></div>
                      </label>
                    ))}
                  </div>
                </div>
              ))}
            </div>
            <FormFieldArrayError error={errors.sections} />
          </div>
        )}
        name="sections"
        control={control}
      />

      <div className="flex flex-col">
        <span className="mb-5 text-base font-medium text-white">{t('reviews.serviceRates')}</span>
        <div className="flex flex-1 flex-shrink-0 flex-col gap-7 rounded-lg bg-primary p-7 lg:flex-initial lg:p-14">
          <div className="grid gap-2.5 sm:gap-7 md:w-[calc(50%-.85rem)]">
            <FormSelect
              name="currencyNo"
              control={control}
              options={currencyOptions}
              placeholder={t('reviews.selectCurrency')}
            />
          </div>

          <Controller
            render={({ field }) => (
              <>
                <div
                  className="grid grid-cols-1 sm:grid-cols-2 sm:gap-7 lg:grid-cols-4"
                  ref={field.ref}
                  tabIndex={-1}
                >
                  {rates.types.map((rate, index) => (
                    <div
                      className="inline-flex min-h-[50px] flex-1 divide-x-[1px] divide-[#272727]"
                      key={rate}
                    >
                      <div className="flex w-5/12 flex-1 items-center justify-center text-white">
                        {t(`rateTime.${rate}`)}
                      </div>
                      <div className="group-price relative w-7/12">
                        <input
                          type="number"
                          className="h-full w-full border-none border-transparent bg-secondary transition duration-150 ease-linear hover:bg-third focus:border-transparent focus:bg-third focus:shadow-[inset_0px_0px_3px_2px_#E90F5D] focus:ring-0 group-first:rounded-tr-lg sm:rounded-tr-lg text-white"
                          onChange={(e) => {
                            const newValue = [...field.value];

                            newValue[index] = {
                              time: rate,
                              incall: e.target.value,
                            };

                            field.onChange(newValue);
                          }}
                          value={field.value[index]?.incall || ''}
                        />
                        <span className="group-price-hover:text-white group-price-focus:text-white absolute top-1/2 right-5 z-10 -translate-y-1/2 transform text-[#999999] transition duration-150 ease-linear">
                          {currency?.currencyCode || '-'}
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              </>
            )}
            name="rates"
            control={control}
          />
        </div>
        <FormFieldArrayError error={errors.rates} />
      </div>

      <div className="flex flex-col">
        <span className="mb-5 text-base font-medium text-white">
          {t('reviews.describeExperience')}
        </span>
        <div className="flex flex-1 flex-shrink-0 flex-col gap-2.5 rounded-lg bg-primary p-7 lg:flex-initial lg:p-14">
          <FormTextarea
            control={control}
            name="description"
            placeholder={t('reviews.yourReview')}
            rows={15}
          />
        </div>
      </div>

      <div className="flex flex-col">
        <span className="mb-5 text-base font-medium text-white">{t('reviews.uploadImages')}</span>
        <FormFiles control={control} name="files" />
      </div>

      <div className="-mt-10 flex flex-1 justify-end">
        <button
          className="btn-rounded bg-[#0E9B57] text-base transition duration-150 ease-linear hover:bg-[#0C844A] md:w-1/2 2xl:w-1/3"
          disabled={isSubmitting}
          type="submit"
        >
          {t('reviews.uploadReview')}
        </button>
      </div>
    </form>
  );
}
