import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';
import { Icon } from '~/components/Icon/Icon';
import { Loader } from '~/components/Loader/Loader';
import { MedalCount } from '~/components/MedalCount/MedalCount';
import { reputationModel } from '~/models/reputationModel/reputationModel';
import { ROUTES } from '~/router/Routes';
import { ReactComponent as Badge } from '~/assets/badges/badge (14).svg';

export default function WallofHonor() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data: reputation } = useQuery(['reputation'], reputationModel.reputation);

  if (!reputation?.items) {
    return <Loader />;
  }

  return (
    <div className="relative flex flex-1 flex-col gap-14 py-14 px-2.5 lg:px-14 xl:gap-16">
      <span className="text-lg font-medium text-white lg:text-2xl">{t('honor.wallOfHonor')}</span>
      <div className="flex flex-col">
        <div className="flex flex-col gap-2.5 overflow-hidden rounded-lg bg-primary">
          <table className="divide-y divide-secondary">
            <thead className="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  className="text-gray-900 w-6/12 py-3.5 pl-4 text-left text-sm font-semibold sm:pl-6"
                >
                  <Icon alt="user" icon="icon-user" className="h-4 w-4 text-[#999999]" />
                </th>
                <th
                  scope="col"
                  className="text-gray-900 w-2/12 py-3.5 text-center text-sm font-semibold"
                >
                  <div className="inline-flex h-4 w-4 items-center">
                    <Icon
                      alt="MessagesStar"
                      icon="icon-messages-star"
                      className="h-4 w-4 text-[#999999]"
                    />
                  </div>
                </th>
                <th
                  scope="col"
                  className="text-gray-900 w-2/12 py-3.5 text-center text-sm font-semibold"
                >
                  <div className="inline-flex h-4 w-4 items-center">
                    <Icon alt="Forum" icon="icon-forum" className="h-4 w-4 text-[#999999]" />
                  </div>
                </th>
                <th
                  scope="col"
                  className="text-gray-900 w-2/12 py-3.5 text-center text-sm font-semibold"
                >
                  <div className="inline-flex h-4 w-4 items-center">
                    <Icon alt="Star" icon="icon-star-filled" className="h-4 w-4 text-[#FCC417]" />
                  </div>
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-x divide-secondary">
              {reputation.items.map((item, index) => (
                <tr
                  key={index}
                  className="group divide-x divide-secondary transition duration-150 ease-linear hover:bg-secondary cursor-pointer"
                  onClick={() => {
                    navigate(
                      generatePath(ROUTES.USER, {
                        id: item.userId,
                      }),
                    );
                  }}
                >
                  <td className="py-4 pl-4 pr-3 text-sm font-medium text-white sm:pl-6">
                    <div className="inline-flex items-center gap-2.5 break-all">
                      <MedalCount count={index + 1} />
                      {item.nickName}
                      {item.isVeteran && (
                        <Badge className="text-pink h-8 w-8" title={t('honor.veteran')} />
                      )}
                    </div>
                  </td>
                  <td className="py-4 text-center text-sm font-medium text-[#999999] transition duration-150 ease-linear group-hover:text-white">
                    {item.countCreatedComments}
                  </td>
                  <td className="py-4 text-center text-sm font-medium text-[#999999] transition duration-150 ease-linear group-hover:text-white">
                    {item.countCreatedReviews}
                  </td>
                  <td className="py-4 text-center text-base font-medium text-white transition duration-150 ease-linear group-hover:text-[#FCC417]">
                    {Math.round(item.countReputation * 10) / 10}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {/* <div className="flex flex-col gap-7 lg:w-1/2">
        <div className="flex flex-col gap-2.5">
          <span className="inline-flex items-center gap-2.5">
            <Icon alt="user" icon="icon-question" className="h-8 w-8 text-[#999999]" />
            <span className="font-semibold text-white">{t('honor.whatIsKarma')}</span>
          </span>
          <p className="text-sm text-[#999999]">
            Sed condimentum diam at enim scelerisque, sit amet dignissim urna semper. Suspendisse
            accumsan ipsum elit, sit amet laoreet odio dapibus eu. Donec vitae aliquam sem. Integer
            hendrerit sem sapien, sed finibus est pellentesque eu. Etiam fringilla pellentesque
            imperdiet. Curabitur tristique nunc nec ante sodales porttitor. Proin laoreet vehicula
            auctor. Sed augue lectus, bibendum id mauris non, placerat sagittis justo.
          </p>
        </div>
      </div> */}
    </div>
  );
}
