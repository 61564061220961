import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import { localStorageKeys } from './config/consts';
import { LanguageType, fallbackLng, languages } from './i18n/i18nConfig';
import translationEN from './i18n/locales/en/translation.json';
import translationLT from './i18n/locales/lt/translation.json';
import translationPL from './i18n/locales/pl/translation.json';
import translationRU from './i18n/locales/ru/translation.json';
import translationUA from './i18n/locales/ua/translation.json';

const getLanguage = () => {
  const localStoreLang = localStorage.getItem(localStorageKeys.language);

  if (localStoreLang && languages.includes(localStoreLang as LanguageType)) {
    return localStoreLang;
  }

  const browserLang = navigator.language;

  if (browserLang && languages.includes(browserLang as LanguageType)) {
    localStorage.setItem(localStorageKeys.language, browserLang);
    return browserLang;
  }

  return fallbackLng;
};

const resources = {
  en: {
    translation: translationEN,
  },
  lt: {
    translation: translationLT,
  },
  uk: {
    translation: translationUA,
  },
  ru: {
    translation: translationRU,
  },
  pl: {
    translation: translationPL,
  },
};

i18next.use(initReactI18next).init({
  lng: getLanguage(),
  resources,
  defaultNS: 'translation',
});

export default i18next;
