import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useModals } from '~/context/ModalContext';
import { Icon } from '../Icon/Icon';

export const VERIFIED_COOKIE = 'Verified';

export default function AgeVerifyModal() {
  const [_, setCookie] = useCookies();
  const { t } = useTranslation();
  const { hideModal } = useModals();

  const verify = () => {
    setCookie(VERIFIED_COOKIE, 'true');
    hideModal();
  };

  const cancel = () => {
    window.location.href = 'https://www.google.com';
  };

  return (
    <div
      className="relative max-h-[90vh] w-full overflow-auto rounded-lg bg-primary md:min-w-[600px]"
      role="dialog"
      aria-modal="true"
    >
      <header className="flex h-20 w-full flex-1 items-center justify-center bg-pink">
        <Icon alt="Logo" icon="logo" className="w-24 h-9 xl:w-36 xl:h-auto" />
      </header>

      <section className="p-7">
        <p className="text-2xl font-bold leading-loose text-white">{t('ageVerifyModal.warning')}</p>
        <p className="text-sm font-bold text-white">{t('ageVerifyModal.title')}</p>
      </section>
      <section className="flex flex-col gap-2 p-7 text-sm md:flex-row">
        <button
          className="btn-rounded flex-shrink-0 border-0 bg-[#0E9B57] transition duration-150 ease-linear hover:bg-[#0C844A] md:w-7/12"
          onClick={verify}
        >
          {t('general.yes')}
        </button>
        <button
          className="btn-rounded flex-shrink-0 border-0 bg-secondary transition duration-150 ease-linear hover:bg-third md:w-5/12"
          onClick={cancel}
        >
          {t('general.no')}
        </button>
      </section>
    </div>
  );
}
