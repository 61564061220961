export const DEFAULT_LANGUAGE = 'lt';
export const DEFAULT_COUNTRY_CODE = 'LT';
export const DEFAULT_CURRENCY = 'eur';
export const DEFAULT_CURRENCY_NAME = 'Euro';
export const DEFAULT_CURRENCY_SYMBOL = '€';

export const MAX_PHOTO_UPLOAD_SIZE = 2000000; // bytes, 2MB

export const MODAL_IDS = Object.freeze({
  loginModal: 'loginModal',
  registerModal: 'registerModal',
  passwordResetModal: 'passwordResetModal',
  successModal: 'successModal',
  reportFakeModal: 'reportFakeModal',
  ageVerifyModal: 'ageVerifyModal',
  agencyModal: 'agencyModal',
  independentUserModal: 'independentUserModal',
  reviewerUserModal: 'reviewerUserModal',
  warningModal: 'warningModal',
  buyCredits: 'buyCredits',
  buyPremium: 'buyPremium',
  transferCredits: 'transferCredits',
  setupTwoFactor: 'setupTwoFactor',
});

export enum MessagingApp {
  Telegram = 'Telegram',
  WhatsApp = 'WhatsApp',
  WeChat = 'WeChat',
  Viber = 'Viber',
  Line = 'Line',
  Signal = 'Signal',
}

export const localStorageKeys = {
  language: 'language',
  auth: 'auth',
};
