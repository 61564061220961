import { useState } from 'react';
import { toast } from 'material-react-toastify';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { Loader } from '~/components/Loader/Loader';
import { publicReviewsModel } from '~/models/publicReviewsModel/publicReviewsModel';

export default function PublicReviewList() {
  const { t } = useTranslation();
  const [pages, setPages] = useState(1);

  const isBigScreen = useMediaQuery({ query: '(min-width: 1024px)' });
  const itemsPerPage = isBigScreen ? 3 : 2;
  const itemCount = itemsPerPage * pages;

  const { data: reviews, isLoading } = useQuery(
    ['public-reviews', itemCount],
    () => publicReviewsModel.publicReviews({ Limit: itemCount }),
    {
      keepPreviousData: true,
      onError: () => toast.error(t('general.error')),
    },
  );

  const handleAddMoreClick = () => {
    setPages(pages + 1);
  };

  if (isLoading) {
    return <Loader />;
  }

  if (!reviews) {
    return null;
  }

  if (!reviews.items.length) {
    return <p className="text-white">{t('general.noMatchingResults')}</p>;
  }

  return (
    <div className="flex flex-col">
      <div className="grid grid-cols-2 gap-5 lg:grid-cols-3">
        {reviews.items.map((review, index) => (
          <div className="flex flex-col p-4 bg-primary gap-3 rounded-lg h-fit" key={index}>
            <span className="text-pink text-xl">{review.creatorNickName}</span>
            <p className="text-white text-lg break-words">{review.review}</p>
          </div>
        ))}
      </div>
      {reviews.items.length < reviews.totalCount && (
        <button className="text-white text-lg uppercase p-3" onClick={handleAddMoreClick}>
          {t('publicReviews.loadMore')}
        </button>
      )}
    </div>
  );
}
