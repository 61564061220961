type Item = { value: string; label: string };

export type RadioGroupProps = {
  items: Item[];
  title?: string;
  value: string | null;
  onChange?: (value: string | null) => void;
};

export default function RadioGroupComponent({ items, title, onChange, value }: RadioGroupProps) {
  const handleChange = (item: string) => {
    const nextValue = item === value ? null : item;

    onChange && onChange(nextValue);
  };

  return (
    <div>
      {title && (
        <div className="relative flex h-14 items-center justify-between px-7 text-sm bg-secondary text-white lg:h-20">
          <span>{title}</span>
        </div>
      )}

      <div className="py-3">
        {items.map((item) => (
          <label className="checkbox-container block" key={item.value}>
            <input
              checked={value === item.value}
              type="checkbox"
              onChange={() => handleChange(item.value)}
            />
            <span className="checkmark"></span>
            <span>{item.label}</span>
          </label>
        ))}
      </div>
    </div>
  );
}
