import classNames from 'classnames';
import { ReactNode, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { CSSTransition } from 'react-transition-group';
import { Icon } from '../Icon/Icon';
import './modal.scss';

interface ModalProps {
  isOpen: boolean;
  size?: 'md' | 'lg';
  onClose: () => void;
  children: ReactNode;
  dismissable?: boolean;
}

function usePrevious<T>(value: T) {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}

const Modal = ({ isOpen, onClose, children, dismissable = true, size = 'md' }: ModalProps) => {
  const dialogRoot = document.getElementById('dialog-root');
  const contentRef = useRef<HTMLInputElement | null>(null);

  const prevIsOpen = usePrevious(isOpen);

  useEffect(() => {
    if (isOpen && !prevIsOpen) {
      document.body.style.overflow = 'hidden';
    }

    if (!isOpen && prevIsOpen) {
      document.body.style.overflow = '';
    }
  });

  useEffect(() => {
    const handleEscapeKey = (event: KeyboardEvent) => {
      if (event.key === 'Escape' && dismissable) {
        onClose();
      }
    };

    const handleClickOutside = (event: MouseEvent) => {
      if (
        dismissable &&
        event.target &&
        contentRef.current &&
        !contentRef.current.contains(event.target as Node)
      ) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('keydown', handleEscapeKey);
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('keydown', handleEscapeKey);
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('keydown', handleEscapeKey);
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, onClose, dialogRoot, dismissable]);

  if (!dialogRoot) return null;

  return ReactDOM.createPortal(
    <CSSTransition in={isOpen} timeout={300} classNames="dialog-overlay" unmountOnExit mountOnEnter>
      <div className="dialog-overlay">
        <div
          className={classNames(
            'dialog-content relative px-2.5 w-full',
            size === 'lg' ? 'md:w-[840px]' : 'md:w-[420px]',
          )}
          ref={contentRef}
        >
          {dismissable && (
            <button
              onClick={onClose}
              className="modal__close group absolute top-2.5 right-5 flex h-8 w-8 items-center justify-center rounded-full bg-primary transition duration-150 ease-linear hover:bg-third z-10"
            >
              <Icon
                alt="close"
                icon="icon-close-alt"
                className="h-3 w-3 text-[#C4C4C4] group-hover:text-white transition duration-150 ease-linear"
              />
            </button>
          )}
          {children}
        </div>
      </div>
    </CSSTransition>,
    dialogRoot,
  );
};

export default Modal;
