import { P2PTransferRequest, V1TransfersP2PCreateData } from '~/api/data-contracts';
import { api } from '~/utils/api';

const URL = {
  CREDITS: '/transfers/p2p',
};

export const creditsModel = {
  transferCredits: (request: P2PTransferRequest) =>
    api.post<V1TransfersP2PCreateData>(URL.CREDITS, request),
};
