import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useSearchParams } from 'react-router-dom';
import {
  UserType,
  V1ReviewsListData,
  V1ReviewsListParams,
  V1ReviewsPersonalListData,
  V1ReviewsPersonalListParams,
} from '~/api/data-contracts';
import { Icon } from '~/components/Icon/Icon';
import { Loader } from '~/components/Loader/Loader';
import Pagination from '~/components/Pagination/Pagination';
import { useAuth } from '~/context/AuthContext';
import { useDebounce } from '~/hooks/useDebounce';
import { usePagination } from '~/hooks/usePagination';
import { ROUTES } from '~/router/Routes';
import { useReviewFilters } from '../../hooks/useReviewFilters/useReviewFilters';
import ReviewButton from '../ReviewButton/ReviewButton';
import ReviewListFiltering from './ReviewListFiltering';
import PublicReviews from '~/features/public-reviews/components/PublicReviews/PublicReviews';

type ReviewListProps = {
  adHref: (id?: string) => string;
  loadFn: (
    params: V1ReviewsListParams | V1ReviewsPersonalListParams,
  ) => Promise<V1ReviewsListData | V1ReviewsPersonalListData>;
  title: string;
};

export default function ReviewList({ adHref, loadFn, title }: ReviewListProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const { page, rowsPerPage, onPageChange } = usePagination({ rowsPerPage: 20 });
  const { apiParams: reviewFilterParams } = useReviewFilters();
  const { t } = useTranslation();

  const country = searchParams.get('country');
  const town = searchParams.get('town');
  const followed = searchParams.get('followed');
  const search = searchParams.get('search');

  const debouncedSearch = useDebounce(search, 500);

  const apiParams = useMemo(() => {
    const result: V1ReviewsListParams = {
      Skip: rowsPerPage * (page - 1),
      Limit: rowsPerPage,
    };

    if (country) {
      result.CountryNo = Number.parseInt(country);
    }

    if (town) {
      result.TownNo = Number.parseInt(town);
    }

    if (debouncedSearch) {
      result.Search = debouncedSearch;
    }

    if (followed) {
      result.FollowedByUserId = followed;
    }

    return { ...reviewFilterParams, ...result };
  }, [page, rowsPerPage, debouncedSearch, country, town, reviewFilterParams]);

  const handleSearchChange = (value: string) => {
    setSearchParams((params) => {
      params.set('search', value);
      return params;
    });
  };

  const { data, isFetching } = useQuery(['reviews', apiParams], () => loadFn(apiParams));

  const { hasOneOfRoles } = useAuth();

  const reviews = data?.items || [];

  const getContent = () => {
    if (isFetching) {
      return <Loader />;
    }

    if (!reviews.length) {
      return <p className="text-white">{t('general.noMatchingResults')}</p>;
    }

    return reviews.map((review) => (
      <ReviewButton key={review.id} review={review} adHref={adHref(review.id)} />
    ));
  };

  return (
    <div className="relative flex flex-1 flex-col gap-14 py-14 px-2.5 lg:px-14 xl:gap-16">
      <div className="hidden items-center justify-between xl:inline-flex">
        <span className="text-lg font-medium text-white lg:text-2xl">{title}</span>

        {hasOneOfRoles([UserType.Reviewer]) && (
          <div className="col text-end">
            <Link
              to={ROUTES.REVIEW_CREATE_EXTERNAL}
              className="btn-rounded h-12 w-auto bg-[#0E9B57] transition duration-150 ease-linear hover:bg-[#0C844A]"
            >
              <span className="px-16 font-bold flex">{t('reviews.createReview')}</span>
            </Link>
          </div>
        )}
      </div>
      <div className="relative flex flex-row gap-3.5">
        <div className="relative group w-full">
          <input
            type="text"
            className="form-control-input block w-full rounded-lg border-transparent border-[#272727] bg-secondary px-[20px] py-[17px] text-white placeholder:text-[#999999] focus:border-transparent"
            placeholder={t('reviews.searchReviews')}
            value={search || ''}
            onChange={(e) => handleSearchChange(e.target.value)}
          />
          <Icon
            alt="Search"
            icon="icon-search"
            className="absolute h-4 w-4 top-1/2 right-5 -translate-y-1/2 text-[#999999]"
          />
        </div>

        <ReviewListFiltering />
        {/* <ReviewListSorting onChange={handleSortChange} value={sort as ReviewListSortingValue} /> */}
      </div>
      <div className="grid grid-cols-2 gap-6 sm:grid-cols-3 lg:grid-cols-4 2xl:grid-cols-6">
        {getContent()}
      </div>
      <Pagination
        itemCount={data?.totalCount || 0}
        onPageChange={onPageChange}
        page={page}
        rowsPerPage={rowsPerPage}
      />

      <PublicReviews />
    </div>
  );
}
