import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Drawer from 'react-modern-drawer';
import { Link, NavLink, matchPath, useLocation, useSearchParams } from 'react-router-dom';
import { UserType } from '~/api/data-contracts';
import Counts from '~/components/Counts/Counts';
import { Icon } from '~/components/Icon/Icon';
import { LangDropdown } from '~/components/Layout/children/LangDropdown';
import { MobileLanguage } from '~/components/Layout/children/MobileLanguage';
import { MODAL_IDS } from '~/config/consts';
import { useAuth } from '~/context/AuthContext';
import { useModals } from '~/context/ModalContext';
import { locationCounterModel } from '~/models/locationCounterModel/locationCounterModel';
import { ROUTES } from '~/router/Routes';
import { CreditsDropdown } from './CreditsDropdown';
import { UserDropdown } from './UserDropdown';

export default function Header() {
  const { isAuthenticated, hasOneOfRoles, user, logout, getHomePath } = useAuth();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();
  const { showModal } = useModals();
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);
  const [isMobileLanguageOpen, setIsMobileLanguageOpen] = useState(false);
  const [isMobileLocationOpen, setIsMobileLocationOpen] = useState(false);
  const townNo = searchParams.get('town');

  const toggleOffMenus = () => {
    setIsMobileLanguageOpen(false);
    setIsMobileNavOpen(false);
    setIsMobileLocationOpen(false);
  };

  useEffect(() => {
    toggleOffMenus();
  }, [pathname, searchParams]);

  const matchesReviews = matchPath({ path: ROUTES.REVIEWS_LIST, end: false }, pathname);
  const matchesReviewCreate = matchPath({ path: ROUTES.REVIEW_CREATE_EXTERNAL }, pathname);
  const matchesAds = matchPath({ path: ROUTES.AD_LIST, end: false }, pathname);
  const matchesAdCreate = matchPath({ path: ROUTES.AD_CREATE }, pathname);

  const hanldeOverlayClick = () => {
    if (isMobileLanguageOpen) {
      return setIsMobileLanguageOpen(false);
    }

    setIsMobileNavOpen(false);
  };

  return (
    <>
      <header className="z-[10001] relative xl:sticky top-0 flex h-16 xl:h-20 items-center justify-between bg-primary">
        <Link to={getHomePath()} className={classNames('ml-7 xl:hidden')}>
          <Icon alt="Logo" icon="logo" className="w-24 h-9 xl:w-[140px] xl:h-[40px]" />
        </Link>

        <div className="flex xl:hidden">
          <button
            id="mobile-location-button"
            className="relative flex h-16 w-16 items-center justify-center"
            onClick={() => {
              setIsMobileLanguageOpen(false);
              setIsMobileNavOpen(false);
              setIsMobileLocationOpen(!isMobileLocationOpen);
            }}
          >
            {townNo && <span className="absolute bg-pink w-2 h-2 rounded-full top-3 right-3" />}
            <Icon
              alt="Location"
              icon="icon-location"
              className={classNames('w-6 h-6', isMobileLocationOpen ? 'text-pink' : 'text-white')}
            />
          </button>

          <button
            className="flex h-16 w-16 items-center justify-center"
            onClick={() => {
              setIsMobileLocationOpen(false);
              setIsMobileNavOpen(!isMobileNavOpen);
            }}
          >
            {isMobileNavOpen ? (
              <Icon alt="Close" icon="icon-close" className="text-pink w-6 h-6" />
            ) : (
              <Icon alt="Menu" icon="icon-burger" className="w-6 h-6" />
            )}
          </button>
        </div>
        <div
          id="mobile-search"
          className="absolute top-16 z-[99] hidden h-16 w-full bg-third xl:hidden"
        >
          <input
            id="mobile-search-input"
            type="text"
            className="h-full w-full border-transparent bg-transparent pl-7 pr-20 text-white placeholder:text-gray focus:border-transparent focus:ring-0 shadow-[0px_30px_30px_rgba(0,0,0,0.15)]"
          />
          <Icon
            alt="Search"
            icon="icon-search"
            className="absolute h-6 w-6 top-1/2 right-4 transform -translate-y-1/2 text-pink"
          />
          <div className="search-gradient absolute bottom-0 h-1 w-full"></div>
        </div>
        <div className="hidden h-full flex-1 items-center justify-between px-16 xl:flex">
          <div className="inline-flex h-full">
            {hasOneOfRoles([UserType.Reviewer]) && (
              <NavLink
                to={ROUTES.REVIEWS_LIST}
                className={({ isActive }) =>
                  classNames('nav-link', {
                    'nav-link-active': isActive,
                  })
                }
              >
                <Icon alt="MessageStar" icon="icon-messages-star" />
                <span className="text-sm text-white">{t('reviews.reviews')}</span>
                <div className="search-gradient absolute bottom-0 left-0 hidden h-[4px] w-full"></div>
              </NavLink>
            )}

            <NavLink
              to={ROUTES.AD_LIST}
              className={({ isActive }) =>
                classNames('nav-link', {
                  'nav-link-active': isActive,
                })
              }
            >
              <Icon alt="Megaphone" icon="icon-megaphone" />
              <span className="text-sm text-white">{t('ads.ads')}</span>
              <div className="search-gradient absolute bottom-0 left-0 hidden h-[4px] w-full"></div>
            </NavLink>

            {isAuthenticated && (
              <NavLink
                to={ROUTES.HONOR}
                className={({ isActive }) =>
                  classNames('nav-link', {
                    'nav-link-active': isActive,
                  })
                }
              >
                <Icon alt="Honor" icon="icon-clipboard" />
                <span className="text-sm text-white">{t('header.honor')}</span>
                <div className="search-gradient absolute bottom-0 left-0 hidden h-[4px] w-full"></div>
              </NavLink>
            )}

            {isAuthenticated && (
              <button onClick={() => showModal(MODAL_IDS.buyPremium)} className="nav-link">
                <Icon alt="Honor" icon="icon-star" />
                <span className="text-sm text-white">{t('premium.premiumMembership')}</span>
                <div className="search-gradient absolute bottom-0 left-0 hidden h-[4px] w-full"></div>
              </button>
            )}
          </div>
          <div className="inline-flex items-center justify-center gap-2.5">
            {!isAuthenticated && (
              <>
                <button
                  className="btn-rounded px-5 py-2.5 transition duration-150 ease-linear hover:border-transparent hover:text-pink hover:shadow-[0px_0px_3px_2px_#E90F5D]"
                  onClick={() => showModal(MODAL_IDS.loginModal)}
                >
                  {t('header.logIn')}
                </button>
                <button
                  className="btn-rounded border-transparent bg-pink px-5 py-2.5 transition duration-150 ease-linear hover:bg-[#C60D4F]"
                  onClick={() => showModal(MODAL_IDS.registerModal)}
                >
                  {t('header.register')}
                </button>
              </>
            )}
            {/* 
            {isAuthenticated && (
              <button
                className="flex h-16 w-auto items-center justify-center gap-2 px-[20px]"
                onClick={() => showModal(MODAL_IDS.buyCredits)}
              >
                <Icon alt="Wallet" icon="icon-coins" className="text-white w-6 h-6" />
                <div className="flex flex-col">
                  <span className="text-pink leading-4">
                    {user ? user.balance.balance.toFixed(2) : '-'}
                  </span>
                  <span className="text-white text-sm leading-4">{t('credits.credits')}</span>
                </div>
              </button>
            )} */}

            {isAuthenticated && <CreditsDropdown />}

            {isAuthenticated && <UserDropdown />}

            <LangDropdown />
          </div>
        </div>
      </header>

      <div className="relative xl:hidden">
        <Drawer
          open={isMobileLocationOpen}
          onClose={() => setIsMobileLocationOpen(false)}
          direction="top"
          zIndex={9999}
          lockBackgroundScroll
          size="auto"
          className={classNames('!bg-secondary', { '!top-[64px]': isMobileLocationOpen })}
        >
          <div id="mobile-location" className="w-full max-h-[80vh] overflow-auto">
            {hasOneOfRoles([UserType.Reviewer]) && matchesReviews ? (
              <Counts
                fetchFn={locationCounterModel.reviewCounts}
                path={ROUTES.REVIEWS_LIST}
                cacheKey="reviews"
              />
            ) : (
              <Counts
                fetchFn={locationCounterModel.adCounts}
                path={ROUTES.AD_LIST}
                cacheKey="ads"
              />
            )}
          </div>
        </Drawer>
      </div>

      {isAuthenticated && (
        <nav className="z-[999] sticky top-0 flex h-16 lg:h-20 bg-secondary xl:hidden">
          <div className="flex justify-between md:justify-start h-full w-full px-5">
            {hasOneOfRoles([UserType.Reviewer]) && (
              <NavLink
                to={ROUTES.REVIEWS_LIST}
                className={() =>
                  classNames('nav-link', {
                    'nav-link-active': matchesReviews && !matchesReviewCreate,
                  })
                }
              >
                <Icon alt="MessageStar" icon="icon-messages-star" />
                <span className="text-sm text-white">{t('reviews.reviews')}</span>
                <div className="search-gradient absolute bottom-0 left-0 hidden h-[4px] w-full"></div>
              </NavLink>
            )}

            <NavLink
              to={ROUTES.AD_LIST}
              className={() =>
                classNames('nav-link', {
                  'nav-link-active': matchesAds && !matchesAdCreate,
                })
              }
            >
              <Icon alt="Megaphone" icon="icon-megaphone" />
              <span className="text-sm text-white">{t('ads.ads')}</span>
              <div className="search-gradient absolute bottom-0 left-0 hidden h-[4px] w-full"></div>
            </NavLink>

            {hasOneOfRoles([UserType.Agency, UserType.Independent]) && (
              <NavLink
                to={ROUTES.AD_CREATE}
                className={({ isActive }) =>
                  classNames('nav-link', {
                    'nav-link-active': isActive,
                  })
                }
              >
                <Icon alt="Create" icon="icon-plus" />
                <span className="text-sm text-white">{t('ads.createNewAd')}</span>
                <div className="search-gradient absolute bottom-0 left-0 hidden h-[4px] w-full"></div>
              </NavLink>
            )}

            {hasOneOfRoles([UserType.Reviewer]) && (
              <NavLink
                to={ROUTES.REVIEW_CREATE_EXTERNAL}
                className={({ isActive }) =>
                  classNames('nav-link', {
                    'nav-link-active': isActive,
                  })
                }
              >
                <Icon alt="Create" icon="icon-plus" />
                <span className="text-sm text-white">{t('reviews.createReview')}</span>
                <div className="search-gradient absolute bottom-0 left-0 hidden h-[4px] w-full"></div>
              </NavLink>
            )}
          </div>
        </nav>
      )}

      <Drawer
        open={isMobileNavOpen}
        onClose={hanldeOverlayClick}
        direction="right"
        zIndex={9999}
        lockBackgroundScroll
        size="20rem"
        className="mobile-nav-drawer !bg-secondary"
      >
        <div className="mobile-nav-wrapper">
          <div className="mobile-nav-container">
            <div className="flex flex-col overflow-y-auto">
              {!isAuthenticated && (
                <ul className="mobile-nav-items gap-2.5 p-7">
                  <li>
                    <button
                      className="mobile-login btn-rounded bg-primary py-2.5"
                      onClick={() => {
                        toggleOffMenus();
                        showModal(MODAL_IDS.loginModal);
                      }}
                    >
                      {t('header.logIn')}
                    </button>
                  </li>
                  <li>
                    <button
                      className="mobile-register btn-rounded bg-pink py-2.5 transition duration-150 ease-linear hover:bg-[#C60D4F]"
                      onClick={() => {
                        toggleOffMenus();
                        showModal(MODAL_IDS.registerModal);
                      }}
                    >
                      {t('header.register')}
                    </button>
                  </li>
                </ul>
              )}

              {isAuthenticated && (
                <ul className="mobile-nav-items py-8">
                  <li className="mobile-nav-header">{t('header.wallet')}</li>
                  <li className="flex h-16 w-auto items-center gap-2 px-[28px]">
                    <Icon alt="Wallet" icon="icon-coins" className="text-white w-6 h-6" />
                    <div className="flex flex-col">
                      <span className="text-pink leading-4">
                        {user ? user.balance.balance.toFixed(2) : '-'}
                      </span>
                      <span className="text-white text-sm leading-4 uppercase">
                        {t('credits.credits')}
                      </span>
                    </div>
                  </li>
                  <li className="mobile-nav-item">
                    <button
                      onClick={() => {
                        toggleOffMenus();
                        showModal(MODAL_IDS.buyCredits);
                      }}
                      className="mobile-nav-link active"
                    >
                      <Icon alt="MessageStar" icon="icon-plus-2" id="caret" className="w-4 h-4" />
                      <span>{t('credits.buyCredits')}</span>
                    </button>
                  </li>
                  <li className="mobile-nav-item">
                    <button
                      onClick={() => {
                        toggleOffMenus();
                        showModal(MODAL_IDS.transferCredits);
                      }}
                      className="mobile-nav-link active"
                    >
                      <Icon alt="MessageStar" icon="icon-up" id="caret" className="w-4 h-4" />
                      <span>{t('credits.sendCredits')}</span>
                    </button>
                  </li>
                </ul>
              )}

              <ul className="mobile-nav-items py-8">
                <li className="mobile-nav-header">{t('header.categories')}</li>
                {isAuthenticated && (
                  <li className="mobile-nav-item">
                    <button
                      onClick={() => {
                        toggleOffMenus();
                        showModal(MODAL_IDS.buyPremium);
                      }}
                      className="mobile-nav-link active"
                    >
                      <Icon alt="MessageStar" icon="icon-star" id="caret" className="w-4 h-4" />
                      <span>{t('premium.premiumMembership')}</span>
                    </button>
                  </li>
                )}

                {hasOneOfRoles([UserType.Reviewer]) && (
                  <li className="mobile-nav-item">
                    <NavLink
                      to={ROUTES.REVIEWS_LIST}
                      className={({ isActive }) =>
                        classNames('mobile-nav-link active', {
                          active: isActive,
                        })
                      }
                    >
                      <Icon
                        alt="MessageStar"
                        icon="icon-messages-star"
                        id="caret"
                        className="w-4 h-4"
                      />
                      <span>{t('reviews.reviews')}</span>
                    </NavLink>
                  </li>
                )}

                <li className="mobile-nav-item">
                  <NavLink
                    to={ROUTES.AD_LIST}
                    className={({ isActive }) =>
                      classNames('mobile-nav-link active', {
                        active: isActive,
                      })
                    }
                  >
                    <Icon
                      alt="MessageStar"
                      icon="icon-messages-star"
                      id="caret"
                      className="w-4 h-4"
                    />
                    <span>{t('ads.ads')}</span>
                  </NavLink>
                </li>

                {isAuthenticated && (
                  <li className="mobile-nav-item">
                    <NavLink
                      to={ROUTES.HONOR}
                      className={({ isActive }) =>
                        classNames('mobile-nav-link active', {
                          active: isActive,
                        })
                      }
                    >
                      <Icon alt="Honor" icon="icon-clipboard" id="caret" className="w-4 h-4" />
                      <span>{t('header.honor')}</span>
                    </NavLink>
                  </li>
                )}
              </ul>

              <ul className="mobile-nav-items py-8">
                <li className="mobile-nav-header">{user?.nickName}</li>
                {isAuthenticated && (
                  <li className="mobile-nav-item">
                    <Link to={ROUTES.USER_PROFILE} className="mobile-nav-link">
                      {t('header.userProfile')}
                    </Link>
                  </li>
                )}

                {isAuthenticated && (
                  <li className="mobile-nav-item">
                    <Link to={ROUTES.USER_SETTINGS} className="mobile-nav-link">
                      {t('header.userSettings')}
                    </Link>
                  </li>
                )}

                {hasOneOfRoles([UserType.Reviewer]) && (
                  <li className="mobile-nav-item">
                    <Link to={ROUTES.REVIEW_LIST_PERSONAL} className="mobile-nav-link">
                      {t('reviews.myReviews')}
                    </Link>
                  </li>
                )}

                {hasOneOfRoles([UserType.Agency, UserType.Independent]) && (
                  <li className="mobile-nav-item">
                    <Link to={ROUTES.AD_LIST_PERSONAL} className="mobile-nav-link">
                      {t('ads.myAds')}
                    </Link>
                  </li>
                )}
              </ul>

              <MobileLanguage isOpen={isMobileLanguageOpen} onOpen={setIsMobileLanguageOpen} />

              <ul className="mobile-nav-items py-8">
                {isAuthenticated && (
                  <li className="mobile-nav-item">
                    <Icon alt="Log out" icon="icon-log-out" id="log-out" className="w-4 h-4" />
                    <button
                      className="mobile-nav-link"
                      id="mobile-logout"
                      onClick={() => {
                        toggleOffMenus();
                        logout();
                      }}
                    >
                      <span>{t('header.logOut')}</span>
                    </button>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
      </Drawer>
    </>
  );
}
