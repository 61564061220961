import { useQuery } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { Loader } from '~/components/Loader/Loader';
import { useAuth } from '~/context/AuthContext';
import UserProfile from '~/features/auth/components/UserProfile/UserProfile';
import { usersModel } from '~/models/usersModel/usersModel';

export default function UserPage() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { getHomePath } = useAuth();

  const { data: user } = useQuery(['reputation'], () => usersModel.user(id!), {
    enabled: Boolean(id),
  });

  if (!id) {
    navigate(getHomePath());
    return null;
  }

  if (!user) {
    return <Loader />;
  }

  return <UserProfile userId={id} user={user} />;
}
