import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { InferType, boolean, object, string } from 'yup';
import FormCheckboxAlt from '~/components/form/FormCheckboxAlt';
import FormRadioAlt from '~/components/form/FormRadioAlt';

export enum ReviewListFilteringKey {
  created = 'created',
}

export const reviewFilterSchema = object({
  sorting: string(),
  followedByMe: boolean(),
  likedByMe: boolean(),
});

export type ReviewListFilteringFormValues = InferType<typeof reviewFilterSchema>;

type ReviewListFilteringFormProps = {
  onCancel: () => void;
  onReset: (value: ReviewListFilteringFormValues) => void;
  onSubmit: (value: ReviewListFilteringFormValues) => void;
  value: ReviewListFilteringFormValues;
};

export default function ReviewListFilteringForm({
  onCancel,
  onReset,
  onSubmit,
  value,
}: ReviewListFilteringFormProps) {
  const { t } = useTranslation();

  const { control, handleSubmit } = useForm<ReviewListFilteringFormValues>({
    defaultValues: value,
    mode: 'onSubmit',
  });

  const sortOptions = [
    {
      value: 'created',
      label: t('sorting.mostRecent'),
    },
    {
      value: 'liked',
      label: t('sorting.mostLiked'),
    },
    {
      value: 'rating',
      label: t('sorting.bestRated'),
    },
    {
      value: 'looks',
      label: t('sorting.bestLooks'),
    },
    {
      value: 'blowjob',
      label: t('sorting.bestBlowjob'),
    },
    {
      value: 'communication',
      label: t('sorting.bestCommunication'),
    },
    {
      value: 'gfe',
      label: t('sorting.bestGfe'),
    },
  ];

  const handleReset = () => {
    onReset({});
  };

  return (
    <form
      className="flex flex-col bg-primary h-full max-h-[100vh]"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="flex justify-between p-3.5 bg-secondary">
        <div className="flex flex-1">
          <button className="link" onClick={onCancel} type="button">
            {t('filters.cancel')}
          </button>
        </div>

        <span className="text-white">{t('filters.filterReviews')}</span>

        <div className="flex flex-1 justify-end">
          <button className="link" onClick={handleReset} type="button">
            {t('filters.clearFilters')}
          </button>
        </div>
      </div>

      <div className="flex-1 overflow-auto">
        <div className="flex flex-col gap-2 py-3.5">
          <FormCheckboxAlt
            control={control}
            name="followedByMe"
            label={t('filters.followedByMe')}
          />
          <FormCheckboxAlt control={control} name="likedByMe" label={t('filters.likedByMe')} />
        </div>

        <FormRadioAlt
          control={control}
          title={t('sorting.sorting')}
          items={sortOptions}
          name="sorting"
        />
      </div>

      <div className="p-3.5 bg-secondary">
        <button
          className="btn-rounded h-12 w-fit bg-pink transition duration-150 ease-linear hover:bg-[#C60D4F] px-6"
          type="submit"
        >
          {t('filters.applyFilters')}
        </button>
      </div>
    </form>
  );
}
