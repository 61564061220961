import classNames from 'classnames';
import { Controller, FieldPath, FieldValues, UseControllerProps } from 'react-hook-form';
import Select from 'react-select';
import FormFieldError from './FormFieldError';
import { useTranslation } from 'react-i18next';

type FormSelectProps<
  TValue,
  TOption extends { value: TValue; label: string },
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = UseControllerProps<TFieldValues, TName> & {
  options?: TOption[];
  isDisabled?: boolean;
  isMulti?: boolean;
  label?: string;
  placeholder?: string;
};

const FormSelect = <
  TValue,
  TOption extends { value: TValue; label: string },
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  control,
  name,
  options,
  isDisabled,
  isMulti,
  placeholder,
  label,
}: FormSelectProps<TValue, TOption, TFieldValues, TName>) => {
  const { t } = useTranslation();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => {
        const getValue = () => {
          if (!options) {
            return null;
          }

          if (Array.isArray(field.value)) {
            return field.value.map((f: string | number) => options.find((o) => o.value === f));
          }

          return options.find((o) => o.value === field.value);
        };

        return (
          <div>
            {label && (
              <label className="text-[#999999] text-sm pl-5" htmlFor={`react-select-${name}-input`}>
                {label}
              </label>
            )}
            <Select
              ref={field.ref}
              onFocus={(e) => {
                if (e.target.autocomplete) {
                  e.target.autocomplete = 'nope';
                }
              }}
              name={name}
              id={name}
              isMulti={isMulti}
              isDisabled={isDisabled}
              instanceId={name}
              className="react-select"
              placeholder={placeholder !== undefined ? placeholder : t('general.select')}
              options={options}
              menuPortalTarget={document.body}
              classNames={{
                control: (state) =>
                  classNames('react-select__control', {
                    focused: state.isFocused,
                    errored: fieldState.error,
                  }),
                singleValue: () => 'react-select__single-value',
                menu: () => 'react-select__menu',
                indicatorSeparator: () => 'react-select__separator',
                option: (state) =>
                  classNames('react-select__option', {
                    selected: state.isSelected,
                    focused: state.isFocused,
                  }),
                input: () => 'react-select__input',
                placeholder: () => 'react-select__placeholder',
                menuPortal: () => 'react-select__portal',
              }}
              value={getValue()}
              onChange={(val) => {
                if (Array.isArray(val)) {
                  const values = val.map((v: TOption) => v.value);

                  field.onChange(values);
                  return;
                }

                const value = val.value;
                field.onChange(value);
              }}
            />
            {fieldState.error && <FormFieldError error={fieldState.error} />}
          </div>
        );
      }}
    />
  );
};

export default FormSelect;
