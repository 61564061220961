import {
  CreateExternalReviewRequest,
  CreateInternalReviewRequest,
  UpdateReviewBaseInfoRequest,
  V1ReviewsListParams,
  V1ReviewsPersonalListData,
} from '~/api/data-contracts';
import { QueryParamsType } from '~/api/http-client';
import {
  CreateReviewResponse,
  GetReviewResponse,
  GetReviewsPersonalResponse,
  GetReviewsResponse,
  ReviewRatesResponse,
  ReviewSectionsResponse,
} from '~/types/reviews';
import { api } from '~/utils/api';

const URL = {
  REVIEWS: (params: QueryParamsType) => `/reviews?${new URLSearchParams(params).toString()}`,
  REVIEWS_INTERNAL: '/reviews/internal',
  REVIEWS_EXTERNAL: '/reviews/external',
  REVIEWS_PUBLIC: '/reviews/public',
  RATES: '/reviews/rate-time',
  DESCRIPTIONS: '/reviews/descriptions',
  REVIEW_EDIT: (id: string) => `/reviews/${id}`,
  REVIEW_DETAILED: (id: string) => `/reviews/${id}`,
  REVIEWS_PERSONAL: (params: QueryParamsType) =>
    `/reviews/personal?${new URLSearchParams(params).toString()}`,
  REVIEW_PERSONAL_DETAILED: (id: string) => `/reviews/${id}/personal`,
  REVIEW_IMAGES: (id: string) => `/reviews/${id}/images`,
  REVIEW_DESCRIPTIONS: (id: string) => `/reviews/${id}/descriptions`,
  REVIEW_RATES: (id: string) => `/reviews/${id}/rates`,
  REVIEW_SECTIONS: (id: string) => `/reviews/${id}/sections`,
  REVIEW_BASE: (id: string) => `/reviews/${id}/base`,
  SECTIONS: '/reviews/sections',
  REVIEW_ACTIVATE: (id: string) => `/reviews/${id}/activate`,
  REVIEW_DEACTIVATE: (id: string) => `/reviews/${id}/disable`,
};

export const reviewModel = {
  reviews: (request: V1ReviewsListParams) => api.get<GetReviewsResponse>(URL.REVIEWS(request)),
  reviewsPublic: () => api.get<GetReviewsResponse>(URL.REVIEWS_PUBLIC),
  reviewDetailed: (id: string) => api.get<GetReviewResponse>(URL.REVIEW_DETAILED(id)),
  reviewsPersonal: (request: V1ReviewsListParams) =>
    api.get<V1ReviewsPersonalListData>(URL.REVIEWS_PERSONAL(request)),
  reviewImages: (id: string, request: FormData) => api.put(URL.REVIEW_IMAGES(id), request),
  reviewRates: () => api.get<ReviewRatesResponse>(URL.RATES),
  reviewSections: () => api.get<ReviewSectionsResponse>(URL.SECTIONS),
  reviewDetailedPersonal: (id: string) =>
    api.get<GetReviewsPersonalResponse>(URL.REVIEW_PERSONAL_DETAILED(id)),
  createReviewInternal: (request: CreateInternalReviewRequest) =>
    api.post<CreateReviewResponse>(URL.REVIEWS_INTERNAL, request),
  createReviewExternal: (request: CreateExternalReviewRequest) =>
    api.post<CreateReviewResponse>(URL.REVIEWS_EXTERNAL, request),
  editReview: (id: string, request: UpdateReviewBaseInfoRequest) =>
    api.put<CreateReviewResponse>(URL.REVIEW_EDIT(id), request),
  // reviewRatesUpdate: (id: string, request: Full<ReviewFormValuesRates>) =>
  //   api.put<void>(URL.REVIEW_RATES(id), request),
  // reviewSectionsUpdate: (id: string, request: Full<ReviewFormValuesSections>) =>
  //   api.put<void>(URL.REVIEW_SECTIONS(id), request),
  // reviewBaseUpdate: (id: string, request: ReviewFormValuesBaseRequest) =>
  //   api.put<void>(URL.REVIEW_BASE(id), request),
  reviewActivate: (id: string) => api.patch(URL.REVIEW_ACTIVATE(id)),
  reviewDeactivate: (id: string) => api.patch(URL.REVIEW_DEACTIVATE(id)),
};
