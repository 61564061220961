import { AxiosError } from 'axios';
import { toast } from 'material-react-toastify';
import { FieldPath, FieldValues, UseFormSetError } from 'react-hook-form';
import { ErrorResponse } from '~/types/sharedTypes';
import i18n from '~/i18n';

export const processFormError =
  <
    TFieldValues extends FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
  >(
    setError: UseFormSetError<TFieldValues>,
  ) =>
  async (error: unknown) => {
    if (error instanceof AxiosError && Array.isArray(error.response?.data?.errors)) {
      const errorResponse = error.response?.data as ErrorResponse;
      errorResponse.errors.forEach((e) => {
        if (!e.parameter) {
          return setError('root', {
            message: i18n.t(`serverError.${e.errorCode}`),
          });
        }

        setError(e.parameter as TName, {
          message: i18n.t(`serverError.${e.errorCode}`),
        });
      });
    } else {
      const message = i18n.t('general.error');
      toast.error(message);
    }
  };

export const errorContainsCode = (error: unknown, code: number) => {
  if (error instanceof AxiosError && Array.isArray(error.response?.data?.errors)) {
    const errorResponse = error.response?.data as ErrorResponse;
    const found = errorResponse.errors.find((e) => e.errorCode === code);

    return Boolean(found);
  } else {
    return false;
  }
};

export const showFirstOrGeneric = (error: unknown) => {
  const genericError = i18n.t('general.error');

  if (error instanceof AxiosError && Array.isArray(error.response?.data?.errors)) {
    const errorResponse = error.response?.data as ErrorResponse;
    const first = errorResponse.errors[0];

    return toast.error(first ? first.message : genericError);
  } else {
    toast.error(genericError);
  }
};
