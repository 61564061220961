import { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { LayoutRouteProps } from 'react-router-dom';
import { MODAL_IDS } from '~/config/consts';
import { useModals } from '~/context/ModalContext';
import { VERIFIED_COOKIE } from '../modals/AgeVerifyModal';
import Footer from './children/Footer';
import Header from './children/Header';
import Sidebar from './children/Sidebar';
import './over.scss';

export default function Layout({ children }: LayoutRouteProps) {
  const { showModal } = useModals();
  const [cookies] = useCookies();

  useEffect(() => {
    if (cookies[VERIFIED_COOKIE] !== 'true') {
      showModal(MODAL_IDS.ageVerifyModal);
    }
  }, []);

  return (
    <div className="relative mx-auto flex min-h-screen flex-1 flex-col bg-third lg:flex-row">
      <Sidebar />
      <div className="flex-1">
        <Header />
        {children}
        <Footer />
      </div>
    </div>
  );
}
