interface MedalCountProps {
  count: number;
}

export const MedalCount = ({ count }: MedalCountProps) => {
  if (count === 1) {
    return (
      <span className="inline-flex h-5 w-5 items-center justify-center rounded-full bg-[#FCC417] text-xs font-extrabold text-[#999999] shrink-0">
        <span className="inline-flex h-[18px] w-[18px] items-center justify-center rounded-full border-[1px] border-[#F18319] text-[#F18319]">
          {count}
        </span>
      </span>
    );
  }

  if (count === 2) {
    return (
      <span className="inline-flex h-5 w-5 items-center justify-center rounded-full bg-[#CECDD2] text-xs font-extrabold text-[#999999] shrink-0">
        <span className="inline-flex h-[18px] w-[18px] items-center justify-center rounded-full border-[1px] border-[#9B9B9D] text-[#9B9B9D]">
          {count}
        </span>
      </span>
    );
  }

  if (count === 3) {
    return (
      <span className="inline-flex h-5 w-5 items-center justify-center rounded-full bg-[#F79429] text-xs font-extrabold text-[#999999] shrink-0">
        <span className="inline-flex h-[18px] w-[18px] items-center justify-center rounded-full border-[1px] border-[#D25116] text-[#D25116]">
          {count}
        </span>
      </span>
    );
  }

  return (
    <span className="inline-flex h-5 w-5 items-center justify-center rounded-full bg-[#272727] text-xs font-extrabold text-[#999999] shrink-0">
      {count}
    </span>
  );
};
