import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { number, object, string } from 'yup';
import FormField from '~/components/form/FormField';
import FormNumberField from '~/components/form/FormNumberField';
import { MODAL_IDS } from '~/config/consts';
import { useAuth } from '~/context/AuthContext';
import { useModals } from '~/context/ModalContext';
import CodeConfirmFlow, {
  StepContext,
} from '~/features/twoFactor/components/CodeConfirmFlow/CodeConfirmFlow';
import i18n from '~/i18n';
import { creditsModel } from '~/models/creditsModel/creditsModel';
import { showFirstOrGeneric } from '~/utils/error';

const requiredMessage = i18n.t('validation.required');
const creditLimitMessage = i18n.t('credits.exceededLimit');

type TransferCreditsSchemaOptions = {
  creditLimit: number;
  isUserRequired: boolean;
};

export const transferCreditsSchema = ({
  creditLimit,
  isUserRequired,
}: TransferCreditsSchemaOptions) =>
  object({
    user: string().when([], {
      is: () => isUserRequired,
      then: (schema) => schema.required(requiredMessage),
    }),
    credits: number()
      .required(requiredMessage)
      .moreThan(0, requiredMessage)
      .lessThan(creditLimit, creditLimitMessage),
  });

export type TransferCreditsFormValues = {
  credits: number | null;
  user: string | undefined;
};

export type TransferCreditsModalProps = {
  context?: {
    userId: string;
    userName: string;
  };
};

export default function TransferCreditsModal({ context }: TransferCreditsModalProps) {
  const defaultValues = {
    credits: null,
    user: '',
  };

  const { fetchUser, user } = useAuth();
  const { hideModal } = useModals();

  const isUserInputRequired = !context;

  const { control, handleSubmit, watch } = useForm<TransferCreditsFormValues>({
    defaultValues,
    resolver: yupResolver<TransferCreditsFormValues>(
      transferCreditsSchema({
        creditLimit: user?.balance.balance || 0,
        isUserRequired: isUserInputRequired,
      }),
    ),
    shouldFocusError: true,
  });

  const {
    mutate: transferCredits,
    isLoading: isTransferCreditsLoading,
    isSuccess: isTransferCreditsSuccess,
  } = useMutation(creditsModel.transferCredits, {
    onSuccess: () => {
      fetchUser();
    },
    onError: showFirstOrGeneric,
  });

  const { t } = useTranslation();
  const { showModal } = useModals();

  if (!user) {
    return null;
  }

  const handleConfirm = async (
    values: TransferCreditsFormValues /* , confirm: ConfirmFormValues */,
  ) => {
    const receiverId = context?.userId || values.user;

    if (!receiverId) {
      throw new Error('Missing reciever');
    }

    transferCredits({
      receiverId,
      amount: values.credits || 0,
      // verificationCode: '',
    });
  };

  const handleEnd = () => {
    hideModal();
  };

  const transferFormId = 'transferForm';

  const userName = context?.userName || watch('user');

  const getSteps = ({ nextStep }: StepContext<TransferCreditsFormValues>) => {
    const handleFormSubmit = (values: TransferCreditsFormValues) => {
      nextStep(values);
    };

    return [
      {
        formId: transferFormId,
        content: (
          <form
            id={transferFormId}
            className="flex flex-col gap-6"
            onSubmit={handleSubmit(handleFormSubmit)}
          >
            <div className="flex flex-1 items-end gap-3.5 flex-wrap">
              <div className="w-full p-3.5 text-center text-2xl text-white">
                {context
                  ? t('credits.transferToUser', { user: context.userName })
                  : t('credits.transferToAnotherUser')}
              </div>

              <div className="flex flex-col flex-1 gap-3.5">
                {!context && (
                  <FormField
                    autoFocus={isUserInputRequired}
                    control={control}
                    name="user"
                    label={t('credits.userId')}
                  />
                )}

                <div className="flex gap-3.5 shrink-0 max-w-full">
                  <div className="flex-1">
                    <FormNumberField
                      autoFocus={!isUserInputRequired}
                      control={control}
                      name="credits"
                      label={t('credits.supportCredits')}
                    />
                  </div>

                  <div className="flex flex-col flex-1 justify-end">
                    <span className="text-gray">
                      <span className="text-xl mr-1">{user.balance.balance.toFixed(2)}</span>
                      {t('credits.creditLimit')}
                    </span>

                    <div>
                      <button
                        className="lg:hidden link"
                        onClick={() => showModal(MODAL_IDS.buyCredits)}
                        type="button"
                      >
                        + {t('credits.buyCredits')}
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="hidden lg:block">
                <button
                  className="btn-rounded h-12 !w-auto bg-pink transition duration-150 ease-linear hover:bg-[#C60D4F] px-6"
                  onClick={() => showModal(MODAL_IDS.buyCredits)}
                  type="button"
                >
                  {t('credits.buyCredits')}
                </button>
              </div>
            </div>
          </form>
        ),
      },
    ];
  };

  return (
    <div
      className="relative max-h-[90vh] w-full overflow-auto rounded-lg bg-primary"
      role="dialog"
      aria-modal="true"
    >
      <CodeConfirmFlow
        getSteps={getSteps}
        onConfirm={handleConfirm}
        onEnd={handleEnd}
        confirmMessage={(values: TransferCreditsFormValues) =>
          t('credits.transferConfirm', { user: userName, credits: values.credits })
        }
        successMessage={(values: TransferCreditsFormValues) =>
          t('credits.transferSuccess', { user: userName, credits: values.credits })
        }
        isLoading={isTransferCreditsLoading}
        isSuccess={isTransferCreditsSuccess}
      />
    </div>
  );
}
