import { ChangePasswordRequest, V1UsersDetailData } from '~/api/data-contracts';
import { api } from '~/utils/api';

const URL = {
  USER: (id: string) => `/users/${id}`,
  USER_AVATAR: `/users/current-user/avatar`,
  USER_PASSWORD: `/users/current-user/password`,
  USER_ACTIVATE: (keyId: string) => `/users/current-user/${keyId}/activate`,
};

export const usersModel = {
  user: (id: string) => api.get<V1UsersDetailData>(URL.USER(id)),
  userAvatar: (request: FormData) => api.put(URL.USER_AVATAR, request),
  userPassword: (request: ChangePasswordRequest) => api.put(URL.USER_PASSWORD, request),
  userActivate: (request: string) => api.patch(URL.USER_ACTIVATE(request)),
};
