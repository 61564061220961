import { FieldError, FieldErrorsImpl, Merge } from 'react-hook-form';

type FormFieldArrayErrorProps = {
  error:
    | Merge<
        FieldError,
        | (
            | Merge<
                FieldError,
                FieldErrorsImpl<{
                  sectionNo: number;
                  rate: number;
                }>
              >
            | undefined
          )[]
      >
    | undefined;
};

export default function FormFieldArrayError({ error }: FormFieldArrayErrorProps) {
  if (!error?.message) {
    return null;
  }

  return <p className="text-red-500 font-light my-1 text-sm">{error.message}</p>;
}
