import { useMutation } from '@tanstack/react-query';
import { toast } from 'material-react-toastify';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import FormError from '~/components/form/FormError';
import FormField from '~/components/form/FormField';
import { useAuth } from '~/context/AuthContext';
import { authModel } from '~/models/authModel/authModel';
import { processFormError } from '~/utils/error';
import { useTranslation } from 'react-i18next';

type ResetPasswordProps = { id: string };

type ResetFormValues = {
  newPassword: string;
  repeatedNewPassword: string;
};

const defaultValues: ResetFormValues = {
  newPassword: '',
  repeatedNewPassword: '',
};

export default function ResetPassword({ id }: ResetPasswordProps) {
  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<ResetFormValues>({
    defaultValues,
  });
  const { t } = useTranslation();
  const { getHomePath } = useAuth();
  const { mutate, isLoading } = useMutation(authModel.password);
  const navigate = useNavigate();

  const onSubmit = async ({ newPassword, repeatedNewPassword }: ResetFormValues) => {
    mutate(
      {
        newPassword,
        repeatedNewPassword,
        passwordResetRequestId: id,
      },
      {
        onError: processFormError(setError),
        onSuccess: async () => {
          toast.success(t('resetPassword.resetPasswordSuccess'));
          window.setTimeout(() => {
            navigate(getHomePath());
          }, 500);
        },
      },
    );
  };

  return (
    <div className="relative flex flex-1 flex-col gap-14 py-14 px-2.5 lg:px-14 xl:gap-16">
      <span className="text-lg font-medium text-white lg:text-2xl">
        {t('resetPassword.createNewPassword')}
      </span>
      <div className="flex flex-col justify-center gap-7 lg:flex-row">
        <div className="flex w-full flex-col gap-7 lg:w-6/12">
          <form
            className="flex w-full flex-col divide-y-[1px] divide-secondary overflow-hidden rounded-lg bg-primary"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="flex flex-col items-center gap-5 p-7 lg:p-14">
              <div className="w-full space-y-2">
                <FormField
                  control={control}
                  name="newPassword"
                  placeholder={t('resetPassword.password')}
                  type="password"
                  icon="icon-solid-closed"
                />

                <FormField
                  control={control}
                  name="repeatedNewPassword"
                  placeholder={t('resetPassword.repeatPassword')}
                  type="password"
                  icon="icon-solid-closed"
                />
              </div>
              <FormError error={errors.root} />
              <button
                type="submit"
                className="btn-rounded border-transparent bg-[#0E9B57] transition duration-150 ease-linear hover:bg-[#0C844A]"
                disabled={isLoading}
              >
                {t('resetPassword.resetPassword')}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
