import { useNavigate, useParams } from 'react-router-dom';
import EditReview from '~/features/reviews/components/EditReview/EditReview';
import { ROUTES } from '~/router/Routes';

export default function ReviewEditPage() {
  const { id } = useParams();
  const navigate = useNavigate();

  if (!id) {
    navigate(ROUTES.REVIEWS_LIST);
    return null;
  }

  return <EditReview id={id} />;
}
