import pickBy from 'lodash.pickby';
import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { V1ReviewsListParams } from '~/api/data-contracts';
import { syncParam } from '~/utils/syncParam';
import { ReviewListFilteringFormValues } from '../../components/ReviewList/ReviewListFilteringForm';

enum Param {
  sorting = 'f_sorting',
  followedByMe = 'f_followedByMe',
  likedByMe = 'f_likedByMe',
}

export const useReviewFilters = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const onSubmit = (value: ReviewListFilteringFormValues) => {
    setSearchParams((params) => {
      syncParam(params, Param.sorting, value.sorting);
      syncParam(params, Param.followedByMe, value.followedByMe);
      syncParam(params, Param.likedByMe, value.likedByMe);

      return params;
    });
  };

  const formValue = useMemo((): ReviewListFilteringFormValues => {
    return {
      sorting: searchParams.get(Param.sorting) || undefined,
      followedByMe: searchParams.get(Param.followedByMe) === 'true',
      likedByMe: searchParams.get(Param.likedByMe) === 'true',
    };
  }, [searchParams]);

  const apiParams = useMemo((): V1ReviewsListParams => {
    const result: V1ReviewsListParams = {
      SortProperty: searchParams.get(Param.sorting) || undefined,
      MyFollowed: searchParams.get(Param.followedByMe) === 'true',
      MyLiked: searchParams.get(Param.likedByMe) === 'true',
    };

    return pickBy(result);
  }, [searchParams]);

  const isAnyFilterSet = useMemo(
    () => Array.from(searchParams.keys()).some((k) => k.startsWith('f_')),
    [searchParams],
  );

  return {
    isAnyFilterSet,
    apiParams,
    formValue,
    onSubmit,
  };
};
