interface SvgIconProps {
  svgName: string;
  className?: string;
  title?: string;
}

export default function SvgIcon({ svgName, title, className = 'gray icons' }: SvgIconProps) {
  const svgPath = `/assets/icons/sprite.svg#${svgName}`;

  return (
    <svg className={className} aria-hidden="true">
      {title && <title>{title}</title>}
      <use href={svgPath}></use>
    </svg>
  );
}
