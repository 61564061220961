import { useMutation, useQuery } from '@tanstack/react-query';
import classNames from 'classnames';
import { toast } from 'material-react-toastify';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, generatePath, useNavigate } from 'react-router-dom';
import Lightbox from 'yet-another-react-lightbox';
import Fullscreen from 'yet-another-react-lightbox/plugins/fullscreen';
import Zoom from 'yet-another-react-lightbox/plugins/zoom';
import 'yet-another-react-lightbox/styles.css';
import { ReactComponent as Badge } from '~/assets/badges/badge (14).svg';
import Comments from '~/components/Comments/Comments';
import { Icon } from '~/components/Icon/Icon';
import { useAuth } from '~/context/AuthContext';
import { commentsModel } from '~/models/commentsModel/commentsModel';
import { followModel } from '~/models/followModel/followModel';
import { reviewModel } from '~/models/reviewModel/reviewModel';
import { ROUTES } from '~/router/Routes';
import { formatDateTime } from '~/utils/formatDate';
import { getReviewImageUrl } from '../../utils/images';

interface ReviewDetailsProps {
  id: string;
}

export default function ReviewDetails({ id }: ReviewDetailsProps) {
  const {
    data: review,
    error,
    refetch: refetchReview,
  } = useQuery(['review', id], () => reviewModel.reviewDetailed(id));
  const { data: comments, refetch: refetchComments } = useQuery(['comments', id], () =>
    commentsModel.reviewComments({ ReviewId: id }),
  );
  const { mutate: createComment } = useMutation(commentsModel.createReviewComment);
  const { mutate: followReview } = useMutation(followModel.followReview);
  const { mutate: unfollowReview } = useMutation(followModel.unfollowReview);
  const { mutate: likeReview } = useMutation(followModel.likeReview);
  const { mutate: dislikeReview } = useMutation(followModel.dislikeReview);
  const { mutate: unlikeReview } = useMutation(followModel.unlikeReview);

  const navigate = useNavigate();

  const { t } = useTranslation();
  const { user } = useAuth();

  const [isOpen, setIsOpen] = useState(false);

  if (error) {
    toast.error(t('general.error'));
    navigate(ROUTES.AD_LIST);
  }

  if (!review) {
    return null;
  }

  const imageUrlList = review.images.map((filename) => getReviewImageUrl(id, filename));

  const firstImage = imageUrlList[0];
  const thumbs = imageUrlList.filter((_, index) => index !== 0);

  const average = review.sections.reduce((a, b) => a + (b.rate || 0), 0) / review.sections.length;

  const handleCommentCreate = async (text: string, parentNo?: number) => {
    createComment(
      {
        reviewId: id,
        text,
        parentNo,
      },
      {
        onSuccess: () => refetchComments(),
        onError: () => toast.error(t('general.error')),
      },
    );
  };

  const handleFollowClick = async () => {
    if (review.followers.isCurrentUserIsFollwing) {
      unfollowReview(id, {
        onSuccess: () => refetchReview(),
        onError: () => toast.error(t('general.error')),
      });
    } else {
      followReview(id, {
        onSuccess: () => refetchReview(),
        onError: () => toast.error(t('general.error')),
      });
    }
  };

  const handleLikeClick = async () => {
    if (review.likesDislikes.isCurrentUserLiked === true) {
      unlikeReview(id, {
        onSuccess: () => refetchReview(),
        onError: () => toast.error(t('general.error')),
      });
    } else {
      likeReview(id, {
        onSuccess: () => refetchReview(),
        onError: () => toast.error(t('general.error')),
      });
    }
  };

  const handleDislikeClick = async () => {
    if (review.likesDislikes.isCurrentUserLiked === false) {
      unlikeReview(id, {
        onSuccess: () => refetchReview(),
        onError: () => toast.error(t('general.error')),
      });
    } else {
      dislikeReview(id, {
        onSuccess: () => refetchReview(),
        onError: () => toast.error(t('general.error')),
      });
    }
  };

  const isDisabled = review.createdData.creatorId === user?.id;

  return (
    <div className="relative flex flex-1 flex-col gap-14 py-14 px-2.5 lg:px-14 xl:gap-16">
      <Lightbox
        open={isOpen}
        close={() => setIsOpen(false)}
        slides={imageUrlList.map((src) => ({ src }))}
        plugins={[Fullscreen, Zoom]}
      />

      <div className="flex flex-1 flex-col gap-7 lg:flex-row">
        <div className="flex flex-1 flex-col gap-2.5 lg:w-5/12 lg:flex-initial 2xl:w-4/12">
          <div className="relative flex flex-1 flex-col gap-2 rounded-lg bg-primary py-5 px-7 lg:hidden">
            <span className="text-lg font-semibold text-pink">{review.name}</span>
            <span className="inline-flex items-center gap-1 text-xs">
              <Icon alt="Location" icon="icon-location" className="h-3.5 w-3.5 text-[#999999]" />
              <span className="font-medium text-white">
                {review.location.town}, {review.location.country}
              </span>
            </span>
            <button
              type="button"
              className="absolute top-2.5 right-2.5 inline-flex gap-2 rounded-full bg-secondary py-1.5 px-3 text-white"
              disabled={isDisabled}
              onClick={handleFollowClick}
            >
              <span className="font-medium">{review.followers.followers}</span>
              <Icon
                alt="Heart"
                icon="icon-heart"
                className={classNames(
                  'h-5 w-5',
                  review.followers.isCurrentUserIsFollwing ? 'pink-color' : 'text-white',
                )}
              />
            </button>
          </div>
          <div className="flex flex-1 flex-col gap-2.5">
            {firstImage && (
              <button
                onClick={() => setIsOpen(true)}
                className="group aspect-w-5 aspect-h-6 relative overflow-hidden md:aspect-h-7 2xl:aspect-h-6"
              >
                <img
                  alt="Profile Picture"
                  src={firstImage}
                  className="object-cover h-full w-full rounded-lg"
                />
                <div className="absolute z-10 hidden h-full w-full items-center justify-center bg-black bg-opacity-20 transition duration-150 ease-linear group-hover:inline-flex">
                  <Icon alt="Zoom-in" icon="icon-zoom-in" className="h-8 w-8 text-white" />
                </div>
              </button>
            )}

            {thumbs.length > 0 && (
              <div className="grid grid-cols-3 gap-2.5 md:grid-cols-3 xl:grid-cols-4">
                {thumbs.map((thumb) => (
                  <button
                    key={thumb}
                    onClick={() => setIsOpen(true)}
                    className="group aspect-w-5 aspect-h-4"
                  >
                    <img
                      alt="Profile Picture"
                      src={thumb}
                      className="object-cover	h-full w-full rounded-lg"
                    />
                    <div className="absolute z-10 hidden h-full w-full items-center justify-center bg-black bg-opacity-20 transition duration-150 ease-linear group-hover:inline-flex">
                      <Icon alt="Zoom-in" icon="icon-zoom-in" className="h-8 w-8 text-white" />
                    </div>
                  </button>
                ))}
              </div>
            )}
          </div>
        </div>
        <div className="flex flex-1 flex-col gap-2.5 lg:w-7/12 lg:flex-initial 2xl:w-8/12 select-text">
          <div className="flex flex-1 flex-shrink-0 flex-col rounded-lg bg-primary lg:flex-initial">
            <div className="relative hidden flex-1 flex-col gap-2 rounded-lg bg-primary py-5 px-7 lg:flex">
              <span className="text-lg font-semibold text-pink">{review.name}</span>
              <span className="inline-flex items-center gap-1 text-xs">
                <Icon alt="Location" icon="icon-location" className="h-3.5 w-3.5 text-[#999999]" />
                <span className="font-medium text-white">
                  {review.location.town}, {review.location.country}
                </span>
              </span>
              <button
                className="group absolute top-2.5 right-2.5 inline-flex gap-2 rounded-full bg-secondary py-1.5 px-3 text-white"
                disabled={isDisabled}
                onClick={handleFollowClick}
              >
                <span className="text-sm font-medium">{review.followers.followers}</span>
                <Icon
                  alt="Heart"
                  icon="icon-heart"
                  className={classNames(
                    'h-4 w-4  group-hover:text-pink transition duration-150 ease-linear',
                    review.followers.isCurrentUserIsFollwing ? 'pink-color' : 'text-white',
                  )}
                />
              </button>
            </div>
            <div className="p-7 text-sm">
              <p className="text-white">{review.description}</p>
            </div>
            <div className="lg:iems-center flex flex-1 flex-col lg:flex-row lg:justify-between lg:px-7">
              <div className="inline-flex items-center gap-5 px-7 pt-5 pb-5 lg:px-0 lg:pb-0 lg:pt-0">
                <span className="inline-flex items-center gap-1 text-xs text-white">
                  <Icon alt="Time" icon="icon-time" className="h-4 w-4 text-[#999999]" />
                  {review.createdData?.createdAt && (
                    <span>{formatDateTime(new Date(review.createdData.createdAt))}</span>
                  )}
                </span>
                <Link
                  className="inline-flex items-center gap-1 text-xs text-white cursor-pointer hover:text-pink"
                  to={generatePath(ROUTES.USER, { id: review.createdData.creatorId })}
                >
                  <Icon alt="User" icon="icon-user" className="h-4 w-4 text-[#999999]" />
                  <span>{review.createdData.nickName}</span>
                  {review.createdData.isVeteran && (
                    <Badge className="text-pink h-6 w-6" title={t('honor.veteran')} />
                  )}
                </Link>
              </div>
              <div className="inline-flex h-[70px] items-center justify-center gap-3 border-t-[1px] border-secondary text-white lg:border-none">
                <button
                  className="group inline-flex items-center gap-1"
                  disabled={isDisabled}
                  onClick={handleLikeClick}
                >
                  <Icon
                    alt="Like"
                    icon="icon-like"
                    className={classNames(
                      'w-4 h-4 transform transition duration-150 ease-linear group-hover:text-[#118DF0] group-hover:-translate-y-0.5',
                      review.likesDislikes.isCurrentUserLiked === true
                        ? 'text-[#118DF0]'
                        : 'text-white',
                    )}
                  />
                  <span className="lg:text-sm">{review.likesDislikes.likes}</span>
                </button>

                <span className="h-3.5 w-[1px] bg-[#272727]"></span>

                <button
                  className="group inline-flex items-center gap-1"
                  disabled={isDisabled}
                  onClick={handleDislikeClick}
                >
                  <Icon
                    alt="Dislike"
                    icon="icon-dislike"
                    className={classNames(
                      'w-4 h-4 transform transition duration-150 ease-linear group-hover:text-[#FF304F] group-hover:translate-y-0.5',
                      review.likesDislikes.isCurrentUserLiked === false
                        ? 'text-[#FF304F]'
                        : 'text-white',
                    )}
                  />
                  <span className="lg:text-sm">{review.likesDislikes.dislikes}</span>
                </button>
              </div>
            </div>
          </div>
          <div className="flex flex-1 flex-shrink-0 flex-col rounded-lg bg-primary lg:flex-initial">
            {review.ad.externalAdUrl && (
              <p className="inline-flex items-center gap-2 px-7 py-5 text-white">
                <Icon alt="Link" icon="icon-link" className="w-5 h-5 text-[#999999]" />
                <a
                  href={review.ad.externalAdUrl}
                  className="link text-xs"
                  target="_blank"
                  rel="noreferrer"
                >
                  {review.ad.externalAdUrl}
                </a>
              </p>
            )}
            {review.ad.internalAdId && (
              <p className="inline-flex items-center gap-2 px-7 py-5 text-white">
                <Icon alt="Link" icon="icon-link" className="w-5 h-5 text-[#999999]" />
                <Link
                  to={generatePath(ROUTES.AD_DETAILED, {
                    id: review.ad.internalAdId,
                  })}
                  className="link text-xs"
                >
                  {generatePath(ROUTES.AD_DETAILED, {
                    id: review.ad.internalAdId,
                  })}
                </Link>
              </p>
            )}
          </div>

          {review.sections.length && (
            <div className="flex flex-1 flex-shrink-0 flex-col overflow-hidden rounded-lg bg-primary lg:flex-initial">
              <table className="w-full divide-y divide-secondary">
                <tbody className="divide-y divide-secondary">
                  <tr className="w-full divide-x divide-secondary">
                    <th
                      colSpan={2}
                      scope="colgroup"
                      className="whitespace-nowrap py-3.5 text-center text-xs font-medium text-[#999999]"
                    >
                      {t('reviews.serviceRatings')}
                    </th>
                  </tr>

                  {review.sections.map((sections, index) => (
                    <tr
                      key={index}
                      className="divide-x divide-secondary hover:bg-secondary text-sm"
                    >
                      <td className="w-8/12 px-7 py-3.5 text-white">
                        {t(`sections.${sections.name}`)}
                      </td>
                      <td className="inline-flex items-baseline gap-1 px-7 py-3.5 font-bold text-white">
                        <span>{sections.rate}</span>
                        <span className="font-regular text-xs text-[#999999]"> /10</span>
                      </td>
                    </tr>
                  ))}

                  <tr className="w-full divide-x divide-secondary">
                    <th
                      colSpan={2}
                      scope="colgroup"
                      className="whitespace-nowrap py-3.5 text-center text-sm font-medium text-[#999999]"
                    >
                      <div className="inline-flex items-center gap-2.5 text-xs">
                        <span>Total</span>
                        <span className="inline-flex items-center gap-1">
                          <Icon
                            alt="Star"
                            icon="icon-star-alt"
                            className="w-3.5 h-3.5 text-[#999999]"
                          />
                          <span className="text-sm font-bold text-white">
                            {Math.round(average * 10) / 10}
                          </span>
                        </span>
                      </div>
                    </th>
                  </tr>
                </tbody>
              </table>
            </div>
          )}

          {review.rates.length && (
            <div className="flex flex-1 flex-shrink-0 flex-col overflow-hidden rounded-lg bg-primary lg:flex-initial">
              <table className="w-full divide-y divide-secondary">
                <tbody className="divide-y divide-secondary">
                  <tr className="w-full divide-x divide-secondary">
                    <th
                      colSpan={2}
                      scope="colgroup"
                      className="whitespace-nowrap py-3.5 text-center text-xs font-medium text-[#999999]"
                    >
                      {t('reviews.serviceRates')}
                    </th>
                  </tr>
                  {review.rates
                    .filter((rate) => rate.incall)
                    .map((rate, index) => (
                      <tr
                        key={index}
                        className="divide-x divide-secondary hover:bg-secondary text-sm"
                      >
                        <td className="w-8/12 px-7 py-3.5 text-white">
                          {t(`rateTime.${rate.rateTime}`)}
                        </td>
                        <td className="px-7 py-3.5 font-bold text-white">{`${rate.incall} ${review.currency.currencyCode}`}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          )}

          {comments && (
            <Comments data={comments} onCreate={handleCommentCreate} onLike={refetchComments} />
          )}
        </div>
      </div>
    </div>
  );
}
