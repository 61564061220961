import { useQuery } from '@tanstack/react-query';
import classNames from 'classnames';
import { useEffect, useMemo, useRef } from 'react';
import { Link, createSearchParams, useSearchParams } from 'react-router-dom';
import { V1LocationsCountsAlphaListData } from '~/api/data-contracts';

declare class Accordion {
  constructor(init: any, config: any);

  update(): void;
}

type CountsProps = {
  cacheKey?: string;
  fetchFn: () => Promise<V1LocationsCountsAlphaListData>;
  path: string;
  searchTerm?: string;
  title?: string;
};

export default function Counts({ cacheKey, fetchFn, path, searchTerm, title }: CountsProps) {
  const { data } = useQuery([cacheKey], fetchFn);

  const accordionElementRef = useRef<HTMLDivElement>(null);
  const accordionRef = useRef<Accordion | null>(null);

  const countries =
    data?.items
      ?.map((country) => ({
        title: country.name || '',
        totalCount: country.count || 0,
        no: country.no || 0,
        iso2: country.iso2,
        towns:
          country.towns
            ?.map((town) => ({
              title: town.name || '',
              totalCount: town.count || 0,
              no: town.no || 0,
            }))
            .sort((a, b) => b.totalCount - a.totalCount) || [],
      }))
      .sort((a, b) => b.totalCount - a.totalCount) || [];

  const filteredCountries = useMemo(() => {
    if (!searchTerm) {
      return countries;
    }

    const searchTermLowerCase = searchTerm.toLowerCase();

    return countries.flatMap((country) => {
      const filteredTowns = country.towns.filter((town) =>
        town.title.toLowerCase().includes(searchTermLowerCase),
      );
      if (
        filteredTowns.length === 0 &&
        !country.title.toLowerCase().includes(searchTermLowerCase)
      ) {
        return [];
      }
      return {
        ...country,
        towns: country.title.toLowerCase().includes(searchTermLowerCase)
          ? country.towns
          : filteredTowns,
      };
    });
  }, [countries, searchTerm]);

  const [searchParams] = useSearchParams();

  const townNo = searchParams.get('town');

  useEffect(() => {
    if (accordionElementRef.current && !accordionRef.current) {
      accordionRef.current = new Accordion(accordionElementRef.current, {
        showMultiple: false,
      });
    }
  }, [accordionElementRef.current]);

  useEffect(() => {
    if (accordionRef.current) {
      accordionRef.current.update();
    }
  }, [filteredCountries]);

  return (
    <div className="accordion-container" ref={accordionElementRef}>
      {title && <span className="text-white pl-5">{title}</span>}

      {filteredCountries.map((country) => (
        <div className="ac border-b-[1px] border-secondary" key={country.no}>
          <div className="ac-header-sidebar ac-trigger">
            <div className="inline-flex justify-center items-center gap-2">
              {country.iso2 && (
                <span className={`fi fi-${country.iso2.toLowerCase()} h-3.5 w-3.5`} />
              )}
              <span>{country.title}</span>
            </div>
            <span className="pr-7">{country.totalCount}</span>
          </div>
          <div className="ac-panel">
            <div className="py-2.5 bg-secondary">
              {country.towns.map((town) => (
                <Link
                  to={`${path}?${createSearchParams({
                    country: country.no.toString(),
                    town: town.no.toString(),
                  })}`}
                  className="radio-container radio-container-pad"
                  key={town.no}
                >
                  <span>{town.title}</span>
                  <span className="total">{town.totalCount}</span>
                  <div className="absolute top-0 left-0 bg-third w-full h-full -z-10"></div>
                  <div
                    className={classNames(
                      'search-gradient absolute bottom-0 left-0 h-[4px] w-full',
                      {
                        '!hidden': town.no.toString() !== townNo,
                      },
                    )}
                  ></div>
                </Link>
              ))}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
