import { useMutation } from '@tanstack/react-query';
import { toast } from 'material-react-toastify';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useModals } from '~/context/ModalContext';
import { authModel } from '~/models/authModel/authModel';
import { processFormError } from '~/utils/error';
import FormError from '../form/FormError';
import FormField from '../form/FormField';

type ResetFormValues = {
  email: string;
};

const defaultValues = {
  email: '',
};

export default function PasswordResetModal() {
  const { t } = useTranslation();
  const { hideModal } = useModals();
  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<ResetFormValues>({
    defaultValues,
  });

  const { mutate, isLoading } = useMutation(authModel.reset);

  const submit = async (values: ResetFormValues) => {
    mutate(values, {
      onError: processFormError(setError),
      onSuccess: () => {
        toast.success(t('passwordResetModal.resetLinkSent'));
        hideModal();
      },
    });
  };

  return (
    <div
      className="relative max-h-[90vh] w-full overflow-auto rounded-lg bg-primary text-center"
      role="dialog"
      aria-modal="true"
    >
      <header className="flex w-full flex-1 items-center justify-center py-7">
        <span className="text-xl font-semibold text-white">{t('passwordResetModal.title')}</span>
      </header>

      <form className="flex flex-col gap-[10px] p-7" onSubmit={handleSubmit(submit)}>
        <FormField
          control={control}
          name="email"
          placeholder={t('passwordResetModal.email')}
          type="text"
          icon="icon-email"
        />
        <FormError error={errors.root} />
        <div>
          <button
            className="btn-rounded mt-[6px] border-0 bg-pink font-semibold transition duration-150 ease-linear hover:bg-[#C60D4F]"
            type="submit"
            disabled={isLoading}
          >
            {t('passwordResetModal.actionReset')}
          </button>
        </div>
      </form>
    </div>
  );
}
