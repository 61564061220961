import { GetCurrenciesResponse } from '~/types/currency';
import { api } from '~/utils/api';

const URL = {
  CURRENCIES: '/currencies',
};

export const currencyModel = {
  currencies: () => api.get<GetCurrenciesResponse>(URL.CURRENCIES),
};
