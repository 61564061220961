export class DropdownConfig {
  private isOpen: boolean;

  constructor(private elem: HTMLElement) {
    if (!(elem instanceof HTMLElement)) {
      throw new TypeError('The element must be an HTML element.');
    }

    this.isOpen = false;
    this.elem = elem;

    this.elem.querySelector('.dropdown-toggle')?.addEventListener('click', () => {
      this.open();
    });

    window.addEventListener('click', (e) => {
      this.close(e);
    });
  }

  open() {
    if (!this.isOpen) {
      this.elem.querySelector('.dropdown-content')?.classList.remove('hidden');

      this.elem
        .querySelector('.dropdown-toggle')
        ?.querySelector('#caret')
        ?.classList.add('rotate-180');

      if (!this.elem.dataset.profile) {
        this.elem.querySelector('.dropdown-toggle')?.classList.remove('text-[#999999]');
        this.elem
          .querySelector('.dropdown-toggle')
          ?.classList.add('rounded-b-none', 'rounded-bl-none', 'text-white');
        this.elem.querySelector('.dropdown-toggle')?.classList.add('bg-secondary');
      }

      if (this.elem.dataset.profile) {
        this.elem
          .querySelector('.dropdown-toggle')
          ?.classList.add('shadow-[0px_0px_3px_2px_#E90F5D]');
      }

      if (this.elem.dataset.comment) {
        this.elem.querySelector('.dropdown-toggle')?.classList.add('rounded-t-lg');
      }

      if (this.elem.dataset.leftfilter) {
        this.elem.querySelector('.dropdown-toggle')?.classList.remove('rounded-bl-lg');
      }

      if (this.elem.dataset.rightfilter) {
        this.elem.querySelector('.dropdown-toggle')?.classList.remove('rounded-br-lg');
      }
    } else {
      this.elem.querySelector('.dropdown-content')?.classList.add('hidden');

      this.elem
        .querySelector('.dropdown-toggle')
        ?.querySelector('#caret')
        ?.classList.remove('rotate-180');

      if (!this.elem.dataset.profile) {
        this.elem.querySelector('.dropdown-toggle')?.classList.add('text-[#999999]');
        this.elem
          .querySelector('.dropdown-toggle')
          ?.classList.remove('rounded-b-none', 'rounded-bl-none', 'text-white');
        this.elem.querySelector('.dropdown-toggle')?.classList.remove('bg-secondary');
      }

      if (this.elem.dataset.profile) {
        this.elem
          .querySelector('.dropdown-toggle')
          ?.classList.remove('shadow-[0px_0px_3px_2px_#E90F5D]');
      }

      if (this.elem.dataset.leftfilter) {
        this.elem.querySelector('.dropdown-toggle')?.classList.add('rounded-bl-lg');
      }

      if (this.elem.dataset.rightfilter) {
        this.elem.querySelector('.dropdown-toggle')?.classList.add('rounded-br-lg');
      }

      if (this.elem.dataset.comment) {
        this.elem.querySelector('.dropdown-toggle')?.classList.remove('rounded-t-lg');
      }
    }

    this.isOpen = !this.isOpen;
  }

  onlyClose() {
    // console.log(this.elem, this.elem.querySelector('.dropdown-content'));
    this.elem.querySelector('.dropdown-content')?.classList.add('hidden');

    this.elem
      .querySelector('.dropdown-toggle')
      ?.querySelector('#caret')
      ?.classList.remove('rotate-180');

    if (!this.elem.dataset.profile) {
      this.elem.querySelector('.dropdown-toggle')?.classList.add('text-[#999999]');
      this.elem
        .querySelector('.dropdown-toggle')
        ?.classList.remove('rounded-b-none', 'rounded-bl-none', 'text-white');
      this.elem.querySelector('.dropdown-toggle')?.classList.remove('bg-secondary');
    }

    if (this.elem.dataset.profile) {
      this.elem
        .querySelector('.dropdown-toggle')
        ?.classList.remove('shadow-[0px_0px_3px_2px_#E90F5D]');
    }

    if (this.elem.dataset.leftfilter) {
      this.elem.querySelector('.dropdown-toggle')?.classList.add('rounded-bl-lg');
    }

    if (this.elem.dataset.rightfilter) {
      this.elem.querySelector('.dropdown-toggle')?.classList.add('rounded-br-lg');
    }

    if (this.elem.dataset.comment) {
      this.elem.querySelector('.dropdown-toggle')?.classList.remove('rounded-t-lg');
    }

    this.isOpen = false;
  }

  close(e: Event) {
    if (!this.elem.contains(e.target as any)) {
      this.onlyClose();
    }
  }
}
