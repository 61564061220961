import { ReactElement } from 'react';
import { useAuth } from '~/context/AuthContext';
import { Inactive } from '~/pages/Inactive/Inactive';
import { NotFound } from '~/pages/NotFound/NotFound';

type AuthorizedProps = {
  roles?: string[];
  children: ReactElement;
  allowInactive?: boolean;
};

export default function Authorized({ allowInactive, children, roles }: AuthorizedProps) {
  const { hasOneOfRoles, user } = useAuth();

  if (!user) {
    return <NotFound />;
  }
  const isActive = allowInactive || (user?.expireAt && new Date(user.expireAt) > new Date());

  if (!isActive) {
    return <Inactive />;
  }

  const isAuthorized = !roles || hasOneOfRoles(roles);

  if (!isAuthorized) {
    return <NotFound />;
  }

  return children;
}
