import { useEffect, useRef } from 'react';

declare class Accordion {
  constructor(init: any, config: any);

  update(): void;
}

type Item = { value: string; label: string };

export type AccordionProps = {
  items: Item[];
  title?: string;
  value: string | null;
  onChange?: (value: string | null) => void;
};

export default function AccordionComponent({ items, title, onChange, value }: AccordionProps) {
  const accordionElementRef = useRef<HTMLDivElement>(null);
  const accordionRef = useRef<Accordion | null>(null);

  useEffect(() => {
    if (accordionElementRef.current && !accordionRef.current) {
      accordionRef.current = new Accordion(accordionElementRef.current, {
        showMultiple: false,
      });
    }
  }, [accordionElementRef.current]);

  useEffect(() => {
    if (accordionRef.current) {
      accordionRef.current.update();
    }
  }, [items]);

  const handleChange = (item: string) => {
    if (!onChange) {
      return;
    }

    const nextValue = item === value ? null : item;

    onChange(nextValue);
  };

  const currentValue = items.find((item) => item.value === value);

  return (
    <div className="accordion-container" ref={accordionElementRef}>
      <div className="ac border-b-[1px] border-[#141414]">
        <div className="ac-header ac-trigger">
          <div className="ac-header-text flex items-center justify-between gap-2">
            <span>{title}</span>
            {currentValue && (
              <span className="text-pink justify-self-end">{currentValue.label}</span>
            )}
          </div>
        </div>
        <div className="ac-panel bg-primary">
          <div className="py-3">
            {items.map((item) => (
              <label className="checkbox-container block" key={item.value}>
                <input
                  checked={value === item.value}
                  type="checkbox"
                  onChange={() => handleChange(item.value)}
                />
                <span className="checkmark"></span>
                <span>{item.label}</span>
              </label>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
