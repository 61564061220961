import { createContext, useContext, useEffect, useReducer } from 'react';
import { useNavigate } from 'react-router-dom';
import { RefreshAccessTokenRequest, UserType } from '~/api/data-contracts';
import { Loader } from '~/components/Loader/Loader';
import { localStorageKeys } from '~/config/consts';
import { authModel } from '~/models/authModel/authModel';
import { ROUTES } from '~/router/Routes';
import { AuthContextType, AuthState } from '~/types/auth';
import { AuthActions, AuthTypes } from '~/types/context';

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: undefined,
};

const AuthReducer = (state: AuthState, action: AuthActions) => {
  switch (action.type) {
    case AuthTypes.Initial:
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        user: action.payload.user,
      };
    case AuthTypes.Login:
      return {
        ...state,
        isAuthenticated: true,
        isInitialized: true,
        user: action.payload.user,
      };
    case AuthTypes.Logout:
      return {
        ...state,
        isAuthenticated: false,
        isInitialized: true,
        user: undefined,
      };

    default:
      return state;
  }
};

export const AuthContext = createContext<AuthContextType>(undefined!);

export const AuthProvider = ({ children }: React.PropsWithChildren) => {
  const navigate = useNavigate();
  const [state, dispatch] = useReducer(AuthReducer, initialState);

  useEffect(() => {
    fetchUser();
  }, []);

  const fetchUser = async () => {
    try {
      const token = window.localStorage.getItem(localStorageKeys.auth);

      if (token) {
        const user = await authModel.currentUser();

        dispatch({
          type: AuthTypes.Initial,
          payload: {
            isAuthenticated: true,
            user,
          },
        });
      } else {
        dispatch({
          type: AuthTypes.Initial,
          payload: {
            isAuthenticated: false,
            user: undefined!,
          },
        });
      }
    } catch (err) {
      // console.error(err);
      dispatch({
        type: AuthTypes.Initial,
        payload: {
          isAuthenticated: false,
          user: undefined!,
        },
      });
    }
  };

  const login = async (values: { emailOrUserName: string; password: string }) => {
    const response = await authModel.login(values);

    if (response.accessToken) {
      localStorage.setItem(localStorageKeys.auth, JSON.stringify(response));
      const user = await authModel.currentUser();

      dispatch({
        type: AuthTypes.Login,
        payload: {
          user,
        },
      });

      return user;
    }
  };

  const refresh = async (values: RefreshAccessTokenRequest) => {
    const response = await authModel.refresh(values);

    if (response.accessToken) {
      localStorage.setItem(localStorageKeys.auth, JSON.stringify(response));
    }
  };

  const logout = async () => {
    localStorage.removeItem(localStorageKeys.auth);
    authModel.signOut();

    dispatch({ type: AuthTypes.Logout });
    navigate(ROUTES.DEFAULT);
  };

  const hasOneOfRoles = (roles: string[]) =>
    state.isAuthenticated && roles.includes(state.user?.userType || '');

  const getHomePath = () => {
    if (hasOneOfRoles([UserType.Reviewer])) {
      return ROUTES.REVIEWS_LIST;
    }

    if (hasOneOfRoles([UserType.Agency, UserType.Independent])) {
      return ROUTES.AD_LIST;
    }

    return ROUTES.DEFAULT;
  };

  if (!state.isInitialized) {
    return <Loader className="text-black" />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        fetchUser,
        hasOneOfRoles,
        login,
        logout,
        getHomePath,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
