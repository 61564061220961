import { useTranslation } from 'react-i18next';

interface AdParamsProps {
  type: string;
  value: number | string | boolean;
}

export default function AdParams({ type, value }: AdParamsProps) {
  const { t } = useTranslation();

  const displayValue = () => {
    return (
      <span className="fw-bold text-white col-8 text-break text-end md:text-start max-w-[50%]">
        {typeof value === 'boolean' ? (value ? t('general.yes') : t('general.no')) : value}
      </span>
    );
  };

  return (
    <div className="col-md-6 col-lg-4 col-12">
      <div className="flex gap-2 justify-between md:justify-start">
        <span className="text-pink col-4 text-break max-w-[50%]">{type}</span>
        {displayValue()}
      </div>
    </div>
  );
}
