import { useTranslation } from 'react-i18next';
import { MODAL_IDS } from '~/config/consts';
import { Icon } from '../Icon/Icon';
import { useModals } from '~/context/ModalContext';

export default function WarningModal() {
  const { t } = useTranslation();
  const { showModal } = useModals();

  const handleLogInClick = () => {
    showModal(MODAL_IDS.loginModal);
  };

  const handleRegisterClick = () => {
    showModal(MODAL_IDS.registerModal);
  };

  return (
    <div
      className="relative max-h-[90vh] w-full overflow-auto rounded-lg bg-primary"
      role="dialog"
      aria-modal="true"
    >
      <header className="flex w-full flex-1 items-center justify-center pt-7">
        <Icon alt="Logo" icon="logo" className="w-24 h-9 xl:w-36 xl:h-10" />
      </header>

      <div className="p-7">
        <div className="grid grid-cols-1 gap-7 rounded-lg bg-primary">
          <div className="flex flex-col gap-7">
            <div className="flex flex-col gap-2.5">
              <span className="inline-flex items-center gap-2.5">
                <Icon alt="Menu" icon="icon-question" className="h-8 w-8 text-[#999999]" />
                <span className="font-semibold text-white">{t('homePage.whatWeProvide')}</span>
              </span>
              <p className="text-[#999999] font-bold">{t('homePage.provide1')}</p>
              <p className="text-sm text-[#999999]">{t('homePage.provide2')}</p>
            </div>
          </div>
          <div className="flex flex-col gap-7">
            <div className="flex flex-col gap-2.5">
              <span className="inline-flex items-center gap-2.5">
                <Icon alt="Menu" icon="icon-question" className="h-8 w-8 text-[#999999]" />
                <span className="font-semibold text-white">{t('homePage.whyChooseUs')}</span>
              </span>
              <p className="text-[#999999] font-bold">{t('homePage.choose1')}</p>
              <p className="text-sm text-[#999999]">{t('homePage.choose2')}</p>
            </div>
          </div>
        </div>
      </div>

      <section className="p-7">
        <p className="text-center text-sm font-medium text-white backface-hidden">
          {t('warningModal.beforeViewing')}{' '}
          <button type="button" onClick={handleLogInClick} className="link">
            {t('warningModal.logIn')}
          </button>{' '}
        </p>
        <p className="text-center text-sm font-medium text-white backface-hidden pt-7 ">
          {t('warningModal.notMember')}{' '}
          <button type="button" onClick={handleRegisterClick} className="link">
            {t('warningModal.register')}
          </button>
        </p>
      </section>
    </div>
  );
}
