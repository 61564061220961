import { V1LocationsCountsAlphaListData } from '~/api/data-contracts';
import { api } from '~/utils/api';

const URL = {
  AD_COUNTS: '/locations-counts/alpha',
  REVIEW_COUNTS: '/locations-counts/reviews',
};

export const locationCounterModel = {
  adCounts: () => api.get<V1LocationsCountsAlphaListData>(URL.AD_COUNTS),
  reviewCounts: () => api.get<V1LocationsCountsAlphaListData>(URL.REVIEW_COUNTS),
};
