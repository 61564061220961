/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface AdPhonesRequest {
  /** @format int32 */
  phoneNo?: number | null;
  /** @format int32 */
  countryNo: number;
  isTelegram?: boolean | null;
  isWhatsApp?: boolean | null;
  isWeChat?: boolean | null;
  isViber?: boolean | null;
  isLine?: boolean | null;
  isSignal?: boolean | null;
  number: string;
}

export interface AdRatesRequest {
  time: RateTime;
  /** @format int32 */
  incall?: number | null;
  /** @format int32 */
  outcall?: number | null;
}

export interface AdServicesRequest {
  /** @format int32 */
  serviceNo: number;
  /** @format double */
  price?: number | null;
}

export enum AdStatus {
  Active = 'Active',
  Disabled = 'Disabled',
}

export enum AdType {
  Male = 'Male',
  Female = 'Female',
  Trans = 'Trans',
}

export enum AvailableFor {
  Incall = 'Incall',
  Outcall = 'Outcall',
  Both = 'Both',
}

export interface BravoProblemDetails {
  type?: string | null;
  title?: string | null;
  /** @format int32 */
  status?: number | null;
  detail?: string | null;
  instance?: string | null;
  errors: Error[];
  [key: string]: any;
}

export enum BreastSize {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E',
  F = 'F',
  G = 'G',
  H = 'H',
}

export enum BreastType {
  Natural = 'Natural',
  Silicone = 'Silicone',
}

export interface BuyPremiumFeatureRequest {
  /** @format int32 */
  premiumFeatureNo: number;
}

export interface ChangePasswordRequest {
  currentPassword: string;
  newPassword: string;
  repeatNewPassword: string;
}

export interface CreateAdBaseInfoRequest {
  name: string;
  description: string;
  adType: AdType;
  availableFor: AvailableFor;
  breastSize?: BreastSize;
  breastType?: BreastType;
  eyeColor: EyeColor;
  ethnicity: Ethnicity;
  hairColor: HairColor;
  hairLenght: HairLenght;
  meetingWith: MeetingWith;
  orientation: Orientation;
  piercing: Piercing;
  pubicHair: PubicHair;
  smoker: Smoker;
  travel: Travel;
  /** @format int32 */
  age: number;
  /** @format int32 */
  height: number;
  /** @format int32 */
  weight: number;
  /** @format int32 */
  dickSize?: number | null;
  isPornStar?: boolean | null;
  tattoo?: boolean | null;
  /** @format int32 */
  nationalityNo: number;
  /** @format int32 */
  locationNo: number;
  /** @format int32 */
  currencNo: number;
  languages: number[];
  services: AdServicesRequest[];
  rates: AdRatesRequest[];
  phones: AdPhonesRequest[];
}

export interface CreateAdBaseInformationCommandResponse {
  /** @format uuid */
  id: string;
}

export interface CreateExternalReviewCommandResponse {
  /** @format uuid */
  id: string;
}

export interface CreateExternalReviewRequest {
  externalUrl: string;
  name: string;
  description: string;
  /** @format int32 */
  locationNo: number;
  /** @format int32 */
  currencyNo: number;
  sections?: ReviewSectionRequest[] | null;
  rates?: ReviewRatesRequest[] | null;
}

export interface CreateInternalReviewCommandResponse {
  /** @format uuid */
  id: string;
}

export interface CreateInternalReviewRequest {
  /** @format uuid */
  adId: string;
  name: string;
  description: string;
  /** @format int32 */
  locationNo: number;
  sections: ReviewSectionRequest[];
}

export interface CreatePublicReviewRequest {
  review: string;
}

export interface CreateReviewCommentRequest {
  /** @format uuid */
  reviewId: string;
  /** @format int32 */
  parentNo?: number | null;
  text: string;
}

export interface EmailResetPasswordRequest {
  email: string;
}

export interface Error {
  /** @format int32 */
  errorCode: number;
  message: string;
  parameter: string;
  /** @format int32 */
  id?: number | null;
}

export interface ErrorCodeModel {
  /** @format int32 */
  errorCode: number;
  /** @format int32 */
  httpStatusCode: number;
  message: string;
  name: string;
}

export interface ErrorCodesResponse {
  errors: ErrorCodeModel[];
  errorsDictionary: Record<string, string>;
}

export enum Ethnicity {
  Arabian = 'Arabian',
  Asian = 'Asian',
  Ebony = 'Ebony',
  Caucasian = 'Caucasian',
  Hispanic = 'Hispanic',
  Indian = 'Indian',
  Latin = 'Latin',
  Mongolia = 'Mongolia',
  Mixed = 'Mixed',
}

export enum EyeColor {
  Black = 'Black',
  Blue = 'Blue',
  BlueGreen = 'BlueGreen',
  Brown = 'Brown',
  Green = 'Green',
  Grey = 'Grey',
  Hazel = 'Hazel',
}

export interface GetAdQueryResult {
  name: string;
  description: string;
  /** @format date-time */
  created: string;
  type: AdType;
  availableFor: AvailableFor;
  breastSize?: BreastSize;
  breastType?: BreastType;
  eyeColor: EyeColor;
  ethnicity: Ethnicity;
  hairColor: HairColor;
  hairLenght: HairLenght;
  meetingWith: MeetingWith;
  orientation: Orientation;
  piercing: Piercing;
  pubicHair: PubicHair;
  smoker: Smoker;
  travel: Travel;
  /** @format int32 */
  age: number;
  /** @format int32 */
  height: number;
  /** @format int32 */
  weight: number;
  /** @format int32 */
  dickSize?: number | null;
  isPornStar: boolean;
  tattoo: boolean;
  images: string[];
  rates: GetAdQueryResultRate[];
  phones: GetAdQueryResultPhone[];
  services: GetAdQueryResultService[];
  languages: GetAdQueryResultLanguageResult[];
  nationality: string;
  location: GetAdQueryResultLocationResult;
  currency: GetAdQueryResultCurrencyResult;
  followers: GetAdQueryResultFollowingResult;
  creator: GetAdQueryResultCreatorResult;
}

export interface GetAdQueryResultCreatorResult {
  nickName?: string | null;
  /** @format uuid */
  id: string;
  isVeteran: boolean;
}

export interface GetAdQueryResultCurrencyResult {
  name?: string | null;
}

export interface GetAdQueryResultFollowingResult {
  isCurrentUserIsFollwing: boolean;
  /** @format int32 */
  followers: number;
}

export interface GetAdQueryResultLanguageResult {
  name?: string | null;
}

export interface GetAdQueryResultLocationResult {
  country?: string | null;
  town?: string | null;
}

export interface GetAdQueryResultPhone {
  /** @format int32 */
  countryCode: number;
  number?: string | null;
  isTelegram: boolean;
  isWhatsApp: boolean;
  isWeChat: boolean;
  isViber: boolean;
  isLine: boolean;
  isSignal: boolean;
}

export interface GetAdQueryResultRate {
  rateTime: RateTime;
  /** @format int32 */
  incall?: number | null;
  /** @format int32 */
  outcall?: number | null;
}

export interface GetAdQueryResultService {
  /** @format double */
  price: number;
  serviceName?: string | null;
}

export interface GetAdsCounterQueryResult {
  iso2: string;
  name: string;
  /** @format int32 */
  no: number;
  /** @format int32 */
  count: number;
  towns: GetAdsCounterQueryResultTownResult[];
}

export interface GetAdsCounterQueryResultTownResult {
  name?: string | null;
  /** @format int32 */
  no: number;
  /** @format int32 */
  count: number;
}

export interface GetAdsQueryResult {
  /** @format date-time */
  createdAt: string;
  town: string;
  country: string;
  name: string;
  description: string;
  image: string;
  /** @format uuid */
  id: string;
}

export interface GetAllUserPermissionsQueryResult {
  permissions: string[];
}

export interface GetCountriesQueryResult {
  name: string;
  /** @format int32 */
  no: number;
  /** @format int32 */
  countryPhoneCode: number;
}

export interface GetCountryTownsQueryResult {
  name: string;
  /** @format int32 */
  no: number;
}

export interface GetCurrenciesQueryResult {
  /** @format int32 */
  currencyNo: number;
  currencyCode: string;
}

export interface GetCurrentUserQueryResult {
  /** @format uuid */
  id: string;
  nickName: string;
  /** @format date-time */
  created: string;
  /** @format date-time */
  expireAt?: string | null;
  userType: UserType;
  userStatus: UserStatus;
  avatar: string;
  permissions: string[];
  adData: GetCurrentUserQueryResultAdResult;
  reviewData: GetCurrentUserQueryResultReviewResult;
  commentData: GetCurrentUserQueryResultCommentResult;
  isVeteran: boolean;
  balance: GetCurrentUserQueryResultBalanceResult;
  isPublicReviewExists: boolean;
}

export interface GetCurrentUserQueryResultAdResult {
  /** @format int32 */
  maxPublishAdsLimit: number;
  /** @format int32 */
  currentlyPublished: number;
}

export interface GetCurrentUserQueryResultBalanceResult {
  /** @format double */
  balance: number;
}

export interface GetCurrentUserQueryResultCommentResult {
  /** @format int32 */
  currentlyCreatedComments: number;
}

export interface GetCurrentUserQueryResultReviewResult {
  /** @format int32 */
  currentlyPublished: number;
  /** @format int32 */
  currentlyCreated: number;
  /** @format int32 */
  followingReviews: number;
}

export interface GetFollowedReviewsQueryResult {
  town: string;
  image: string;
  /** @format uuid */
  id: string;
  /** @format double */
  average: number;
  /** @format int32 */
  likes: number;
  /** @format int32 */
  dislikes: number;
}

export interface GetLikedDislikedCommentsQueryResult {
  town: string;
  image: string;
  /** @format uuid */
  id: string;
}

export interface GetLikedDislikedReviewsQueryResult {
  town: string;
  image: string;
  /** @format uuid */
  id: string;
}

export interface GetNationalitiesQueryResult {
  name: string;
  /** @format int32 */
  no: number;
}

export interface GetPersonalAdQueryResult {
  name: string;
  description: string;
  /** @format date-time */
  created: string;
  type: AdType;
  availableFor: AvailableFor;
  breastSize?: BreastSize;
  breastType?: BreastType;
  eyeColor: EyeColor;
  ethnicity: Ethnicity;
  hairColor: HairColor;
  hairLenght: HairLenght;
  meetingWith: MeetingWith;
  orientation: Orientation;
  piercing: Piercing;
  pubicHair: PubicHair;
  smoker: Smoker;
  travel: Travel;
  /** @format int32 */
  age: number;
  /** @format int32 */
  height: number;
  /** @format int32 */
  weight: number;
  /** @format int32 */
  dickSize?: number | null;
  isPornStar: boolean;
  tattoo: boolean;
  images: string[];
  rates: GetPersonalAdQueryResultRate[];
  phones: GetPersonalAdQueryResultPhone[];
  services: GetPersonalAdQueryResultService[];
  languages: GetPersonalAdQueryResultLanguageResult[];
  nationality: GetPersonalAdQueryResultNationalityResult;
  location: GetPersonalAdQueryResultLocationResult;
  status: AdStatus;
  currency: GetPersonalAdQueryResultCurrencyResult;
}

export interface GetPersonalAdQueryResultCurrencyResult {
  /** @format int32 */
  no: number;
  currencyCode?: string | null;
}

export interface GetPersonalAdQueryResultLanguageResult {
  /** @format int32 */
  no: number;
  name?: string | null;
}

export interface GetPersonalAdQueryResultLocationResult {
  /** @format int32 */
  townNo: number;
  /** @format int32 */
  countryNo: number;
  country?: string | null;
  town?: string | null;
}

export interface GetPersonalAdQueryResultNationalityResult {
  /** @format int32 */
  nationalityNo: number;
  name?: string | null;
}

export interface GetPersonalAdQueryResultPhone {
  /** @format int32 */
  phoneNo: number;
  /** @format int32 */
  countryNo: number;
  /** @format int32 */
  countryCode: number;
  number?: string | null;
  isTelegram: boolean;
  isWhatsApp: boolean;
  isWeChat: boolean;
  isViber: boolean;
  isLine: boolean;
  isSignal: boolean;
}

export interface GetPersonalAdQueryResultRate {
  rateTime: RateTime;
  /** @format int32 */
  incall?: number | null;
  /** @format int32 */
  outcall?: number | null;
}

export interface GetPersonalAdQueryResultService {
  /** @format int32 */
  serviceNo: number;
  /** @format double */
  price: number;
  serviceName?: string | null;
}

export interface GetPersonalAdsQueryResult {
  town: string;
  country: string;
  name: string;
  description: string;
  image: string;
  /** @format uuid */
  id: string;
  status: AdStatus;
}

export interface GetPersonalReviewQueryResult {
  name: string;
  description: string;
  ad: GetPersonalReviewQueryResultAdResult;
  images: string[];
  rates: GetPersonalReviewQueryResultRate[];
  sections: GetPersonalReviewQueryResultSection[];
  location: GetPersonalReviewQueryResultLocationResult;
  currency: GetPersonalReviewQueryResultCurrencyResult;
  status: ReviewStatus;
}

export interface GetPersonalReviewQueryResultAdResult {
  externalAdUrl?: string | null;
  /** @format uuid */
  internalAdId?: string | null;
}

export interface GetPersonalReviewQueryResultCurrencyResult {
  /** @format int32 */
  currencyNo: number;
  currencyCode?: string | null;
}

export interface GetPersonalReviewQueryResultLocationResult {
  /** @format int32 */
  townNo: number;
  /** @format int32 */
  countryNo: number;
  country?: string | null;
  town?: string | null;
}

export interface GetPersonalReviewQueryResultRate {
  rateTime: RateTime;
  /** @format int32 */
  incall?: number | null;
  /** @format int32 */
  outcall?: number | null;
}

export interface GetPersonalReviewQueryResultSection {
  /** @format double */
  rate: number;
  /** @format int32 */
  sectionNo: number;
  name?: string | null;
}

export interface GetPersonalReviewsQueryResult {
  name: string;
  /** @format date-time */
  createdAt: string;
  town: string;
  country: string;
  image: string;
  /** @format uuid */
  id: string;
  status: ReviewStatus;
  /** @format double */
  average: number;
  /** @format int32 */
  likes: number;
  /** @format int32 */
  dislikes: number;
}

export interface GetPremiumTimeQueryResult {
  /** @format int64 */
  no: number;
  /** @format double */
  cost: number;
  /** @format int32 */
  premiumTimeInDays: number;
}

export interface GetPublicReviewsQueryResult {
  review: string;
  creatorNickName: string;
}

export interface GetReputationQueryResult {
  nickName: string;
  /** @format uuid */
  userId: string;
  /** @format int32 */
  countCreatedReviews: number;
  /** @format int32 */
  countCreatedComments: number;
  /** @format double */
  countReputation: number;
  isVeteran: boolean;
}

export interface GetReviewCommentsQueryResult {
  /** @format int32 */
  no: number;
  text: string;
  /** @format date-time */
  created: string;
  /** @format int32 */
  likes: number;
  /** @format int32 */
  dislikes: number;
  isCurrentUserLiked?: boolean | null;
  createdBy: string;
  avatar: string;
  isVeteran: boolean;
  innerComments: GetReviewCommentsQueryResultInnerResult[];
}

export interface GetReviewCommentsQueryResultInnerResult {
  /** @format int32 */
  no: number;
  text: string;
  /** @format date-time */
  created: string;
  /** @format int32 */
  likes: number;
  /** @format int32 */
  dislikes: number;
  isCurrentUserLiked?: boolean | null;
  createdBy: string;
  avatar: string;
  isVeteran: boolean;
}

export interface GetReviewQueryResult {
  name: string;
  description: string;
  ad: GetReviewQueryResultAdResult;
  images: string[];
  rates: GetReviewQueryResultRate[];
  sections: GetReviewQueryResultSection[];
  location: GetReviewQueryResultLocationResult;
  createdData: GetReviewQueryResultCreationResult;
  currency: GetReviewQueryResultCurrencyResult;
  followers: GetReviewQueryResultFollowingResult;
  likesDislikes: GetReviewQueryResultLikesDislikesResult;
}

export interface GetReviewQueryResultAdResult {
  externalAdUrl?: string | null;
  /** @format uuid */
  internalAdId?: string | null;
}

export interface GetReviewQueryResultCreationResult {
  /** @format uuid */
  creatorId: string;
  nickName?: string | null;
  /** @format date-time */
  createdAt: string;
  isVeteran: boolean;
}

export interface GetReviewQueryResultCurrencyResult {
  currencyCode?: string | null;
}

export interface GetReviewQueryResultFollowingResult {
  isCurrentUserIsFollwing: boolean;
  /** @format int32 */
  followers: number;
}

export interface GetReviewQueryResultLikesDislikesResult {
  /** @format int32 */
  likes: number;
  /** @format int32 */
  dislikes: number;
  isCurrentUserLiked?: boolean | null;
}

export interface GetReviewQueryResultLocationResult {
  country?: string | null;
  town?: string | null;
}

export interface GetReviewQueryResultRate {
  rateTime: RateTime;
  /** @format int32 */
  incall?: number | null;
  /** @format int32 */
  outcall?: number | null;
}

export interface GetReviewQueryResultSection {
  /** @format double */
  rate: number;
  name?: string | null;
}

export interface GetReviewSectionsQueryResult {
  name: string;
  /** @format int32 */
  no: number;
}

export interface GetReviewsCounterQueryResult {
  iso2: string;
  name: string;
  /** @format int32 */
  no: number;
  /** @format int32 */
  count: number;
  towns: GetReviewsCounterQueryResultTownResult[];
}

export interface GetReviewsCounterQueryResultTownResult {
  name?: string | null;
  /** @format int32 */
  no: number;
  /** @format int32 */
  count: number;
}

export interface GetReviewsQueryResult {
  name: string;
  /** @format date-time */
  createdAt: string;
  country: string;
  town: string;
  image: string;
  /** @format uuid */
  id: string;
  /** @format double */
  average: number;
  /** @format int32 */
  likes: number;
  /** @format int32 */
  dislikes: number;
}

export interface GetServicesQueryResult {
  /** @format int32 */
  no: number;
  name: string;
}

export interface GetUserQueryResult {
  nickName: string;
  /** @format date-time */
  created: string;
  userType: UserType;
  userStatus: UserStatus;
  avatar: string;
  permissions: string[];
  adData: GetUserQueryResultAdResult;
  reviewData: GetUserQueryResultReviewResult;
  commentData: GetUserQueryResultCommentResult;
  isVeteran: boolean;
}

export interface GetUserQueryResultAdResult {
  /** @format int32 */
  maxPublishAdsLimit: number;
  /** @format int32 */
  currentlyPublished: number;
}

export interface GetUserQueryResultCommentResult {
  /** @format int32 */
  currentlyCreatedComments: number;
}

export interface GetUserQueryResultReviewResult {
  /** @format int32 */
  currentlyPublished: number;
  /** @format int32 */
  followingReviews: number;
}

export enum HairColor {
  Blonde = 'Blonde',
  Brown = 'Brown',
  Black = 'Black',
  Red = 'Red',
}

export enum HairLenght {
  Short = 'Short',
  Medium = 'Medium',
  Long = 'Long',
  Bald = 'Bald',
}

export type HttpValidationProblemDetails = ProblemDetails & {
  errors: Record<string, string[]>;
  [key: string]: any;
};

export interface LimitedResultGetAdsCounterQueryResult {
  items: GetAdsCounterQueryResult[];
  /** @format int32 */
  totalCount: number;
}

export interface LimitedResultGetAdsQueryResult {
  items: GetAdsQueryResult[];
  /** @format int32 */
  totalCount: number;
}

export interface LimitedResultGetCountriesQueryResult {
  items: GetCountriesQueryResult[];
  /** @format int32 */
  totalCount: number;
}

export interface LimitedResultGetCountryTownsQueryResult {
  items: GetCountryTownsQueryResult[];
  /** @format int32 */
  totalCount: number;
}

export interface LimitedResultGetCurrenciesQueryResult {
  items: GetCurrenciesQueryResult[];
  /** @format int32 */
  totalCount: number;
}

export interface LimitedResultGetFollowedReviewsQueryResult {
  items: GetFollowedReviewsQueryResult[];
  /** @format int32 */
  totalCount: number;
}

export interface LimitedResultGetLikedDislikedCommentsQueryResult {
  items: GetLikedDislikedCommentsQueryResult[];
  /** @format int32 */
  totalCount: number;
}

export interface LimitedResultGetLikedDislikedReviewsQueryResult {
  items: GetLikedDislikedReviewsQueryResult[];
  /** @format int32 */
  totalCount: number;
}

export interface LimitedResultGetNationalitiesQueryResult {
  items: GetNationalitiesQueryResult[];
  /** @format int32 */
  totalCount: number;
}

export interface LimitedResultGetPersonalAdsQueryResult {
  items: GetPersonalAdsQueryResult[];
  /** @format int32 */
  totalCount: number;
}

export interface LimitedResultGetPersonalReviewsQueryResult {
  items: GetPersonalReviewsQueryResult[];
  /** @format int32 */
  totalCount: number;
}

export interface LimitedResultGetPremiumTimeQueryResult {
  items: GetPremiumTimeQueryResult[];
  /** @format int32 */
  totalCount: number;
}

export interface LimitedResultGetPublicReviewsQueryResult {
  items: GetPublicReviewsQueryResult[];
  /** @format int32 */
  totalCount: number;
}

export interface LimitedResultGetReputationQueryResult {
  items: GetReputationQueryResult[];
  /** @format int32 */
  totalCount: number;
}

export interface LimitedResultGetReviewCommentsQueryResult {
  items: GetReviewCommentsQueryResult[];
  /** @format int32 */
  totalCount: number;
}

export interface LimitedResultGetReviewSectionsQueryResult {
  items: GetReviewSectionsQueryResult[];
  /** @format int32 */
  totalCount: number;
}

export interface LimitedResultGetReviewsCounterQueryResult {
  items: GetReviewsCounterQueryResult[];
  /** @format int32 */
  totalCount: number;
}

export interface LimitedResultGetReviewsQueryResult {
  items: GetReviewsQueryResult[];
  /** @format int32 */
  totalCount: number;
}

export interface LimitedResultGetServicesQueryResult {
  items: GetServicesQueryResult[];
  /** @format int32 */
  totalCount: number;
}

export enum MeetingWith {
  Man = 'Man',
  Woman = 'Woman',
  Couples = 'Couples',
  Both = 'Both',
}

export enum Orientation {
  Straight = 'Straight',
  Bisexual = 'Bisexual',
  Lesbian = 'Lesbian',
  Homosexual = 'Homosexual',
}

export interface P2PTransferRequest {
  receiverId: string;
  /** @format double */
  amount: number;
}

export enum Piercing {
  No = 'No',
  Belly = 'Belly',
  Eyebrow = 'Eyebrow',
  Genitals = 'Genitals',
  Mouth = 'Mouth',
  Nose = 'Nose',
  Nipples = 'Nipples',
  Tongue = 'Tongue',
}

export interface ProblemDetails {
  type?: string | null;
  title?: string | null;
  /** @format int32 */
  status?: number | null;
  detail?: string | null;
  instance?: string | null;
  [key: string]: any;
}

export enum PubicHair {
  Shaved = 'Shaved',
  Trimmed = 'Trimmed',
  Natural = 'Natural',
}

export enum RateTime {
  HalfAHour = 'HalfAHour',
  Hour = 'Hour',
  Two = 'Two',
  Three = 'Three',
  Four = 'Four',
  Six = 'Six',
  Twelwe = 'Twelwe',
  TwentyFour = 'TwentyFour',
  FourtyEight = 'FourtyEight',
}

export interface RefereshAccessTokenCommandResult {
  accessToken: string;
  /** @format int64 */
  expiresIn: number;
  refreshToken: string;
}

export interface RefreshAccessTokenRequest {
  refreshToken: string;
}

export interface RegisterRequest {
  email: string;
  password: string;
  userName: string;
  userType: UserType;
  repeatedPassword: string;
}

export interface ResetPasswordRequest {
  /** @format uuid */
  passwordResetRequestId: string;
  newPassword: string;
  repeatedNewPassword: string;
}

export interface ReviewRatesRequest {
  time: RateTime;
  /** @format int32 */
  incall?: number | null;
  /** @format int32 */
  outcall?: number | null;
}

export interface ReviewSectionRequest {
  /** @format int32 */
  sectionNo: number;
  /** @format double */
  rate: number;
}

export enum ReviewStatus {
  Active = 'Active',
  Disabled = 'Disabled',
}

export interface SignInRequest {
  emailOrUserName: string;
  password: string;
}

export interface SignInUserCommandResponse {
  accessToken: string;
  /** @format int64 */
  expiresIn: number;
  refreshToken: string;
  /** @format uuid */
  signInAttemptId?: string | null;
  type?: string | null;
}

export enum Smoker {
  Yes = 'Yes',
  No = 'No',
  Sometimes = 'Sometimes',
}

export interface StatusControllerStatusResponse {
  /** @format date-time */
  serverTime: string;
  version: string;
  isDbAlive: boolean;
}

export enum Travel {
  Countrywide = 'Countrywide',
  Europe = 'Europe',
  Worldwide = 'Worldwide',
  NoTravel = 'NoTravel',
}

export interface UpdateAdBaseInfoRequest {
  name: string;
  description: string;
  adType: AdType;
  availableFor: AvailableFor;
  breastSize?: BreastSize;
  breastType?: BreastType;
  eyeColor: EyeColor;
  ethnicity: Ethnicity;
  hairColor: HairColor;
  hairLenght: HairLenght;
  meetingWith: MeetingWith;
  orientation: Orientation;
  piercing: Piercing;
  pubicHair: PubicHair;
  smoker: Smoker;
  travel: Travel;
  /** @format int32 */
  age: number;
  /** @format int32 */
  height: number;
  /** @format int32 */
  weight: number;
  /** @format int32 */
  dickSize?: number | null;
  isPornStar?: boolean | null;
  tattoo?: boolean | null;
  /** @format int32 */
  nationalityNo: number;
  /** @format int32 */
  locationNo: number;
  /** @format int32 */
  currencyNo: number;
  languages: number[];
  services: AdServicesRequest[];
  rates: AdRatesRequest[];
  phones: AdPhonesRequest[];
}

export interface UpdateReviewBaseInfoRequest {
  name: string;
  description: string;
  /** @format int32 */
  locationNo: number;
  /** @format int32 */
  currencyNo: number;
  sections: ReviewSectionRequest[];
  rates: ReviewRatesRequest[];
}

export enum UserStatus {
  Created = 'Created',
  Active = 'Active',
  Suspended = 'Suspended',
}

export enum UserType {
  Reviewer = 'Reviewer',
  Agency = 'Agency',
  Independent = 'Independent',
}

export interface V1AlphaListParams {
  /** @format uuid */
  UserId?: string;
  /** @format int32 */
  TownNo?: number;
  /** @format int32 */
  CountryNo?: number;
  /** @format int32 */
  AgeFrom?: number;
  /** @format int32 */
  AgeTo?: number;
  HairColor?: HairColor;
  HairLenght?: HairLenght;
  PubicHair?: PubicHair;
  BreastSize?: BreastSize;
  BreastType?: BreastType;
  Travel?: Travel;
  /** @format int32 */
  WeightFrom?: number;
  /** @format int32 */
  WeightTo?: number;
  /** @format int32 */
  HeightFrom?: number;
  /** @format int32 */
  HeightTo?: number;
  Ethnicity?: Ethnicity;
  /** @format int32 */
  NationalityNo?: number;
  Languages?: number[];
  Smoker?: Smoker;
  Piercing?: Piercing;
  EyeColor?: EyeColor;
  Orientation?: Orientation;
  Tattoo?: boolean;
  Pornstar?: boolean;
  AvailableFor?: AvailableFor;
  Time?: RateTime;
  /** @format double */
  RateFrom?: number;
  /** @format double */
  RateTo?: number;
  Services?: number[];
  /** @format int32 */
  Limit?: number;
  Search?: string;
  /** @format int32 */
  Skip?: number;
  SortDirection?: string;
  SortProperty?: string;
}

export type V1AlphaListData = LimitedResultGetAdsQueryResult;

export type V1AlphaListError =
  | BravoProblemDetails
  | (ProblemDetails | HttpValidationProblemDetails);

export type V1AlphaCreateData = CreateAdBaseInformationCommandResponse;

export type V1AlphaCreateError =
  | BravoProblemDetails
  | (ProblemDetails | HttpValidationProblemDetails);

export type V1AlphaDetailData = GetAdQueryResult;

export type V1AlphaDetailError =
  | BravoProblemDetails
  | (ProblemDetails | HttpValidationProblemDetails);

export interface V1AlphaPersonalListParams {
  /** @format uuid */
  UserId?: string;
  /** @format int32 */
  TownNo?: number;
  /** @format int32 */
  CountryNo?: number;
  /** @format int32 */
  AgeFrom?: number;
  /** @format int32 */
  AgeTo?: number;
  HairColor?: HairColor;
  HairLenght?: HairLenght;
  PubicHair?: PubicHair;
  BreastSize?: BreastSize;
  BreastType?: BreastType;
  Travel?: Travel;
  /** @format int32 */
  WeightFrom?: number;
  /** @format int32 */
  WeightTo?: number;
  /** @format int32 */
  HeightFrom?: number;
  /** @format int32 */
  HeightTo?: number;
  Ethnicity?: Ethnicity;
  /** @format int32 */
  NationalityNo?: number;
  Languages?: number[];
  Smoker?: Smoker;
  Piercing?: Piercing;
  EyeColor?: EyeColor;
  Orientation?: Orientation;
  Tattoo?: boolean;
  Pornstar?: boolean;
  AvailableFor?: AvailableFor;
  Time?: RateTime;
  /** @format double */
  RateFrom?: number;
  /** @format double */
  RateTo?: number;
  Services?: number[];
  /** @format int32 */
  Limit?: number;
  Search?: string;
  /** @format int32 */
  Skip?: number;
  SortDirection?: string;
  SortProperty?: string;
}

export type V1AlphaPersonalListData = LimitedResultGetPersonalAdsQueryResult;

export type V1AlphaPersonalListError =
  | BravoProblemDetails
  | (ProblemDetails | HttpValidationProblemDetails);

export type V1AlphaPersonalDetailData = GetPersonalAdQueryResult;

export type V1AlphaPersonalDetailError =
  | BravoProblemDetails
  | (ProblemDetails | HttpValidationProblemDetails);

export type V1AlphaBaseUpdateData = any;

export type V1AlphaBaseUpdateError =
  | BravoProblemDetails
  | (ProblemDetails | HttpValidationProblemDetails);

export interface V1AlphaImagesUpdatePayload {
  Files?: File[];
  ExistingFileNames?: string[];
}

export type V1AlphaImagesUpdateData = any;

export type V1AlphaImagesUpdateError =
  | BravoProblemDetails
  | (ProblemDetails | HttpValidationProblemDetails);

export type V1AlphaActivatePartialUpdateData = any;

export type V1AlphaActivatePartialUpdateError =
  | BravoProblemDetails
  | (ProblemDetails | HttpValidationProblemDetails);

export type V1AlphaDisablePartialUpdateData = any;

export type V1AlphaDisablePartialUpdateError =
  | BravoProblemDetails
  | (ProblemDetails | HttpValidationProblemDetails);

export type V1AlphaAdTypesListData = string[];

export type V1AlphaAdTypesListError =
  | BravoProblemDetails
  | (ProblemDetails | HttpValidationProblemDetails);

export type V1AlphaAvailableForsListData = string[];

export type V1AlphaAvailableForsListError =
  | BravoProblemDetails
  | (ProblemDetails | HttpValidationProblemDetails);

export type V1AlphaBreastSizesListData = string[];

export type V1AlphaBreastSizesListError =
  | BravoProblemDetails
  | (ProblemDetails | HttpValidationProblemDetails);

export type V1AlphaBreastTypesListData = string[];

export type V1AlphaBreastTypesListError =
  | BravoProblemDetails
  | (ProblemDetails | HttpValidationProblemDetails);

export type V1AlphaEyeColorsListData = string[];

export type V1AlphaEyeColorsListError =
  | BravoProblemDetails
  | (ProblemDetails | HttpValidationProblemDetails);

export type V1AlphaEthnicitiesListData = string[];

export type V1AlphaEthnicitiesListError =
  | BravoProblemDetails
  | (ProblemDetails | HttpValidationProblemDetails);

export type V1AlphaHairColorsListData = string[];

export type V1AlphaHairColorsListError =
  | BravoProblemDetails
  | (ProblemDetails | HttpValidationProblemDetails);

export type V1AlphaHairLenghtsListData = string[];

export type V1AlphaHairLenghtsListError =
  | BravoProblemDetails
  | (ProblemDetails | HttpValidationProblemDetails);

export type V1AlphaMeetingWithListData = string[];

export type V1AlphaMeetingWithListError =
  | BravoProblemDetails
  | (ProblemDetails | HttpValidationProblemDetails);

export type V1AlphaOrientationListData = string[];

export type V1AlphaOrientationListError =
  | BravoProblemDetails
  | (ProblemDetails | HttpValidationProblemDetails);

export type V1AlphaPiercingListData = string[];

export type V1AlphaPiercingListError =
  | BravoProblemDetails
  | (ProblemDetails | HttpValidationProblemDetails);

export type V1AlphaPubicHairListData = string[];

export type V1AlphaPubicHairListError =
  | BravoProblemDetails
  | (ProblemDetails | HttpValidationProblemDetails);

export type V1AlphaRateTimeListData = string[];

export type V1AlphaRateTimeListError =
  | BravoProblemDetails
  | (ProblemDetails | HttpValidationProblemDetails);

export type V1AlphaSmokerListData = string[];

export type V1AlphaSmokerListError =
  | BravoProblemDetails
  | (ProblemDetails | HttpValidationProblemDetails);

export type V1AlphaTravelListData = string[];

export type V1AlphaTravelListError =
  | BravoProblemDetails
  | (ProblemDetails | HttpValidationProblemDetails);

export interface V1AlphaServicesListParams {
  /** @format int32 */
  Limit?: number;
  Search?: string;
  /** @format int32 */
  Skip?: number;
  SortDirection?: string;
  SortProperty?: string;
}

export type V1AlphaServicesListData = LimitedResultGetServicesQueryResult;

export type V1AlphaServicesListError =
  | BravoProblemDetails
  | (ProblemDetails | HttpValidationProblemDetails);

export type V1AuthLoginCreateData = SignInUserCommandResponse;

export type V1AuthLoginCreateError = BravoProblemDetails;

export type V1AuthRegisterCreateData = any;

export type V1AuthRegisterCreateError = BravoProblemDetails;

export type V1AuthResetCreateData = any;

export type V1AuthResetCreateError =
  | BravoProblemDetails
  | (ProblemDetails | HttpValidationProblemDetails);

export type V1AuthPasswordCreateData = any;

export type V1AuthPasswordCreateError =
  | BravoProblemDetails
  | (ProblemDetails | HttpValidationProblemDetails);

export type V1AuthRefreshCreateData = RefereshAccessTokenCommandResult;

export type V1AuthRefreshCreateError =
  | BravoProblemDetails
  | (ProblemDetails | HttpValidationProblemDetails);

export type V1AuthAvailablePermissionsListData = GetAllUserPermissionsQueryResult;

export type V1AuthAvailablePermissionsListError =
  | BravoProblemDetails
  | (ProblemDetails | HttpValidationProblemDetails);

export interface V1CommentsReviewsListParams {
  /** @format uuid */
  ReviewId?: string;
  /** @format int32 */
  Limit?: number;
  Search?: string;
  /** @format int32 */
  Skip?: number;
  SortDirection?: string;
  SortProperty?: string;
}

export type V1CommentsReviewsListData = LimitedResultGetReviewCommentsQueryResult;

export type V1CommentsReviewsListError =
  | BravoProblemDetails
  | (ProblemDetails | HttpValidationProblemDetails);

export type V1CommentsReviewsCreateData = any;

export type V1CommentsReviewsCreateError =
  | BravoProblemDetails
  | (ProblemDetails | HttpValidationProblemDetails);

export interface V1CurrenciesListParams {
  /** @format int32 */
  CurrencyNo?: number;
  /** @format int32 */
  Limit?: number;
  Search?: string;
  /** @format int32 */
  Skip?: number;
  SortDirection?: string;
  SortProperty?: string;
}

export type V1CurrenciesListData = LimitedResultGetCurrenciesQueryResult;

export type V1CurrenciesListError =
  | BravoProblemDetails
  | (ProblemDetails | HttpValidationProblemDetails);

export type V1ErrorCodesListData = ErrorCodesResponse;

export type V1ErrorCodesListError =
  | BravoProblemDetails
  | (ProblemDetails | HttpValidationProblemDetails);

export type V1FollowingsReviewsFollowPartialUpdateData = any;

export type V1FollowingsReviewsFollowPartialUpdateError =
  | BravoProblemDetails
  | (ProblemDetails | HttpValidationProblemDetails);

export type V1FollowingsReviewsUnfollowPartialUpdateData = any;

export type V1FollowingsReviewsUnfollowPartialUpdateError =
  | BravoProblemDetails
  | (ProblemDetails | HttpValidationProblemDetails);

export interface V1FollowingsReviewsCurrentUserListParams {
  /** @format int32 */
  Limit?: number;
  Search?: string;
  /** @format int32 */
  Skip?: number;
  SortDirection?: string;
  SortProperty?: string;
}

export type V1FollowingsReviewsCurrentUserListData = LimitedResultGetFollowedReviewsQueryResult;

export type V1FollowingsReviewsCurrentUserListError =
  | BravoProblemDetails
  | (ProblemDetails | HttpValidationProblemDetails);

export type V1FollowingsAlphaFollowPartialUpdateData = any;

export type V1FollowingsAlphaFollowPartialUpdateError =
  | BravoProblemDetails
  | (ProblemDetails | HttpValidationProblemDetails);

export type V1FollowingsAlphaUnfollowPartialUpdateData = any;

export type V1FollowingsAlphaUnfollowPartialUpdateError =
  | BravoProblemDetails
  | (ProblemDetails | HttpValidationProblemDetails);

export interface V1FollowingsAlphaCurrentUserListParams {
  /** @format int32 */
  Limit?: number;
  Search?: string;
  /** @format int32 */
  Skip?: number;
  SortDirection?: string;
  SortProperty?: string;
}

export type V1FollowingsAlphaCurrentUserListData = LimitedResultGetFollowedReviewsQueryResult;

export type V1FollowingsAlphaCurrentUserListError =
  | BravoProblemDetails
  | (ProblemDetails | HttpValidationProblemDetails);

export type V1LikesReviewsLikePartialUpdateData = any;

export type V1LikesReviewsLikePartialUpdateError =
  | BravoProblemDetails
  | (ProblemDetails | HttpValidationProblemDetails);

export type V1LikesReviewsDislikePartialUpdateData = any;

export type V1LikesReviewsDislikePartialUpdateError =
  | BravoProblemDetails
  | (ProblemDetails | HttpValidationProblemDetails);

export type V1LikesReviewsUnlikePartialUpdateData = any;

export type V1LikesReviewsUnlikePartialUpdateError =
  | BravoProblemDetails
  | (ProblemDetails | HttpValidationProblemDetails);

export type V1LikesCommentsLikePartialUpdateData = any;

export type V1LikesCommentsLikePartialUpdateError =
  | BravoProblemDetails
  | (ProblemDetails | HttpValidationProblemDetails);

export type V1LikesCommentsDislikePartialUpdateData = any;

export type V1LikesCommentsDislikePartialUpdateError =
  | BravoProblemDetails
  | (ProblemDetails | HttpValidationProblemDetails);

export type V1LikesCommentsUnlikePartialUpdateData = any;

export type V1LikesCommentsUnlikePartialUpdateError =
  | BravoProblemDetails
  | (ProblemDetails | HttpValidationProblemDetails);

export interface V1LikesReviewsCurrentUserListParams {
  /** @format int32 */
  Limit?: number;
  Search?: string;
  /** @format int32 */
  Skip?: number;
  SortDirection?: string;
  SortProperty?: string;
}

export type V1LikesReviewsCurrentUserListData = LimitedResultGetLikedDislikedReviewsQueryResult;

export type V1LikesReviewsCurrentUserListError =
  | BravoProblemDetails
  | (ProblemDetails | HttpValidationProblemDetails);

export interface V1LikesCommentsCurrentUserListParams {
  /** @format int32 */
  Limit?: number;
  Search?: string;
  /** @format int32 */
  Skip?: number;
  SortDirection?: string;
  SortProperty?: string;
}

export type V1LikesCommentsCurrentUserListData = LimitedResultGetLikedDislikedCommentsQueryResult;

export type V1LikesCommentsCurrentUserListError =
  | BravoProblemDetails
  | (ProblemDetails | HttpValidationProblemDetails);

export interface V1LocationsCountriesListParams {
  /** @format int32 */
  Limit?: number;
  Search?: string;
  /** @format int32 */
  Skip?: number;
  SortDirection?: string;
  SortProperty?: string;
}

export type V1LocationsCountriesListData = LimitedResultGetCountriesQueryResult;

export type V1LocationsCountriesListError = BravoProblemDetails;

export interface V1LocationsTownsDetailParams {
  /** @format int32 */
  Limit?: number;
  Search?: string;
  /** @format int32 */
  Skip?: number;
  SortDirection?: string;
  SortProperty?: string;
  /** @format int32 */
  countryNo: number;
}

export type V1LocationsTownsDetailData = LimitedResultGetCountryTownsQueryResult;

export type V1LocationsTownsDetailError = BravoProblemDetails;

export interface V1LocationsLanguagesListParams {
  /** @format int32 */
  Limit?: number;
  Search?: string;
  /** @format int32 */
  Skip?: number;
  SortDirection?: string;
  SortProperty?: string;
}

export type V1LocationsLanguagesListData = LimitedResultGetNationalitiesQueryResult;

export type V1LocationsLanguagesListError = BravoProblemDetails;

export interface V1LocationsCountsAlphaListParams {
  /** @format int32 */
  Limit?: number;
  Search?: string;
  /** @format int32 */
  Skip?: number;
  SortDirection?: string;
  SortProperty?: string;
}

export type V1LocationsCountsAlphaListData = LimitedResultGetAdsCounterQueryResult;

export type V1LocationsCountsAlphaListError = BravoProblemDetails;

export interface V1LocationsCountsReviewsListParams {
  Search?: string;
  SortDirection?: string;
  SortProperty?: string;
  /** @format int32 */
  Skip?: number;
  /** @format int32 */
  Limit?: number;
}

export type V1LocationsCountsReviewsListData = LimitedResultGetReviewsCounterQueryResult;

export type V1LocationsCountsReviewsListError = BravoProblemDetails;

export type V1PremiumListData = LimitedResultGetPremiumTimeQueryResult;

export type V1PremiumListError =
  | BravoProblemDetails
  | (ProblemDetails | HttpValidationProblemDetails);

export type V1PremiumCreateData = any;

export type V1PremiumCreateError =
  | BravoProblemDetails
  | (ProblemDetails | HttpValidationProblemDetails);

export interface V1PublicReviewsListParams {
  /** @format int32 */
  Limit?: number;
  Search?: string;
  /** @format int32 */
  Skip?: number;
  SortDirection?: string;
  SortProperty?: string;
}

export type V1PublicReviewsListData = LimitedResultGetPublicReviewsQueryResult;

export type V1PublicReviewsListError =
  | BravoProblemDetails
  | (ProblemDetails | HttpValidationProblemDetails);

export type V1PublicReviewsCreateData = any;

export type V1PublicReviewsCreateError =
  | BravoProblemDetails
  | (ProblemDetails | HttpValidationProblemDetails);

export type V1ReputationListData = LimitedResultGetReputationQueryResult;

export type V1ReputationListError =
  | BravoProblemDetails
  | (ProblemDetails | HttpValidationProblemDetails);

export interface V1ReviewsListParams {
  /** @format uuid */
  UserId?: string;
  MyLiked?: boolean;
  MyFollowed?: boolean;
  /** @format double */
  RatingFrom?: number;
  /** @format double */
  RatingTo?: number;
  /** @format uuid */
  FollowedByUserId?: string;
  CreatorNickName?: string;
  /** @format int32 */
  LikesFrom?: number;
  /** @format int32 */
  LikesTo?: number;
  /** @format date-time */
  From?: string;
  /** @format date-time */
  To?: string;
  /** @format int32 */
  TownNo?: number;
  /** @format int32 */
  CountryNo?: number;
  /** @format int32 */
  Limit?: number;
  Search?: string;
  /** @format int32 */
  Skip?: number;
  SortDirection?: string;
  SortProperty?: string;
}

export type V1ReviewsListData = LimitedResultGetReviewsQueryResult;

export type V1ReviewsListError =
  | BravoProblemDetails
  | (ProblemDetails | HttpValidationProblemDetails);

export type V1ReviewsPublicListData = LimitedResultGetReviewsQueryResult;

export type V1ReviewsPublicListError =
  | BravoProblemDetails
  | (ProblemDetails | HttpValidationProblemDetails);

export type V1ReviewsDetailData = GetReviewQueryResult;

export type V1ReviewsDetailError =
  | BravoProblemDetails
  | (ProblemDetails | HttpValidationProblemDetails);

export type V1ReviewsUpdateData = any;

export type V1ReviewsUpdateError =
  | BravoProblemDetails
  | (ProblemDetails | HttpValidationProblemDetails);

export interface V1ReviewsPersonalListParams {
  /** @format uuid */
  UserId?: string;
  MyLiked?: boolean;
  MyFollowed?: boolean;
  /** @format double */
  RatingFrom?: number;
  /** @format double */
  RatingTo?: number;
  /** @format uuid */
  FollowedByUserId?: string;
  CreatorNickName?: string;
  /** @format int32 */
  LikesFrom?: number;
  /** @format int32 */
  LikesTo?: number;
  /** @format date-time */
  From?: string;
  /** @format date-time */
  To?: string;
  /** @format int32 */
  TownNo?: number;
  /** @format int32 */
  CountryNo?: number;
  /** @format int32 */
  Limit?: number;
  Search?: string;
  /** @format int32 */
  Skip?: number;
  SortDirection?: string;
  SortProperty?: string;
}

export type V1ReviewsPersonalListData = LimitedResultGetPersonalReviewsQueryResult;

export type V1ReviewsPersonalListError =
  | BravoProblemDetails
  | (ProblemDetails | HttpValidationProblemDetails);

export type V1ReviewsPersonalDetailData = GetPersonalReviewQueryResult;

export type V1ReviewsPersonalDetailError =
  | BravoProblemDetails
  | (ProblemDetails | HttpValidationProblemDetails);

export interface V1ReviewsImagesUpdatePayload {
  Files?: File[];
  ExistingFileNames?: string[];
}

export type V1ReviewsImagesUpdateData = any;

export type V1ReviewsImagesUpdateError =
  | BravoProblemDetails
  | (ProblemDetails | HttpValidationProblemDetails);

export type V1ReviewsInternalCreateData = CreateInternalReviewCommandResponse;

export type V1ReviewsInternalCreateError =
  | BravoProblemDetails
  | (ProblemDetails | HttpValidationProblemDetails);

export type V1ReviewsExternalCreateData = CreateExternalReviewCommandResponse;

export type V1ReviewsExternalCreateError =
  | BravoProblemDetails
  | (ProblemDetails | HttpValidationProblemDetails);

export type V1ReviewsActivatePartialUpdateData = any;

export type V1ReviewsActivatePartialUpdateError =
  | BravoProblemDetails
  | (ProblemDetails | HttpValidationProblemDetails);

export type V1ReviewsDisablePartialUpdateData = any;

export type V1ReviewsDisablePartialUpdateError =
  | BravoProblemDetails
  | (ProblemDetails | HttpValidationProblemDetails);

export type V1ReviewsRateTimeListData = string[];

export type V1ReviewsRateTimeListError =
  | BravoProblemDetails
  | (ProblemDetails | HttpValidationProblemDetails);

export interface V1ReviewsSectionsListParams {
  /** @format int32 */
  Limit?: number;
  Search?: string;
  /** @format int32 */
  Skip?: number;
  SortDirection?: string;
  SortProperty?: string;
}

export type V1ReviewsSectionsListData = LimitedResultGetReviewSectionsQueryResult;

export type V1ReviewsSectionsListError =
  | BravoProblemDetails
  | (ProblemDetails | HttpValidationProblemDetails);

export type V1StatusListData = StatusControllerStatusResponse;

export type V1StatusListError =
  | BravoProblemDetails
  | (ProblemDetails | HttpValidationProblemDetails);

export type V1TransfersP2PCreateData = any;

export type V1TransfersP2PCreateError =
  | BravoProblemDetails
  | (ProblemDetails | HttpValidationProblemDetails);

export type V1TransfersDonationCreateData = any;

export type V1TransfersDonationCreateError =
  | BravoProblemDetails
  | (ProblemDetails | HttpValidationProblemDetails);

export type V1UsersCurrentUserListData = GetCurrentUserQueryResult;

export type V1UsersCurrentUserListError =
  | BravoProblemDetails
  | (ProblemDetails | HttpValidationProblemDetails);

export type V1UsersDetailData = GetUserQueryResult;

export type V1UsersDetailError =
  | BravoProblemDetails
  | (ProblemDetails | HttpValidationProblemDetails);

export interface V1UsersCurrentUserAvatarUpdatePayload {
  /** @format binary */
  File?: File;
}

export type V1UsersCurrentUserAvatarUpdateData = GetUserQueryResult;

export type V1UsersCurrentUserAvatarUpdateError =
  | BravoProblemDetails
  | (ProblemDetails | HttpValidationProblemDetails);

export type V1UsersSignOutCreateData = any;

export type V1UsersSignOutCreateError =
  | BravoProblemDetails
  | (ProblemDetails | HttpValidationProblemDetails);

export type V1UsersCurrentUserPasswordUpdateData = any;

export type V1UsersCurrentUserPasswordUpdateError =
  | BravoProblemDetails
  | (ProblemDetails | HttpValidationProblemDetails);

export type V1UsersCurrentUserActivatePartialUpdateData = any;

export type V1UsersCurrentUserActivatePartialUpdateError =
  | BravoProblemDetails
  | (ProblemDetails | HttpValidationProblemDetails);
