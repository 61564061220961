type FormErrorProps = {
  error:
    | (Record<
        string,
        Partial<{
          type: string | number;
          message: string;
        }>
      > &
        Partial<{
          type: string | number;
          message: string;
        }>)
    | undefined;
};

export default function FormError({ error }: FormErrorProps) {
  if (!error) {
    return null;
  }

  return <p className="text-red-500 my-1 font-light text-sm">{error.message}</p>;
}
