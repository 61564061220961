import classNames from 'classnames';
import { InputHTMLAttributes, ReactNode } from 'react';
import { Controller, FieldPath, FieldValues, UseControllerProps } from 'react-hook-form';
import FormFieldError from './FormFieldError';

type FormFieldProps<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = UseControllerProps<TFieldValues, TName> & {
  onChange?: (value: number | null) => void;
  label?: string;
  suffix?: ReactNode;
} & Omit<InputHTMLAttributes<HTMLInputElement>, 'value' | 'onChange'>;

const FormNumberField = <
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  control,
  name,
  label,
  suffix,
  onChange,
  ...inputProps
}: FormFieldProps<TFieldValues, TName>) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => {
        return (
          <div>
            {label && (
              <label htmlFor={name} className="text-[#999999] text-sm pl-5">
                {label}
              </label>
            )}
            <div className="flex flex-row gap-3.5 items-center relative">
              <input
                id={name}
                type="number"
                className={classNames(
                  'form-control-input block w-full rounded-lg border-transparent border-[#272727] bg-secondary px-[20px] py-[17px] text-white placeholder:text-[#999999] focus:border-transparent',
                  {
                    'pr-[50px]': suffix,
                    '!border-red-500': fieldState.error,
                  },
                )}
                value={field.value === null ? '' : field.value}
                onChange={(e) => {
                  const numberValue = e.target.value ? Number(e.target.value) : null;

                  field.onChange(numberValue);
                  onChange && onChange(numberValue);
                }}
                ref={field.ref}
                {...inputProps}
              />
              {suffix && <span className="text-white absolute right-3.5">{suffix}</span>}
            </div>
            {fieldState.error && <FormFieldError error={fieldState.error} />}
          </div>
        );
      }}
    />
  );
};

export default FormNumberField;
