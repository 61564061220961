import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { ROUTES } from '~/router/Routes';

export const Inactive = () => {
  const { t } = useTranslation();

  return (
    <div className="py-16">
      <h1 className="text-white text-center mb-3">{t('inactivePage.title')}</h1>
      <p className="text-white text-center mb-3">
        <Trans i18nKey="inactivePage.activationCode">
          If you have activation code use it in
          <Link
            to={{
              pathname: ROUTES.USER_SETTINGS,
              search: '?tab=activate',
            }}
            className="link pt-5"
          >
            User settings
          </Link>
        </Trans>
      </p>
      <p className="text-white text-center">
        <Trans i18nKey="inactivePage.contactAdmin">
          Or contact
          <a
            className="link pt-5"
            href={process.env.REACT_APP_ADMIN_LINK}
            target="_blank"
            rel="noreferrer"
          >
            Administrator
          </a>
        </Trans>
      </p>
    </div>
  );
};
