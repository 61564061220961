import { useQuery } from '@tanstack/react-query';
import { ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useSearchParams } from 'react-router-dom';
import {
  UserType,
  V1AlphaListData,
  V1AlphaListParams,
  V1AlphaPersonalListData,
  V1AlphaPersonalListParams,
} from '~/api/data-contracts';
import { Icon } from '~/components/Icon/Icon';
import { Loader } from '~/components/Loader/Loader';
import Pagination from '~/components/Pagination/Pagination';
import { useAuth } from '~/context/AuthContext';
import { useDebounce } from '~/hooks/useDebounce';
import { usePagination } from '~/hooks/usePagination';
import { ROUTES } from '~/router/Routes';
import { useAdFilters } from '../../hooks/useAdFilters/useAdFilters';
import AdAlt from '../AdAlt/AdAlt';
import AdListFiltering from './AdListFiltering';
import PublicReviews from '~/features/public-reviews/components/PublicReviews/PublicReviews';

type AdListProps = {
  adHref: (id?: string) => string;
  loadFn: (
    params: V1AlphaListParams | V1AlphaPersonalListParams,
  ) => Promise<V1AlphaListData | V1AlphaPersonalListData>;
  title: ReactNode;
};

export default function AdList({ adHref, loadFn, title }: AdListProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const { page, rowsPerPage, onPageChange } = usePagination();
  const { apiParams: adFilterParams } = useAdFilters();
  const { t } = useTranslation();

  const country = searchParams.get('country');
  const town = searchParams.get('town');
  const sort = searchParams.get('sort');
  const search = searchParams.get('search');

  const debouncedSearch = useDebounce(search, 500);

  const apiParams = useMemo(() => {
    const result: V1AlphaListParams = {
      Skip: rowsPerPage * (page - 1),
      Limit: rowsPerPage,
    };

    if (country) {
      result.CountryNo = Number.parseInt(country);
    }

    if (town) {
      result.TownNo = Number.parseInt(town);
    }

    if (debouncedSearch) {
      result.Search = debouncedSearch;
    }

    if (sort) {
      result.SortProperty = sort;
    }

    return { ...adFilterParams, ...result };
  }, [page, debouncedSearch, rowsPerPage, sort, adFilterParams]);

  const handleSearchChange = (value: string) => {
    setSearchParams((params) => {
      params.set('search', value);
      return params;
    });
  };

  const { data, isFetching } = useQuery(['ads', apiParams], () => loadFn(apiParams));

  const { hasOneOfRoles } = useAuth();

  const ads = data?.items || [];

  const getContent = () => {
    if (isFetching) {
      return <Loader />;
    }

    if (!ads.length) {
      return <p className="text-white">{t('general.noMatchingResults')}</p>;
    }

    return ads.map((ad) => <AdAlt key={ad.id} ad={ad} adHref={adHref(ad.id)} />);
  };

  return (
    <div className="relative flex flex-1 flex-col gap-14 py-14 px-2.5 lg:px-14 xl:gap-16">
      <div className="hidden items-center justify-between xl:inline-flex">
        <span className="text-lg font-medium text-white lg:text-2xl">{title}</span>
        {hasOneOfRoles([UserType.Agency, UserType.Independent]) && (
          <div className="col text-end">
            <Link
              to={ROUTES.AD_CREATE}
              className="btn-rounded h-12 w-auto bg-[#0E9B57] transition duration-150 ease-linear hover:bg-[#0C844A]"
              role="button"
            >
              <span className="px-16 font-bold flex">{t('ads.createNewAd')}</span>
            </Link>
          </div>
        )}
      </div>

      <div className="relative flex flex-row gap-3.5">
        <div className="relative group w-full">
          <input
            type="text"
            className="form-control-input block w-full rounded-lg border-transparent border-[#272727] bg-secondary px-[20px] py-[17px] text-white placeholder:text-[#999999] focus:border-transparent"
            placeholder={t('ads.searchAds')}
            value={search || ''}
            onChange={(e) => handleSearchChange(e.target.value)}
          />
          <Icon
            alt="Search"
            icon="icon-search"
            className="absolute h-4 w-4 top-1/2 right-5 -translate-y-1/2 text-[#999999]"
          />
        </div>

        <AdListFiltering />
      </div>

      <div className="grid grid-cols-1 gap-2.5">{getContent()}</div>
      <Pagination
        itemCount={data?.totalCount || 0}
        onPageChange={onPageChange}
        page={page}
        rowsPerPage={rowsPerPage}
      />

      <PublicReviews />
    </div>
  );
}
