import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { rowsPerPageOptions } from '~/config/pagination';

enum SearchParam {
  rowsPerPage = 'rowsPerPage',
  page = 'page',
}

export const usePagination = (options?: { rowsPerPage: number }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [page, setPage] = useState(
    searchParams.get(SearchParam.page) ? Number(searchParams.get(SearchParam.page)) : 1,
  );
  const [rowsPerPage, setRowsPerPage] = useState(
    searchParams.get(SearchParam.rowsPerPage)
      ? Number(searchParams.get(SearchParam.rowsPerPage))
      : options?.rowsPerPage || rowsPerPageOptions[0],
  );
  const [offset, setOffset] = useState(
    searchParams.get(SearchParam.page)
      ? (Number(searchParams.get(SearchParam.page)) - 1) * rowsPerPage
      : 0,
  );

  const onPageChange = (newPage: number) => {
    if (newPage === page) {
      return;
    }

    setPage(newPage);
    searchParams.set(SearchParam.page, `${newPage}`);
    setSearchParams(searchParams, { replace: true });
    setOffset((newPage - 1) * rowsPerPage);
  };

  const onRowsPerPageChange = (r: number) => {
    setRowsPerPage(r);
    searchParams.set(SearchParam.page, '1');
    searchParams.set(SearchParam.rowsPerPage, `${r}`);
    setSearchParams(searchParams, { replace: true });
    setPage(1);
  };

  return {
    offset,
    page,
    rowsPerPage,
    onPageChange,
    onRowsPerPageChange,
  };
};
