import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { InferType, mixed, object, string } from 'yup';
import {
  AvailableFor,
  BreastSize,
  BreastType,
  Ethnicity,
  EyeColor,
  HairColor,
  HairLenght,
  Orientation,
  Piercing,
  PubicHair,
  Smoker,
  Travel,
} from '~/api/data-contracts';
import FormAccordion from '~/components/form/FormAccordion';
import {
  AGE_OPTIONS,
  AVAILABLE_FOR_OPTIONS,
  BREAST_SIZE_OPTIONS,
  BREAST_TYPE_OPTIONS,
  ETHNICITY_OPTIONS,
  EYE_COLOR_OPTIONS,
  HAIR_COLOR_OPTIONS,
  HAIR_LENGTH_OPTIONS,
  HEIGHT_OPTIONS,
  ORIENTATION_OPTIONS,
  PIERCING_OPTIONS,
  PRICE_OPTIONS,
  PUBIC_HAIR_OPTIONS,
  SMOKER_OPTIONS,
  TATTOO_OPTIONS,
  TRAVEL_OPTIONS,
  WEIGHT_OPTIONS,
} from '../../consts';
import FormAccordionGroup from '~/components/form/FormAccordionGroup';

export const adFilterSchema = object({
  // adType: mixed<AdType>().oneOf(Object.values(AdType)),
  availableFor: mixed<AvailableFor>().oneOf(Object.values(AvailableFor)),
  breastSize: mixed<BreastSize>().oneOf(Object.values(BreastSize)),
  breastType: mixed<BreastType>().oneOf(Object.values(BreastType)),
  eyeColor: mixed<EyeColor>().oneOf(Object.values(EyeColor)),
  ethnicity: mixed<Ethnicity>().oneOf(Object.values(Ethnicity)),
  hairColor: mixed<HairColor>().oneOf(Object.values(HairColor)),
  hairLenght: mixed<HairLenght>().oneOf(Object.values(HairLenght)),
  // meetingWith: mixed<MeetingWith>().oneOf(Object.values(MeetingWith)),
  orientation: mixed<Orientation>().oneOf(Object.values(Orientation)),
  piercing: mixed<Piercing>().oneOf(Object.values(Piercing)),
  pubicHair: mixed<PubicHair>().oneOf(Object.values(PubicHair)),
  smoker: mixed<Smoker>().oneOf(Object.values(Smoker)),
  travel: mixed<Travel>().oneOf(Object.values(Travel)),
  age: string(),
  height: string(),
  weight: string(),
  tattoo: string(),
  price: object({
    group: string(),
    item: string(),
  }).nullable(),
  // services: array().of(number().required()),
});

export type AdListFilteringFormValue = InferType<typeof adFilterSchema>;

type AdListFilteringFormProps = {
  onCancel: () => void;
  onReset: (value: AdListFilteringFormValue) => void;
  onSubmit: (value: AdListFilteringFormValue) => void;
  value: AdListFilteringFormValue;
};

export default function AdListFilteringForm({
  onCancel,
  onReset,
  onSubmit,
  value,
}: AdListFilteringFormProps) {
  const { t } = useTranslation();

  const { control, handleSubmit } = useForm<AdListFilteringFormValue>({
    defaultValues: value,
    mode: 'onSubmit',
  });

  const handleReset = () => {
    onReset({
      price: null,
    });
  };

  return (
    <form className="flex flex-col h-full bg-primary" onSubmit={handleSubmit(onSubmit)}>
      <div className="flex justify-between p-3.5 bg-secondary">
        <div className="flex flex-1">
          <button className="link" onClick={onCancel} type="button">
            {t('filters.cancel')}
          </button>
        </div>

        <span className="text-white">{t('filters.filterAds')}</span>

        <div className="flex flex-1 justify-end">
          <button className="link" onClick={handleReset} type="button">
            {t('filters.clearFilters')}
          </button>
        </div>
      </div>

      <div className="flex-1 overflow-auto">
        <div className="grid grid-cols-1">
          <FormAccordion control={control} title={t('ads.age')} items={AGE_OPTIONS} name={'age'} />
          <FormAccordion
            control={control}
            title={t('ads.height')}
            items={HEIGHT_OPTIONS}
            name={'height'}
          />
          <FormAccordion
            control={control}
            title={t('ads.weight')}
            items={WEIGHT_OPTIONS}
            name={'weight'}
          />
          <FormAccordion
            control={control}
            title={t('ads.tattoo')}
            items={TATTOO_OPTIONS}
            name={'tattoo'}
          />
          <FormAccordionGroup
            control={control}
            title={t('ads.rates')}
            groups={PRICE_OPTIONS}
            name={'price'}
          />
          {/* <FormAccordion
          control={control}
          title={t('ads.adType')}
          items={AD_TYPE_OPTIONS}
          name={'adType'}
        /> */}
          <FormAccordion
            control={control}
            title={t('ads.availableFor')}
            items={AVAILABLE_FOR_OPTIONS}
            name={'availableFor'}
          />
          <FormAccordion
            control={control}
            title={t('ads.breastSize')}
            items={BREAST_SIZE_OPTIONS}
            name={'breastSize'}
          />
          <FormAccordion
            control={control}
            title={t('ads.breastType')}
            items={BREAST_TYPE_OPTIONS}
            name={'breastType'}
          />
          <FormAccordion
            control={control}
            title={t('ads.eyeColor')}
            items={EYE_COLOR_OPTIONS}
            name={'eyeColor'}
          />
          <FormAccordion
            control={control}
            title={t('ads.ethnicity')}
            items={ETHNICITY_OPTIONS}
            name={'ethnicity'}
          />
          <FormAccordion
            control={control}
            title={t('ads.hairColor')}
            items={HAIR_COLOR_OPTIONS}
            name={'hairColor'}
          />
          <FormAccordion
            control={control}
            title={t('ads.hairLength')}
            items={HAIR_LENGTH_OPTIONS}
            name={'hairLenght'}
          />
          {/* <FormAccordion
          control={control}
          title={t('ads.meetingWith')}
          items={MEETING_WITH_OPTIONS}
          name={'meetingWith'}
        /> */}
          <FormAccordion
            control={control}
            title={t('ads.orientation')}
            items={ORIENTATION_OPTIONS}
            name={'orientation'}
          />
          <FormAccordion
            control={control}
            title={t('ads.piercing')}
            items={PIERCING_OPTIONS}
            name={'piercing'}
          />
          <FormAccordion
            control={control}
            title={t('ads.pubicHair')}
            items={PUBIC_HAIR_OPTIONS}
            name={'pubicHair'}
          />
          <FormAccordion
            control={control}
            title={t('ads.smoker')}
            items={SMOKER_OPTIONS}
            name={'smoker'}
          />
          <FormAccordion
            control={control}
            title={t('ads.travel')}
            items={TRAVEL_OPTIONS}
            name={'travel'}
          />
        </div>
      </div>

      <div className="p-3.5 bg-secondary">
        <button
          className="btn-rounded h-12 w-fit bg-pink transition duration-150 ease-linear hover:bg-[#C60D4F] px-6"
          type="submit"
        >
          {t('filters.applyFilters')}
        </button>
      </div>
    </form>
  );
}
