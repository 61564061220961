import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  AvailableFor,
  BreastSize,
  BreastType,
  Ethnicity,
  EyeColor,
  HairColor,
  HairLenght,
  Orientation,
  Piercing,
  PubicHair,
  Smoker,
  Travel,
  V1AlphaListParams,
} from '~/api/data-contracts';
import { syncParam } from '~/utils/syncParam';
import { tryParseEnum } from '~/utils/tryParseEnum';
import { AdListFilteringFormValue } from '../../components/AdList/AdListFilteringForm';
import {
  AGE_OPTIONS,
  HEIGHT_OPTIONS,
  PRICE_OPTIONS,
  TATTOO_OPTIONS,
  WEIGHT_OPTIONS,
} from '../../consts';
import pickBy from 'lodash.pickby';

enum Param {
  adType = 'f_adType',
  availableFor = 'f_availableFor',
  breastSize = 'f_breastSize',
  breastType = 'f_breastType',
  eyeColor = 'f_eyeColor',
  ethnicity = 'f_ethnicity',
  hairColor = 'f_hairColor',
  hairLenght = 'f_hairLenght',
  meetingWith = 'f_meetingWith',
  orientation = 'f_orientation',
  piercing = 'f_piercing',
  pubicHair = 'f_pubicHair',
  smoker = 'f_smoker',
  travel = 'f_travel',
  age = 'f_age',
  height = 'f_height',
  weight = 'f_weight',
  tattoo = 'f_tattoo',
  price = 'f_price',
  priceTime = 'f_priceTime',
}

export const useAdFilters = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const onSubmit = (value: AdListFilteringFormValue) => {
    setSearchParams((params) => {
      syncParam(params, Param.availableFor, value.availableFor);
      syncParam(params, Param.breastSize, value.breastSize);
      syncParam(params, Param.breastType, value.breastType);
      syncParam(params, Param.eyeColor, value.eyeColor);
      syncParam(params, Param.ethnicity, value.ethnicity);
      syncParam(params, Param.hairColor, value.hairColor);
      syncParam(params, Param.hairLenght, value.hairLenght);
      syncParam(params, Param.orientation, value.orientation);
      syncParam(params, Param.piercing, value.piercing);
      syncParam(params, Param.pubicHair, value.pubicHair);
      syncParam(params, Param.smoker, value.smoker);
      syncParam(params, Param.travel, value.travel);
      syncParam(params, Param.travel, value.travel);

      syncParam(params, Param.age, value.age);
      syncParam(params, Param.height, value.height);
      syncParam(params, Param.weight, value.weight);
      syncParam(params, Param.tattoo, value.tattoo);

      syncParam(params, Param.price, value.price?.item);
      syncParam(params, Param.priceTime, value.price?.group);

      return params;
    });
  };

  const formValue = useMemo((): AdListFilteringFormValue => {
    const price = searchParams.get(Param.price);
    const priceTime = searchParams.get(Param.priceTime);

    return {
      // adType: tryParseEnum(AdType, searchParams.get(Param.adType)),
      availableFor: tryParseEnum(AvailableFor, searchParams.get(Param.availableFor)),
      breastSize: tryParseEnum(BreastSize, searchParams.get(Param.breastSize)),
      breastType: tryParseEnum(BreastType, searchParams.get(Param.breastType)),
      eyeColor: tryParseEnum(EyeColor, searchParams.get(Param.eyeColor)),
      ethnicity: tryParseEnum(Ethnicity, searchParams.get(Param.ethnicity)),
      hairColor: tryParseEnum(HairColor, searchParams.get(Param.hairColor)),
      hairLenght: tryParseEnum(HairLenght, searchParams.get(Param.hairLenght)),
      // meetingWith: tryParseEnum(MeetingWith, searchParams.get(Param.meetingWith)),
      orientation: tryParseEnum(Orientation, searchParams.get(Param.orientation)),
      piercing: tryParseEnum(Piercing, searchParams.get(Param.piercing)),
      pubicHair: tryParseEnum(PubicHair, searchParams.get(Param.pubicHair)),
      smoker: tryParseEnum(Smoker, searchParams.get(Param.smoker)),
      travel: tryParseEnum(Travel, searchParams.get(Param.travel)),
      age: AGE_OPTIONS.find((a) => a.value === searchParams.get(Param.age))?.value,
      height: HEIGHT_OPTIONS.find((h) => h.value === searchParams.get(Param.height))?.value,
      weight: WEIGHT_OPTIONS.find((w) => w.value === searchParams.get(Param.weight))?.value,
      tattoo: TATTOO_OPTIONS.find((t) => t.value === searchParams.get(Param.tattoo))?.value,
      price:
        price && priceTime
          ? {
              item: price,
              group: priceTime,
            }
          : null,
    };
  }, [searchParams]);

  const apiParams = useMemo((): V1AlphaListParams => {
    const ageOption = AGE_OPTIONS.find((a) => a.value === searchParams.get(Param.age));
    const heightOption = HEIGHT_OPTIONS.find((h) => h.value === searchParams.get(Param.height));
    const weightOption = WEIGHT_OPTIONS.find((w) => w.value === searchParams.get(Param.weight));
    const tattooOption = TATTOO_OPTIONS.find((t) => t.value === searchParams.get(Param.tattoo));

    const priceTimeOption = PRICE_OPTIONS.find(
      (p) => p.value === searchParams.get(Param.priceTime),
    );
    const price = priceTimeOption?.items.find((p) => p.value === searchParams.get(Param.price));

    const result: V1AlphaListParams = {
      AvailableFor: tryParseEnum(AvailableFor, searchParams.get(Param.availableFor)),
      BreastSize: tryParseEnum(BreastSize, searchParams.get(Param.breastSize)),
      BreastType: tryParseEnum(BreastType, searchParams.get(Param.breastType)),
      EyeColor: tryParseEnum(EyeColor, searchParams.get(Param.eyeColor)),
      HairColor: tryParseEnum(HairColor, searchParams.get(Param.hairColor)),
      HairLenght: tryParseEnum(HairLenght, searchParams.get(Param.hairLenght)),
      Orientation: tryParseEnum(Orientation, searchParams.get(Param.orientation)),
      Piercing: tryParseEnum(Piercing, searchParams.get(Param.piercing)),
      PubicHair: tryParseEnum(PubicHair, searchParams.get(Param.pubicHair)),
      Smoker: tryParseEnum(Smoker, searchParams.get(Param.smoker)),
      Travel: tryParseEnum(Travel, searchParams.get(Param.travel)),
      AgeFrom: ageOption?.context.min,
      AgeTo: ageOption?.context.max,
      HeightFrom: heightOption?.context.min,
      HeightTo: heightOption?.context.max,
      WeightFrom: weightOption?.context.min,
      WeightTo: weightOption?.context.max,
      Tattoo: tattooOption?.context.value,
      Time: priceTimeOption?.value,
      RateFrom: price?.context.min,
      RateTo: price?.context.max,
    };

    return pickBy(result);
  }, [searchParams]);

  const isAnyFilterSet = useMemo(
    () => Array.from(searchParams.keys()).some((k) => k.startsWith('f_')),
    [searchParams],
  );

  return {
    isAnyFilterSet,
    apiParams,
    formValue,
    onSubmit,
  };
};
