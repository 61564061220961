import { useNavigate, useParams } from 'react-router-dom';
import CreateReviewInternal from '~/features/reviews/components/CreateReview/CreateReviewInternal';
import { ROUTES } from '~/router/Routes';

export default function ReviewCreatePage() {
  const { adId } = useParams();
  const navigate = useNavigate();

  if (!adId) {
    navigate(ROUTES.REVIEWS_LIST);
    return null;
  }

  return <CreateReviewInternal adId={adId} />;
}
