import ReviewList from '../ReviewList/ReviewList';
import { reviewModel } from '~/models/reviewModel/reviewModel';
import { useTranslation } from 'react-i18next';

export default function ReviewsPersonal() {
  const { t } = useTranslation();

  return (
    <ReviewList
      adHref={(id) => `/reviews/${id}/edit`}
      loadFn={reviewModel.reviewsPersonal}
      title={t('reviews.myReviews')}
    />
  );
}
