import { useMutation } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { MODAL_IDS } from '~/config/consts';
import { useAuth } from '~/context/AuthContext';
import { useModals } from '~/context/ModalContext';
import { processFormError } from '~/utils/error';
import { Icon } from '../Icon/Icon';
import FormError from '../form/FormError';
import FormField from '../form/FormField';

type LoginModalFormValues = {
  emailOrUserName: string;
  password: string;
};

export default function LoginModal() {
  const { login } = useAuth();
  const { hideModal, showModal } = useModals();

  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<LoginModalFormValues>({
    defaultValues: {
      emailOrUserName: '',
      password: '',
    },
  });

  const { t } = useTranslation();

  const { mutate, isLoading } = useMutation(login);

  const submit = (values: LoginModalFormValues) => {
    mutate(values, {
      onError: processFormError(setError),
      onSuccess: () => hideModal(),
    });
  };

  const handleForgotClick = () => {
    showModal(MODAL_IDS.passwordResetModal);
  };

  const handleRegisterClick = () => {
    showModal(MODAL_IDS.registerModal);
  };

  return (
    <div
      className="relative max-h-[90vh] w-full overflow-auto rounded-lg bg-primary text-center"
      role="dialog"
      aria-modal="true"
    >
      <header className="flex w-full flex-1 items-center justify-center py-7">
        <span className="text-xl font-semibold text-white">{t('loginModal.title')}</span>
      </header>

      <div className="flex flex-row items-center gap-2.5 bg-red-600 text-white px-7 py-3.5">
        <Icon alt="user" icon="icon-megaphone" className="h-10 w-10 text-white" />
        <p className="text-white">
          {t('loginModal.returning')}{' '}
          <button className="underline" onClick={handleForgotClick}>
            {t('loginModal.reset')}
          </button>
        </p>
      </div>

      <form className="flex flex-col gap-[10px] p-7" onSubmit={handleSubmit(submit)}>
        <FormField
          control={control}
          name="emailOrUserName"
          placeholder={t('loginModal.usernameOrEmail')}
          type="text"
          icon="icon-user-filled"
        />
        <FormField
          control={control}
          name="password"
          placeholder={t('loginModal.password')}
          type="password"
          icon="icon-solid-closed"
        />
        <FormError error={errors.root} />
        <div>
          <button
            className="btn-rounded mt-[6px] border-0 bg-pink font-semibold transition duration-150 ease-linear hover:bg-[#C60D4F]"
            type="submit"
            disabled={isLoading}
          >
            {t('loginModal.actionLogIn')}
          </button>
        </div>
        <div className="inline-flex justify-center">
          <button type="button" onClick={handleForgotClick} className="link pt-5">
            {t('loginModal.forgot')}
          </button>
        </div>
      </form>
      <div className="sepator bg-secondary"></div>
      <section className="flex flex-col gap-2 p-7 text-sm md:flex-row">
        <span className="inline-flex justify-center gap-1 text-sm text-[#999999]">
          {t('loginModal.notMember')}
          <button type="button" onClick={handleRegisterClick} className="link">
            {t('loginModal.register')}
          </button>
        </span>
      </section>
    </div>
  );
}
