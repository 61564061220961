import { toast } from 'material-react-toastify';
import { useEffect, useState } from 'react';
import { RateTime } from '~/api/data-contracts';
import { Loader } from '~/components/Loader/Loader';
import { adModel } from '~/models/adModel/adModel';
import { getAdImageUrl, prepareFormDataForImages } from '../../utils/images';
import AdForm from '../AdForm/AdForm';
import { AdFormValues } from '../AdForm/adSchema';
import { useTranslation } from 'react-i18next';

type EditAdProps = {
  id: string;
};

export default function EditAd(props: EditAdProps) {
  const { t } = useTranslation();
  const [defaultValues, setDefaultValues] = useState<AdFormValues | null>(null);

  const loadAd = async () => {
    const ad = await adModel.personalAd(props.id);

    setDefaultValues({
      status: ad.status,
      adType: ad.type,
      age: ad.age,
      availableFor: ad.availableFor,
      breastSize: ad.breastSize,
      breastType: ad.breastType,
      countryNo: ad.location.countryNo || 0,
      description: ad.description,
      dickSize: ad.dickSize,
      ethnicity: ad.ethnicity,
      eyeColor: ad.eyeColor,
      hairColor: ad.hairColor,
      hairLenght: ad.hairLenght,
      height: ad.height,
      isPornStar: ad.isPornStar,
      locationNo: ad.location.townNo || 0,
      meetingWith: ad.meetingWith,
      name: ad.name,
      nationalityNo: ad.nationality.nationalityNo || 0,
      orientation: ad.orientation,
      piercing: ad.piercing,
      pubicHair: ad.pubicHair,
      smoker: ad.smoker,
      tattoo: ad.tattoo,
      travel: ad.travel,
      weight: ad.weight,
      currencyNo: ad.currency.no || 0,
      languages: ad.languages.map((language) => language.no!),
      files: ad.images.map((file) => ({
        file,
        url: getAdImageUrl(props.id, file),
      })),
      services: ad.services.map((service) => ({
        serviceNo: service.serviceNo || 0,
        price: service.price,
      })),
      rates: ad.rates.map((rate) => ({
        incall: rate.incall,
        outcall: rate.outcall,
        time: rate.rateTime || RateTime.HalfAHour,
      })),
      phones: ad.phones.length
        ? ad.phones.map((phone) => ({
            countryNo: phone.countryNo || 0,
            phoneNo: phone.phoneNo || 0,
            isLine: phone.isLine || false,
            isTelegram: phone.isTelegram || false,
            isWhatsApp: phone.isWhatsApp || false,
            isWeChat: phone.isWeChat || false,
            isViber: phone.isViber || false,
            isSignal: phone.isSignal || false,
            number: phone.number || '',
          }))
        : [
            {
              countryNo: 0,
              isTelegram: false,
              isWhatsApp: false,
              isWeChat: false,
              isViber: false,
              isLine: false,
              isSignal: false,
              number: '',
            },
          ],
    });
  };

  useEffect(() => {
    loadAd();
  }, []);

  const handleSubmit = async (values: AdFormValues) => {
    try {
      await adModel.editAdBase(props.id, {
        adType: values.adType,
        age: values.age,
        availableFor: values.availableFor,
        breastSize: values.breastSize,
        breastType: values.breastType,
        currencyNo: values.currencyNo,
        description: values.description,
        dickSize: values.dickSize,
        ethnicity: values.ethnicity,
        eyeColor: values.eyeColor,
        hairColor: values.hairColor,
        hairLenght: values.hairLenght,
        height: values.height,
        isPornStar: values.isPornStar,
        locationNo: values.locationNo,
        meetingWith: values.meetingWith,
        name: values.name,
        nationalityNo: values.nationalityNo,
        orientation: values.orientation,
        piercing: values.piercing,
        pubicHair: values.pubicHair,
        smoker: values.smoker,
        travel: values.travel,
        weight: values.weight,
        tattoo: values.tattoo,
        languages: values.languages,
        services: values.services,
        rates: values.rates,
        phones: values.phones,
      });
      await adModel.editAdImages(props.id, prepareFormDataForImages(values.files));

      await loadAd();
      toast.success(t('ads.editAdSuccess'));
    } catch (e) {
      toast.error(t('general.error'));
    }
  };

  if (!defaultValues) {
    return <Loader />;
  }

  return (
    <AdForm
      id={props.id}
      title={t('ads.editAd')}
      defaultValues={defaultValues}
      onSubmit={handleSubmit}
      refetch={loadAd}
    />
  );
}
