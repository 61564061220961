import classNames from 'classnames';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useSearchParams } from 'react-router-dom';
import { DropdownConfig } from '~/components/Dropdown/DropdownConfig';
import { Icon } from '~/components/Icon/Icon';
import { MODAL_IDS } from '~/config/consts';
import { useAuth } from '~/context/AuthContext';
import { useModals } from '~/context/ModalContext';

export function CreditsDropdown() {
  const dropdownElementRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<DropdownConfig | null>(null);

  const { isAuthenticated, user } = useAuth();
  const { showModal } = useModals();

  const { t } = useTranslation();

  useEffect(() => {
    if (dropdownElementRef.current) {
      dropdownRef.current = new DropdownConfig(dropdownElementRef.current);
    }
  }, [dropdownElementRef]);

  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (dropdownRef.current) {
      dropdownRef.current.onlyClose();
    }
  }, [pathname, searchParams]);

  return (
    <div className="dropdown relative z-[999]" data-profile="true" ref={dropdownElementRef}>
      <button className="dropdown-toggle flex h-16 w-auto items-center justify-center gap-2 px-[20px] rounded-lg">
        <Icon alt="Wallet" icon="icon-coins" className="text-white w-6 h-6" />
        <div className="flex flex-col">
          <span className="text-pink leading-4">
            {user ? user.balance.balance.toFixed(2) : '-'}
          </span>
          <span className="text-white text-sm leading-4">{t('credits.credits')}</span>
        </div>
      </button>

      <div className="dropdown-content absolute top-14 right-0 hidden w-48">
        <div className="shadow-filter flex w-full w-60 flex-col overflow-hidden rounded-lg bg-primary">
          <div className="inline-flex h-11 w-full items-center bg-primary px-5 text-sm font-medium text-[#999999]">
            {t('credits.credits')}
          </div>
          <div className="flex-1 bg-secondary py-2.5">
            <button
              className={classNames(
                'flex flex-1 items-center py-2.5 px-5 text-sm font-medium text-[#999999] transition duration-150 ease-linear hover:bg-third hover:text-white',
                {
                  'visually-hidden': !isAuthenticated,
                },
              )}
              onClick={() => showModal(MODAL_IDS.buyCredits)}
            >
              {t('credits.buyCredits')}
            </button>
            <button
              className={classNames(
                'flex flex-1 items-center py-2.5 px-5 text-sm font-medium text-[#999999] transition duration-150 ease-linear hover:bg-third hover:text-white',
                {
                  'visually-hidden': !isAuthenticated,
                },
              )}
              onClick={() => showModal(MODAL_IDS.transferCredits)}
            >
              {t('credits.sendCredits')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
