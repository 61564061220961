import { useNavigate, useParams } from 'react-router-dom';

import ReviewDetails from '~/features/reviews/components/ReviewDetails/ReviewDetails';
import { ROUTES } from '~/router/Routes';

export default function ReviewDetailsPage() {
  const { id } = useParams();
  const navigate = useNavigate();

  if (!id) {
    navigate(ROUTES.REVIEWS_LIST);
    return null;
  }

  return <ReviewDetails id={id} />;
}
