export const Tracker = () => {
  const _hey_lt_w = screen.width;
  const _hey_lt_h = screen.height;
  const _hey_lt_c =
    navigator.appName.indexOf('Microsoft') >= 0 ? screen.colorDepth : screen.pixelDepth;
  return (
    <div className="flex justify-center">
      <a target="_blank" rel="noreferrer" href="http://www.hey.lt/details.php?id=wonderland">
        <img
          width="88"
          height="31"
          src={`http://www.hey.lt/count.php?id=wonderland&width=" + ${_hey_lt_w} + "&height=" + ${_hey_lt_h} + "&color=" + ${_hey_lt_c} + "&referer=" + escape(document.referrer) + "`}
          alt="Hey.lt - Nemokamas lankytojų skaitliukas"
        />
      </a>
    </div>
  );
};
