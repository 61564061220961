import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from '@tanstack/react-query';
import { toast } from 'material-react-toastify';
import { useForm } from 'react-hook-form';
import { object, string } from 'yup';
import FormError from '~/components/form/FormError';
import FormField from '~/components/form/FormField';
import { useAuth } from '~/context/AuthContext';
import { usersModel } from '~/models/usersModel/usersModel';
import { processFormError } from '~/utils/error';
import { useTranslation } from 'react-i18next';

type ActivationFormValues = {
  keyId: string;
};

const defaultValues: ActivationFormValues = {
  keyId: '',
};

const activationSchema = object({
  keyId: string().required('Required'),
});

export default function UserSettingsActivation() {
  const { fetchUser } = useAuth();

  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<ActivationFormValues>({
    defaultValues,
    resolver: yupResolver<ActivationFormValues>(activationSchema),
  });
  const { mutate, isLoading } = useMutation(usersModel.userActivate);
  const { t } = useTranslation();

  const onSubmit = (values: ActivationFormValues) => {
    mutate(values.keyId, {
      onError: processFormError(setError),
      onSuccess: () => {
        toast.success('Activated successfully');
        fetchUser();
      },
    });
  };

  return (
    <form
      className="flex w-full flex-col divide-y-[1px] divide-secondary overflow-hidden rounded-lg bg-primary"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="w-full py-3.5 text-center text-xs font-medium text-[#999999]">
        {t('userProfile.activateAccount')}
      </div>
      <div className="flex flex-col items-center gap-5 p-7 lg:p-14">
        <div className="w-full space-y-2">
          <FormField
            control={control}
            name="keyId"
            placeholder={t('userProfile.enterYourCode')}
            icon="icon-solid-closed"
          />
        </div>
        <FormError error={errors.root} />
        <button
          disabled={isLoading}
          type="submit"
          className="btn-rounded border-transparent bg-[#0E9B57] transition duration-150 ease-linear hover:bg-[#0C844A]"
        >
          {t('userProfile.activate')}
        </button>
      </div>
    </form>
  );
}
