import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

type ConfirmFormProps = {
  message: string;
  formId: string;
  onSubmit: () => void;
};

export default function ConfirmForm({ onSubmit, formId, message }: ConfirmFormProps) {
  const { t } = useTranslation();

  const { handleSubmit } = useForm({
    shouldFocusError: true,
  });

  return (
    <form id={formId} onSubmit={handleSubmit(onSubmit)}>
      <div className="w-full p-3.5 text-center text-2xl text-white">{t('twoFactor.step3')}</div>

      <p className="w-full mb-3.5 text-center text-gray">{message}</p>
    </form>
  );
}
