import classNames from 'classnames';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import { UserType } from '~/api/data-contracts';
import { DropdownConfig } from '~/components/Dropdown/DropdownConfig';
import { Icon } from '~/components/Icon/Icon';
import { useAuth } from '~/context/AuthContext';
import { ROUTES } from '~/router/Routes';

export function UserDropdown() {
  const dropdownElementRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<DropdownConfig | null>(null);

  const { hasOneOfRoles, isAuthenticated, user, logout } = useAuth();

  const { t } = useTranslation();

  useEffect(() => {
    if (dropdownElementRef.current) {
      dropdownRef.current = new DropdownConfig(dropdownElementRef.current);
    }
  }, [dropdownElementRef]);

  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (dropdownRef.current) {
      dropdownRef.current.onlyClose();
    }
  }, [pathname, searchParams]);

  return (
    <div className="dropdown relative z-[999]" data-profile="true" ref={dropdownElementRef}>
      <button className="dropdown-toggle inline-flex h-9 items-center rounded-lg border-[1px] border-[#272727] px-5 text-white">
        <span className="text-sm font-medium">{user?.nickName}</span>
        <Icon
          alt="caret"
          icon="icon-caret"
          id="caret"
          className="ml-5 h-2.5 w-2.5 text-[#999999] dropdown-caret"
        />
      </button>
      <div className="dropdown-content absolute top-10 right-0 hidden w-48">
        <div className="shadow-filter flex w-full w-60 flex-col overflow-hidden rounded-lg bg-primary">
          <div className="inline-flex h-11 w-full items-center bg-primary px-5 text-sm font-medium text-[#999999]">
            {t('header.settings')}
          </div>
          <div className="flex-1 bg-secondary py-2.5">
            <Link
              to={ROUTES.USER_PROFILE}
              className={classNames(
                'flex flex-1 items-center py-2.5 px-5 text-sm font-medium text-[#999999] transition duration-150 ease-linear hover:bg-third hover:text-white',
                {
                  'visually-hidden': !isAuthenticated,
                },
              )}
            >
              {t('header.userProfile')}
            </Link>
            <Link
              to={ROUTES.USER_SETTINGS}
              className={classNames(
                'flex flex-1 items-center py-2.5 px-5 text-sm font-medium text-[#999999] transition duration-150 ease-linear hover:bg-third hover:text-white',
                {
                  'visually-hidden': !isAuthenticated,
                },
              )}
            >
              {t('header.userSettings')}
            </Link>
          </div>
          <div className="inline-flex h-11 w-full items-center bg-primary px-5 text-sm font-medium text-[#999999]">
            {t('header.content')}
          </div>
          <div className="flex-1 bg-secondary py-2.5">
            {hasOneOfRoles([UserType.Reviewer]) && (
              <Link
                to={ROUTES.REVIEW_LIST_PERSONAL}
                className="flex flex-1 items-center py-2.5 px-5 text-sm font-medium text-[#999999] transition duration-150 ease-linear hover:bg-third hover:text-white"
              >
                {t('reviews.myReviews')}
              </Link>
            )}

            {hasOneOfRoles([UserType.Agency, UserType.Independent]) && (
              <Link
                to={ROUTES.AD_LIST_PERSONAL}
                className="flex flex-1 items-center py-2.5 px-5 text-sm font-medium text-[#999999] transition duration-150 ease-linear hover:bg-third hover:text-white"
              >
                {t('ads.myAds')}
              </Link>
            )}

            {/* <a
            href="#"
            className="flex flex-1 items-center py-2.5 px-5 text-sm font-medium text-[#999999] transition duration-150 ease-linear hover:bg-third hover:text-white"
          >
            Followed ads
          </a>
          <a
            href="#"
            className="flex flex-1 items-center py-2.5 px-5 text-sm font-medium text-[#999999] transition duration-150 ease-linear hover:bg-third hover:text-white"
          >
            Followed reviews
          </a> */}
          </div>
          <div className="flex-1 border-t-[1px] border-primary bg-secondary py-2.5">
            <button
              className="flex flex-1 items-center py-2.5 px-5 text-sm font-medium text-[#999999] transition duration-150 ease-linear hover:bg-third hover:text-white"
              onClick={() => logout()}
            >
              {t('header.logOut')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
