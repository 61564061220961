import { ImageType } from '~/types/sharedTypes';

export const getAdImageUrl = (id: string, fileName: string) =>
  `${process.env.REACT_APP_API_ROOT}/images/ads/${id}/${fileName}`;

export const prepareFormDataForImages = (files: ImageType[]) => {
  const formData = new FormData();

  files.forEach(({ file }) => {
    const key = file instanceof File ? 'files' : 'existingFileNames';

    formData.append(key, file);
  });

  return formData;
};
