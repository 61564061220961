import { useMutation } from '@tanstack/react-query';
import { toast } from 'material-react-toastify';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { UserType } from '~/api/data-contracts';
import FormRadio from '~/components/form/FormRadio';
import { MODAL_IDS } from '~/config/consts';
import { useModals } from '~/context/ModalContext';
import { authModel } from '~/models/authModel/authModel';
import { processFormError } from '~/utils/error';
import FormError from '../form/FormError';
import FormField from '../form/FormField';

type RegisterModalFormValues = {
  email: string;
  password: string;
  repeatedPassword: string;
  userName: string;
  userType: UserType;
};

export default function RegisterModal() {
  // const [agreedToTerms, setAgreedToTerms] = useState(false);
  const { t } = useTranslation();
  const { showModal } = useModals();

  const {
    control,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm<RegisterModalFormValues>({
    defaultValues: {
      email: '',
      password: '',
      repeatedPassword: '',
      userName: '',
      userType: UserType.Reviewer,
    },
  });

  const { mutate, isLoading } = useMutation(authModel.register, {
    onError: processFormError(setError),
  });

  const submit = async (values: RegisterModalFormValues) => {
    mutate(values, {
      onError: processFormError(setError),
      onSuccess: () => {
        toast.success(t('registerModal.registerSuccess'));
        showModal(MODAL_IDS.loginModal);
      },
    });
  };

  const handleLogInClick = () => {
    showModal(MODAL_IDS.loginModal);
  };

  return (
    <div
      className="max-h-[90vh] w-full overflow-auto rounded-lg bg-primary"
      role="dialog"
      aria-modal="true"
    >
      <header className="flex w-full flex-1 items-center justify-center py-7">
        <span className="text-xl font-semibold text-white">{t('registerModal.title')}</span>
      </header>

      <form className="flex flex-col gap-[10px] p-7" onSubmit={handleSubmit(submit)}>
        <FormField
          control={control}
          name="userName"
          placeholder={t('registerModal.username')}
          type="text"
          icon="icon-user-filled"
        />

        <FormField
          control={control}
          name="email"
          placeholder={t('registerModal.email')}
          type="text"
          icon="icon-email"
        />

        <FormField
          control={control}
          name="password"
          placeholder={t('registerModal.password')}
          type="password"
          icon="icon-solid-closed"
        />

        <FormField
          control={control}
          name="repeatedPassword"
          placeholder={t('registerModal.repeatPassword')}
          type="password"
          icon="icon-solid-closed"
        />

        <FormRadio
          name="userType"
          label={t('registerModal.userTypeTitle')}
          control={control}
          options={[
            {
              // onHintClick: () => {
              //   MicroModal.close(MODAL_IDS.registerModal);
              //   MicroModal.show(MODAL_IDS.reviewerUserModal);
              // },
              label: t('registerModal.answerReviewer'),
              value: UserType.Reviewer,
            },
            {
              // onHintClick: () => {
              //   MicroModal.close(MODAL_IDS.registerModal);
              //   MicroModal.show(MODAL_IDS.agencyModal);
              // },
              label: t('registerModal.answerAgency'),
              value: UserType.Agency,
            },
            {
              // onHintClick: () => {
              //   MicroModal.close(MODAL_IDS.registerModal);
              //   MicroModal.show(MODAL_IDS.independentUserModal);
              // },
              label: t('registerModal.answerIndependent'),
              value: UserType.Independent,
            },
          ]}
        />
        <FormError error={errors.root} />
        {/* <div className="inline-flex items-center gap-2.5">
              <label className="switch inline-flex flex-shrink-0 gap-2">
                <input type="checkbox" onChange={(e) => setAgreedToTerms(e.target.checked)}/>
                <span className="slider round"></span>
              </label>
              <span className="pt-3 text-sm text-[#999999]">
                Sutinku su svetainės{' '}
                <a href="#" className="link pt-5">
                  taisyklėmis
                </a>{' '}
                ir{' '}
                <a href="#" className="link pt-5">
                  privatumo politika
                </a>
              </span>
            </div> */}
        <div>
          <button
            type="submit"
            className="btn-rounded mt-[6px] border-0 bg-pink font-semibold transition duration-150 ease-linear hover:bg-[#C60D4F]"
            disabled={isLoading}
          >
            {t('registerModal.actionRegister')}
          </button>
        </div>
      </form>

      <section className="flex gap-2 p-7 text-sm md:flex-row">
        <span className="text-sm text-[#999999]">
          {t('registerModal.alreadyMember')}{' '}
          <button className="link" onClick={handleLogInClick}>
            {t('registerModal.logIn')}
          </button>
        </span>
      </section>
    </div>
  );
}
