import { Controller, FieldPath, FieldValues, UseControllerProps } from 'react-hook-form';
import ImageUpload from '../ImageUpload/ImageUpload';
import FormFieldArrayError from './FormFieldArrayError';

type FormSelectProps<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = UseControllerProps<TFieldValues, TName>;

const FormFiles = <
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  control,
  name,
}: FormSelectProps<TFieldValues, TName>) => {
  return (
    <Controller
      render={({ field: { onChange, value, ref }, fieldState }) => (
        <div tabIndex={-1} ref={ref}>
          <ImageUpload onChange={onChange} files={value} />
          <FormFieldArrayError error={fieldState.error} />
        </div>
      )}
      name={name}
      control={control}
    />
  );
};

export default FormFiles;
