import classNames from 'classnames';
import { InputHTMLAttributes, ReactNode } from 'react';
import { Controller, FieldPath, FieldValues, UseControllerProps } from 'react-hook-form';
import FormFieldError from './FormFieldError';
import { Icon } from '../Icon/Icon';

type FormFieldProps<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = UseControllerProps<TFieldValues, TName> & {
  onChange?: (value: string) => void;
  label?: string;
  suffix?: ReactNode;
  icon?: string;
} & Omit<InputHTMLAttributes<HTMLInputElement>, 'value' | 'onChange'>;

const FormField = <
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  control,
  name,
  label,
  suffix,
  icon,
  onChange,
  ...inputProps
}: FormFieldProps<TFieldValues, TName>) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => {
        return (
          <div>
            {label && (
              <label htmlFor={name} className="text-[#999999] text-sm pl-5">
                {label}
              </label>
            )}
            <div className="flex flex-row gap-3.5 items-center relative">
              <input
                id={name}
                type="text"
                className={classNames(
                  'form-control-input block w-full rounded-lg border-transparent border-[#272727] bg-secondary px-[20px] py-[17px] text-white placeholder:text-[#999999] focus:border-transparent',
                  {
                    'pr-[50px]': suffix,
                    'pl-[50px]': icon,
                    '!border-red-500': fieldState.error,
                  },
                )}
                value={field.value}
                onChange={(e) => {
                  field.onChange(e.target.value);
                  onChange && onChange(e.target.value);
                }}
                ref={field.ref}
                {...inputProps}
              />
              {icon && (
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-[20px]">
                  <Icon alt={label || ''} icon={icon} className="h-5 w-5 text-[#999999]" />
                </div>
              )}
              {suffix && <span className="text-white absolute right-3.5">{suffix}</span>}
            </div>
            {fieldState.error && <FormFieldError error={fieldState.error} />}
          </div>
        );
      }}
    />
  );
};

export default FormField;
