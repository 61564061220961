import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { Loader } from '~/components/Loader/Loader';
import { MODAL_IDS } from '~/config/consts';
import { useAuth } from '~/context/AuthContext';
import { useModals } from '~/context/ModalContext';
import PublicReviews from '~/features/public-reviews/components/PublicReviews/PublicReviews';
import ReviewButton from '~/features/reviews/components/ReviewButton/ReviewButton';
import { reviewModel } from '~/models/reviewModel/reviewModel';
import { useTranslation } from 'react-i18next';

export default function Home() {
  const { data, isFetching } = useQuery(['reviews-public'], reviewModel.reviewsPublic);
  const { isAuthenticated, getHomePath } = useAuth();
  const navigate = useNavigate();
  const { showModal } = useModals();
  const { t } = useTranslation();

  const reviews = data?.items || [];

  const handleClick = () => {
    showModal(MODAL_IDS.warningModal);
  };

  const getContent = () => {
    if (isFetching) {
      return <Loader />;
    }

    if (!reviews.length) {
      return <p className="text-white">{t('general.noMatchingResults')}</p>;
    }

    return reviews.map((review) => (
      <ReviewButton key={review.id} review={review} onClick={handleClick} />
    ));
  };

  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }

    return navigate(getHomePath());
  }, [isAuthenticated]);

  return (
    <div className="relative flex flex-1 flex-col gap-14 py-14 px-2.5 lg:px-14 xl:gap-16">
      <div className="inline-flex items-center justify-between">
        <span className="text-lg font-medium text-white lg:text-2xl">
          {t('reviews.recentReview')}
        </span>
      </div>
      <div className="grid grid-cols-2 gap-6 sm:grid-cols-3 lg:grid-cols-4 2xl:grid-cols-6">
        {getContent()}
      </div>

      <PublicReviews />
    </div>
  );
}
