import { Link } from 'react-router-dom';
import { GetPersonalReviewsQueryResult, GetReviewsQueryResult } from '~/api/data-contracts';
import Gradient from '~/components/Gradient/Gradient';
import { Icon } from '~/components/Icon/Icon';
import { getReviewImageUrl } from '../../utils/images';

type ReviewsButtonProps = {
  adHref?: string;
  review: GetReviewsQueryResult | GetPersonalReviewsQueryResult;
  onClick?: () => void;
};

export default function ReviewButton({ review, adHref, onClick }: ReviewsButtonProps) {
  const { id, image, town } = review;

  if (!id) {
    return null;
  }

  const content = (
    <>
      <div className="relative aspect-w-5 aspect-h-7 overflow-hidden 2xl:aspect-h-6">
        <img
          alt="Profile Picture"
          src={image ? getReviewImageUrl(id, image) : '/assets/images/image-placeholder.jpg'}
          className="object-cover h-full w-full transform group-hover:scale-110 transition duration-150 ease-linear backface-hidden"
        />
        {/* {{#if flower/>
        <div className="absolute top-1.5 left-1.5 bg-primary rounded-full w-6 h-6 flex justify-center items-center md:h-8 md:w-8 backface-hidden">
            <Icon alt="Flower" icon="icon-flower" className="w-3 h-3 md:w-4 md:h-4"/>
        </div>
        {{/if/> */}
        <div className="absolute bottom-0 w-full h-full inline-flex items-end backface-hidden">
          <div className="flex flex-1 justify-between items-center px-4 py-1.5 text-xs text-white like-gradient">
            {'likes' in review && (
              <div className="inline-flex items-center gap-1">
                <Icon alt="Like" icon="icon-like" className="w-4 h-4" />
                <span>{review.likes}</span>
              </div>
            )}
            {'dislikes' in review && (
              <div className="inline-flex items-center gap-1">
                <Icon alt="Dislike" icon="icon-dislike" className="w-4 h-4" />
                <span>{review.dislikes}</span>
              </div>
            )}
          </div>
        </div>
      </div>
      {'average' in review && <Gradient likes={review.likes} dislikes={review.dislikes} />}
      <div className="flex flex-shrink-0 items-center justify-between h-11 bg-primary px-3 backface-hidden">
        <div className="inline-flex justify-center items-center text-white gap-1 text-xs backface-hidden">
          <Icon
            alt="location"
            icon="icon-location"
            className="w-4 h-4 text-[#999999] group-hover:text-white transition duration-150 ease-linear backface-hidden"
          />
          {town}
        </div>
        {'average' in review && (
          <div className="inline-flex justify-center items-center text-white gap-1 text-xs backface-hidden">
            <Icon
              alt="location"
              icon="icon-star"
              className="w-4 h-4 text-[#999999] group-hover:text-white transition duration-150 ease-linear backface-hidden"
            />
            {review.average}
          </div>
        )}
      </div>
    </>
  );

  if (adHref) {
    return (
      <Link
        to={adHref}
        className="rounded-lg overflow-hidden group transform hover:-translate-y-1 transition duration-150 ease-linear backface-hidden"
      >
        {content}
      </Link>
    );
  }

  return (
    <button
      className="rounded-lg overflow-hidden group transform hover:-translate-y-1 transition duration-150 ease-linear backface-hidden"
      onClick={onClick}
    >
      {content}
    </button>
  );
}
