import { InferType, ObjectSchema, array, boolean, lazy, mixed, number, object, string } from 'yup';
import {
  AdStatus,
  AdType,
  AvailableFor,
  BreastSize,
  BreastType,
  Ethnicity,
  EyeColor,
  HairColor,
  HairLenght,
  MeetingWith,
  Orientation,
  Piercing,
  PubicHair,
  RateTime,
  Smoker,
  Travel,
} from '~/api/data-contracts';
import i18n from '~/i18n';
import { ImageType } from '~/types/sharedTypes';

const requiredMessage = i18n.t('validation.required');

const fileSchema: ObjectSchema<ImageType> = object().shape({
  file: lazy((value) => {
    if (value instanceof File) {
      return mixed<File>().required(requiredMessage);
    }

    return string().required(requiredMessage);
  }),
  url: string().required(requiredMessage),
});

const serviceSchema = object({
  serviceNo: number().required(requiredMessage).moreThan(0, requiredMessage),
  price: number().nullable(),
});

const rateSchema = object().shape(
  {
    time: mixed<RateTime>().oneOf(Object.values(RateTime)).required(requiredMessage),
    incall: number()
      .nullable()
      .when('outcall', {
        is: null,
        then: (schema) => schema.required(requiredMessage),
      }),
    outcall: number()
      .nullable()
      .when('incall', {
        is: null,
        then: (schema) => schema.required(requiredMessage),
      }),
  },
  [['incall', 'outcall']],
);

const phoneSchema = object({
  number: string().required(requiredMessage),
  countryNo: number().required(requiredMessage).moreThan(0, requiredMessage),
  isTelegram: boolean(),
  isWhatsApp: boolean(),
  isWeChat: boolean(),
  isViber: boolean(),
  isLine: boolean(),
  isSignal: boolean(),
});

export const adSchema = object({
  status: mixed<AdStatus>().nullable().oneOf(Object.values(AdStatus)),
  countryNo: number().moreThan(0, requiredMessage),

  name: string().required(requiredMessage),
  description: string().required(requiredMessage),

  adType: mixed<AdType>().required(requiredMessage).oneOf(Object.values(AdType)),
  availableFor: mixed<AvailableFor>().required(requiredMessage).oneOf(Object.values(AvailableFor)),
  breastSize: mixed<BreastSize>()
    .oneOf(Object.values(BreastSize))
    .when('adType', {
      is: (adType: AdType) => adType === AdType.Female || adType === AdType.Trans,
      then: (schema) => schema.required(requiredMessage),
    }),
  breastType: mixed<BreastType>()
    .oneOf(Object.values(BreastType))
    .when('adType', {
      is: (adType: AdType) => adType === AdType.Female || adType === AdType.Trans,
      then: (schema) => schema.required(requiredMessage),
    }),
  eyeColor: mixed<EyeColor>().required(requiredMessage).oneOf(Object.values(EyeColor)),
  ethnicity: mixed<Ethnicity>().required(requiredMessage).oneOf(Object.values(Ethnicity)),
  hairColor: mixed<HairColor>().required(requiredMessage).oneOf(Object.values(HairColor)),
  hairLenght: mixed<HairLenght>().required(requiredMessage).oneOf(Object.values(HairLenght)),
  meetingWith: mixed<MeetingWith>().required(requiredMessage).oneOf(Object.values(MeetingWith)),
  orientation: mixed<Orientation>().required(requiredMessage).oneOf(Object.values(Orientation)),
  piercing: mixed<Piercing>().required(requiredMessage).oneOf(Object.values(Piercing)),
  pubicHair: mixed<PubicHair>().required(requiredMessage).oneOf(Object.values(PubicHair)),
  smoker: mixed<Smoker>().required(requiredMessage).oneOf(Object.values(Smoker)),
  travel: mixed<Travel>().required(requiredMessage).oneOf(Object.values(Travel)),

  age: number()
    .min(18, i18n.t('validation.minAge', { age: 18 }))
    .max(80, i18n.t('validation.maxAge', { age: 80 }))
    .required(requiredMessage),
  height: number()
    .min(100, i18n.t('validation.minHeight', { height: 100 }))
    .max(210, i18n.t('validation.maxHeight', { height: 210 }))
    .required(requiredMessage),
  weight: number()
    .min(20, i18n.t('validation.minWeight', { weight: 20 }))
    .max(150, i18n.t('validation.maxWeight', { weight: 150 }))
    .required(requiredMessage),
  isPornStar: boolean(),
  dickSize: number().nullable(),
  tattoo: boolean(),

  nationalityNo: number().required().moreThan(0, requiredMessage),
  locationNo: number().required(requiredMessage).moreThan(0, requiredMessage),
  currencyNo: number().required(requiredMessage).moreThan(0, requiredMessage),

  languages: array()
    .of(number().required(requiredMessage))
    .required()
    .min(1, i18n.t('validation.oneLanguage')),
  files: array().of(fileSchema).required(requiredMessage).min(1, i18n.t('validation.oneImage')),
  services: array()
    .of(serviceSchema)
    .required(requiredMessage)
    .min(1, i18n.t('validation.oneService')),
  rates: array().of(rateSchema).required().min(1, i18n.t('validation.oneRate')),
  phones: array().of(phoneSchema).required().min(1, i18n.t('validation.onePhone')),
});

export type AdFormValues = InferType<typeof adSchema>;
