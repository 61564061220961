import { useNavigate, useParams } from 'react-router-dom';
import ResetPassword from '~/features/auth/components/ResetPassword/ResetPassword';
import { ROUTES } from '~/router/Routes';

export default function ResetPasswordPage() {
  const { id } = useParams();
  const navigate = useNavigate();

  if (!id) {
    navigate(ROUTES.DEFAULT);
    return null;
  }

  return <ResetPassword id={id} />;
}
