import { Controller, FieldPath, FieldValues, UseControllerProps } from 'react-hook-form';
import RadioGroup, { RadioGroupProps } from '../RadioGroup/RadioGroup';
import FormFieldError from './FormFieldError';

type FormRadioAltProps<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = UseControllerProps<TFieldValues, TName> & Omit<RadioGroupProps, 'value'>;

const FormRadioAlt = <
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  control,
  name,
  ...accordionProps
}: FormRadioAltProps<TFieldValues, TName>) => {
  return (
    <Controller
      render={({ field, fieldState }) => (
        <div tabIndex={-1} ref={field.ref}>
          <RadioGroup onChange={field.onChange} value={field.value} {...accordionProps} />
          <FormFieldError error={fieldState.error} />
        </div>
      )}
      name={name}
      control={control}
    />
  );
};

export default FormRadioAlt;
