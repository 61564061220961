import { useTranslation } from 'react-i18next';
import { useAuth } from '~/context/AuthContext';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'material-react-toastify';
import PublicReviewForm, { PublicReviewFormValues } from '../PublicReviewForm/PublicReviewForm';
import PublicReviewList from '../PublicReviewList/PublicReviewList';
import { publicReviewsModel } from '~/models/publicReviewsModel/publicReviewsModel';

export default function PublicReviews() {
  const { t } = useTranslation();
  const { isAuthenticated, user, fetchUser } = useAuth();
  const queryClient = useQueryClient();

  const { mutate: createReview, isLoading } = useMutation(
    ({ text }: PublicReviewFormValues) => publicReviewsModel.createReview({ review: text }),
    {
      onSuccess: () => {
        toast.success(t('publicReviews.feedbackSuccess'));
        queryClient.invalidateQueries({
          queryKey: ['public-reviews'],
        });
        fetchUser();
      },
      onError: () => toast.error(t('general.error')),
    },
  );

  return (
    <div className="relative flex flex-1 flex-col gap-14 py-14 xl:gap-16">
      <div className="inline-flex items-center justify-between">
        <span className="text-lg font-medium text-white lg:text-2xl">
          {t('publicReviews.whatPeopleThink')}
        </span>
      </div>

      <PublicReviewList />

      {isAuthenticated && user && !user.isPublicReviewExists && (
        <PublicReviewForm isDisabled={isLoading} onSubmit={createReview} />
      )}
    </div>
  );
}
