import { Controller, FieldPath, FieldValues, UseControllerProps } from 'react-hook-form';
import Accordion, { AccordionProps } from '../Accordion/Accordion';
import FormFieldError from './FormFieldError';

type FormAccordionProps<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = UseControllerProps<TFieldValues, TName> & Omit<AccordionProps, 'value'>;

const FormAccordion = <
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  control,
  name,
  ...accordionProps
}: FormAccordionProps<TFieldValues, TName>) => {
  return (
    <Controller
      render={({ field, fieldState }) => (
        <div tabIndex={-1} ref={field.ref}>
          <Accordion onChange={field.onChange} value={field.value} {...accordionProps} />
          <FormFieldError error={fieldState.error} />
        </div>
      )}
      name={name}
      control={control}
    />
  );
};

export default FormAccordion;
