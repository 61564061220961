import { useMutation } from '@tanstack/react-query';
import { toast } from 'material-react-toastify';
import { AdStatus } from '~/api/data-contracts';
import { useAuth } from '~/context/AuthContext';
import { adModel } from '~/models/adModel/adModel';
import { Trans, useTranslation } from 'react-i18next';

type AdStatusProps = {
  id: string;
  isDisabled?: boolean;
  status: AdStatus;
  refetch?: () => void;
};

export const AdStatusButton = ({ id, isDisabled, status, refetch }: AdStatusProps) => {
  const { t } = useTranslation();
  const { user, fetchUser } = useAuth();
  const { mutate: adActivate, isLoading: isActivateLoading } = useMutation(adModel.adActivate);
  const { mutate: adDeactivate, isLoading: isDectivateLoading } = useMutation(adModel.adDeactivate);

  const refetchData = async () => {
    await fetchUser();
    refetch && (await refetch());
  };

  const handleActivateClick = async () => {
    adActivate(id, {
      onSuccess: async () => {
        await refetchData();
        toast.success(t('ads.activateSuccess'));
      },
      onError: () => {
        toast.error(t('general.error'));
      },
    });
  };

  const handleDeactivateClick = async () => {
    adDeactivate(id, {
      onSuccess: async () => {
        await refetchData();
        toast.success(t('ads.deactivateSuccess'));
      },
      onError: () => {
        toast.error(t('general.error'));
      },
    });
  };

  const isUnderLimit =
    user && (user.adData?.currentlyPublished || 0) < (user.adData?.maxPublishAdsLimit || 0);

  return (
    <div className="flex flex-col items-end gap-3.5 lg:flex-row-reverse lg:items-center">
      {status === AdStatus.Active && (
        <button
          key="activate"
          type="button"
          className="btn-rounded h-12 !w-fit bg-pink transition duration-150 ease-linear hover:bg-[#C60D4F] px-6"
          onClick={handleDeactivateClick}
          disabled={isDectivateLoading}
        >
          {t('ads.deactivate')}
        </button>
      )}
      {status === AdStatus.Disabled && (
        <button
          key="activate"
          type="button"
          className="btn-rounded h-12 !w-fit bg-[#0E9B57] transition duration-150 ease-linear hover:bg-[#0C844A] px-6"
          onClick={handleActivateClick}
          disabled={isActivateLoading || isDisabled || !isUnderLimit}
        >
          {t('ads.activate')}
        </button>
      )}

      <div className="flex flex-col items-end">
        <span className="text-white text-sm">
          {t('ads.activeAds', {
            currentlyPublished: user?.adData?.currentlyPublished,
            maxPublishAdsLimit: user?.adData?.maxPublishAdsLimit,
          })}
        </span>
        {status === AdStatus.Disabled && !isUnderLimit && (
          <span className="text-white text-sm">
            <Trans i18nKey="ads.contactAdmin">
              Contact
              <a
                className="link pt-5"
                href={process.env.REACT_APP_ADMIN_LINK}
                target="_blank"
                rel="noreferrer"
              >
                Administrator
              </a>
              to increase limits
            </Trans>
          </span>
        )}
      </div>
    </div>
  );
};

export default AdStatusButton;
