import {
  CreatePublicReviewRequest,
  V1PublicReviewsCreateData,
  V1PublicReviewsListData,
  V1PublicReviewsListParams,
} from '~/api/data-contracts';
import { QueryParamsType } from '~/api/http-client';
import { api } from '~/utils/api';

const URL = {
  PUBLIC_REVIEWS: (params: QueryParamsType) =>
    `/public-reviews?${new URLSearchParams(params).toString()}`,
  CREATE_REVIEW: '/public-reviews',
};

export const publicReviewsModel = {
  publicReviews: (request: V1PublicReviewsListParams) =>
    api.get<V1PublicReviewsListData>(URL.PUBLIC_REVIEWS(request)),
  createReview: (request: CreatePublicReviewRequest) =>
    api.post<V1PublicReviewsCreateData>(URL.CREATE_REVIEW, request),
};
