import { InputHTMLAttributes } from 'react';
import { Controller, FieldPath, FieldValues, UseControllerProps } from 'react-hook-form';

type FormCheckboxAltProps<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = {
  label: string;
  onChange?: (value: boolean) => void;
} & UseControllerProps<TFieldValues, TName> &
  Omit<InputHTMLAttributes<HTMLInputElement>, 'value' | 'onChange'>;

const FormCheckboxAlt = <
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  control,
  label,
  name,
  onChange,
  ...inputProps
}: FormCheckboxAltProps<TFieldValues, TName>) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        return (
          <label className="checkbox-container block">
            <input
              type="checkbox"
              id={name}
              checked={field.value}
              onChange={(e) => field.onChange(e.target.checked)}
            />
            <span className="checkmark"></span>
            <span>{label}</span>
          </label>
        );
      }}
    />
  );
};

export default FormCheckboxAlt;
