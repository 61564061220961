import {
  BuyPremiumFeatureRequest,
  V1PremiumCreateData,
  V1PremiumListData,
} from '~/api/data-contracts';
import { api } from '~/utils/api';

const URL = {
  PREMIUM: '/premium',
};

export const premiumModel = {
  getPremium: () => api.get<V1PremiumListData>(URL.PREMIUM),
  postPremium: (request: BuyPremiumFeatureRequest) =>
    api.post<V1PremiumCreateData>(URL.PREMIUM, request),
};
