import { adModel } from '~/models/adModel/adModel';
import AdList from '../AdList/AdList';
import { useTranslation } from 'react-i18next';

export default function AdsPersonal() {
  const { t } = useTranslation();

  return (
    <AdList
      adHref={(id) => `/ads/${id}/edit`}
      loadFn={adModel.adsPersonal}
      title={t('ads.myAds')}
    />
  );
}
