import classNames from 'classnames';
import { DropzoneInputProps, useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { Icon } from '../Icon/Icon';

type ImageUploadProps = {
  onChange: (files: { file: string | File; url: string }[]) => void;
  files: { file: string | File; url: string }[];
  dropzoneProps?: DropzoneInputProps;
};

export const ImageUpload = ({ files, onChange, dropzoneProps }: ImageUploadProps) => {
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/png': ['.png'],
      'image/jpg': ['.jpg'],
      'image/jpeg': ['.jpeg'],
    },
    multiple: true,
    onDrop: (acceptedFiles) => {
      const added = acceptedFiles.map((file) => ({
        file,
        url: URL.createObjectURL(file),
      }));

      onChange([...files, ...added]);
    },
  });

  const { className: rootClassName, ...rootProps } = getRootProps({ className: 'dropzone' });
  const { style, ...inputProps } = getInputProps(dropzoneProps);

  const { t } = useTranslation();

  return (
    <div className="flex flex-1 flex-shrink-0 flex-col gap-7 rounded-lg bg-primary p-7 lg:flex-initial lg:flex-row lg:p-14">
      <div
        className={classNames(
          'relative rounded-lg border-[2px] border-dashed border-[#272727] transition duration-150 ease-linear hover:bg-secondary',
          rootClassName,
        )}
        {...rootProps}
      >
        <input
          type="file"
          multiple
          className="relative z-50 block cursor-pointer opacity-0 h-48 w-full"
          {...inputProps}
        />
        <div className="absolute top-0 right-0 left-0 m-auto flex h-full w-full flex-col items-center justify-center gap-3.5">
          <Icon alt="FileUpload" icon="icon-file-upload" className="h-8 w-8 text-white" />
          <div className="hidden flex-col items-center lg:flex">
            <p className="font-regular text-white text-center">{t('imageUpload.dropzone')}</p>
          </div>
          <div className="flex flex-col items-center lg:hidden">
            <p className="font-bold text-white text-center">{t('imageUpload.selectFiles')}</p>
          </div>
        </div>
      </div>
      <div className="flex flex-1 flex-col gap-1.5">
        {files.map((file, index) => {
          return (
            <div
              key={file.url}
              className="inline-flex items-center justify-between rounded-lg border-[1px] border-[#272727] bg-secondary py-2.5 pl-5 pr-3"
            >
              <div className="inline-flex items-center gap-2.5 text-white">
                <img src={file.url} className="display-block w-auto h-20" />
              </div>
              <button
                type="button"
                onClick={() => {
                  onChange(files.filter((_, i) => i !== index));

                  URL.revokeObjectURL(file.url);
                }}
              >
                <Icon alt="Close" icon="icon-close-alt2" className="h-3 w-3 text-[#999999]" />
              </button>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ImageUpload;
