import { Controller, FieldPath, FieldValues, UseControllerProps } from 'react-hook-form';
import SvgIcon from '../SvgIcon';
import FormFieldError from './FormFieldError';

type Option = {
  label: string;
  value: string;
  onHintClick?: () => void;
};

type FormRadioProps<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = {
  options: Option[];
  label: string;
  onChange?: (avlue: string) => void;
} & UseControllerProps<TFieldValues, TName>;

const FormRadio = <
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  control,
  name,
  options,
}: FormRadioProps<TFieldValues, TName>) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => {
        const getInputId = (optionValue: Option['value']) => {
          return `radio-${name}-${optionValue}`;
        };

        return (
          <div>
            {options.map((option) => (
              <div key={option.value} className="flex">
                <label className="radio-container radio-container-pad flex-1">
                  <input
                    className="form-check-input"
                    type="radio"
                    name={field.name}
                    id={getInputId(option.value)}
                    onChange={() => field.onChange(option.value)}
                    checked={field.value === option.value}
                  />
                  <span className="fake-radio"></span>
                  <span>{option.label}</span>
                </label>
                {option.onHintClick && (
                  <span className="total">
                    <button className="pink-color" onClick={option.onHintClick} type="button">
                      <SvgIcon svgName="icon-question" className="icons" />
                    </button>
                  </span>
                )}
              </div>
            ))}

            {fieldState.error && <FormFieldError error={fieldState.error} />}
          </div>
        );
      }}
    />
  );
};

export default FormRadio;
