// import * as Sentry from '@sentry/react';
// import { BrowserTracing } from '@sentry/tracing';
import { QueryClientProvider } from '@tanstack/react-query';
import { ToastContainer } from 'material-react-toastify';
import 'material-react-toastify/dist/ReactToastify.css';
import { CookiesProvider } from 'react-cookie';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import { AuthProvider } from './context/AuthContext';
import { ModalProvider } from './context/ModalContext';
import { Router } from './router/Router';
import { queryClient } from './utils/queryClient';
import './i18n';
import 'flag-icons/css/flag-icons.min.css';
import 'react-modern-drawer/dist/index.css';
// import reportWebVitals from './reportWebVitals';

// Sentry.init({
//   dsn: process.env.REACT_APP_SENTRY_DSN,
//   integrations: [new BrowserTracing()],
//   tracesSampleRate: 1.0,
// });

// initAmplitude();
// sendAmplitudeData(AmplitudeEvent.PageOpened);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter basename="/">
        <ScrollToTop />
        <CookiesProvider>
          <AuthProvider>
            <ModalProvider>
              <Router />
            </ModalProvider>
          </AuthProvider>
        </CookiesProvider>
      </BrowserRouter>
    </QueryClientProvider>
    <ToastContainer
      autoClose={2500}
      style={{ marginBottom: 20, zIndex: 99999 }}
      position="bottom-center"
    />
  </>,
);

// reportWebVitals();
