import {
  AdType,
  AvailableFor,
  BreastSize,
  BreastType,
  Ethnicity,
  EyeColor,
  HairColor,
  HairLenght,
  MeetingWith,
  Orientation,
  Piercing,
  PubicHair,
  RateTime,
  Smoker,
  Travel,
} from '~/api/data-contracts';
import i18n from '~/i18n';

export const AD_TYPE_OPTIONS = Object.values(AdType).map((value) => ({
  value,
  label: i18n.t(`adType.${value}`),
}));

export const AVAILABLE_FOR_OPTIONS = Object.values(AvailableFor).map((value) => ({
  value,
  label: i18n.t(`availableFor.${value}`),
}));

export const BREAST_SIZE_OPTIONS = Object.values(BreastSize).map((value) => ({
  value,
  label: value,
}));

export const BREAST_TYPE_OPTIONS = Object.values(BreastType).map((value) => ({
  value,
  label: i18n.t(`breastType.${value}`),
}));

export const ETHNICITY_OPTIONS = Object.values(Ethnicity).map((value) => ({
  value,
  label: i18n.t(`ethnicity.${value}`),
}));

export const EYE_COLOR_OPTIONS = Object.values(EyeColor).map((value) => ({
  value,
  label: i18n.t(`eyeColor.${value}`),
}));

export const HAIR_COLOR_OPTIONS = Object.values(HairColor).map((value) => ({
  value,
  label: i18n.t(`hairColor.${value}`),
}));

export const HAIR_LENGTH_OPTIONS = Object.values(HairLenght).map((value) => ({
  value,
  label: i18n.t(`hairLength.${value}`),
}));

export const MEETING_WITH_OPTIONS = Object.values(MeetingWith).map((value) => ({
  value,
  label: i18n.t(`meetingWith.${value}`),
}));

export const ORIENTATION_OPTIONS = Object.values(Orientation).map((value) => ({
  value,
  label: i18n.t(`orientation.${value}`),
}));

export const PIERCING_OPTIONS = Object.values(Piercing).map((value) => ({
  value,
  label: i18n.t(`piercing.${value}`),
}));

export const PUBIC_HAIR_OPTIONS = Object.values(PubicHair).map((value) => ({
  value,
  label: i18n.t(`pubicHair.${value}`),
}));

export const SMOKER_OPTIONS = Object.values(Smoker).map((value) => ({
  value,
  label: i18n.t(`smoker.${value}`),
}));

export const TRAVEL_OPTIONS = Object.values(Travel).map((value) => ({
  value,
  label: i18n.t(`travel.${value}`),
}));

export const AGE_OPTIONS = [
  { value: '1', label: '18 - 25', context: { min: 18, max: 25 } },
  { value: '2', label: '26 - 35', context: { min: 26, max: 35 } },
  { value: '3', label: '36 - 45', context: { min: 36, max: 45 } },
  { value: '4', label: '> 45', context: { min: 46, max: undefined } },
];

export const HEIGHT_OPTIONS = [
  { value: '1', label: '< 150cm', context: { min: undefined, max: 150 } },
  { value: '2', label: '151cm - 160cm', context: { min: 151, max: 160 } },
  { value: '3', label: '161cm - 170cm', context: { min: 161, max: 170 } },
  { value: '4', label: '171cm - 180cm', context: { min: 171, max: 180 } },
  { value: '5', label: '> 180cm', context: { min: 181, max: undefined } },
];

export const WEIGHT_OPTIONS = [
  { value: '1', label: '< 50kg', context: { min: undefined, max: 50 } },
  { value: '2', label: '51kg - 60kg', context: { min: 51, max: 60 } },
  { value: '3', label: '61kg - 70kg', context: { min: 61, max: 70 } },
  { value: '4', label: '71kg - 80kg', context: { min: 71, max: 80 } },
  { value: '5', label: '> 80kg', context: { min: 81, max: undefined } },
];

export const PRICE_OPTIONS = [
  {
    value: RateTime.HalfAHour,
    label: i18n.t('rateTime.HalfAHour'),
    items: [
      { value: '1', label: '< 50EUR', context: { min: undefined, max: 50 } },
      { value: '2', label: '51EUR - 100EUR', context: { min: 51, max: 100 } },
      { value: '3', label: '> 100EUR', context: { min: 101, max: undefined } },
    ],
  },
  {
    value: RateTime.Hour,
    label: i18n.t('rateTime.Hour'),
    items: [
      { value: '1', label: '< 100EUR', context: { min: undefined, max: 100 } },
      { value: '2', label: '101EUR - 150EUR', context: { min: 101, max: 150 } },
      { value: '3', label: '> 150EUR', context: { min: 151, max: undefined } },
    ],
  },
];

export const TATTOO_OPTIONS = [
  { value: '1', label: i18n.t('general.yes'), context: { value: true } },
  { value: '2', label: i18n.t('general.no'), context: { value: false } },
];
