import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';

declare class Accordion {
  constructor(init: any, config: any);

  update(): void;
}

type Value = string | number;
type Group = { value: Value; label: string; items: Item[] };
type Item = { value: Value; label: string };
type SelectedValue = {
  group: Value;
  item: Value;
};

export type AccordionGroupProps = {
  groups: Group[];
  title?: string;
  value: SelectedValue | null;
  onChange?: (value: SelectedValue | null) => void;
};

export default function AccordionMulti({ groups, title, onChange, value }: AccordionGroupProps) {
  const [group, setGroup] = useState<Value | undefined>(value?.group || groups[0]?.value);

  const accordionElementRef = useRef<HTMLDivElement>(null);
  const accordionRef = useRef<Accordion | null>(null);

  useEffect(() => {
    if (accordionElementRef.current && !accordionRef.current) {
      accordionRef.current = new Accordion(accordionElementRef.current, {
        showMultiple: false,
      });
    }
  }, [accordionElementRef.current]);

  useEffect(() => {
    if (accordionRef.current) {
      accordionRef.current.update();
    }
  }, [groups]);

  const handleChange = (item: SelectedValue) => {
    if (!onChange) {
      return;
    }

    const nextValue = item.group === value?.group && item.item === value?.item ? null : item;

    onChange(nextValue);
  };

  const currentOpenGroup = groups.find((g) => g.value === group);

  const currentGroup = groups.find((g) => g.value === value?.group);
  const currentItem = currentGroup?.items.find((i) => i.value === value?.item);

  const selectedLabel =
    currentItem && currentGroup && `${currentItem.label}, ${currentGroup.label}`;

  return (
    <div className="accordion-container" ref={accordionElementRef}>
      <div className="ac border-b-[1px] border-[#141414]">
        <div className="ac-header ac-trigger">
          <div className="ac-header-text flex items-center justify-between gap-2">
            <span>{title}</span>
            {selectedLabel && <span className="text-pink justify-self-end">{selectedLabel}</span>}
          </div>
        </div>
        <div className="ac-panel bg-primary">
          <div>
            <div className="flex bg-secondary">
              {groups.map((g) => (
                <button
                  className={classNames(
                    'text-gray flex-1 gap-2 p-3 hover:text-white rounded-t-lg',
                    {
                      'text-white bg-primary': group === g.value,
                    },
                  )}
                  type="button"
                  onClick={() => setGroup(g.value)}
                  key={g.value}
                >
                  {g.label}
                </button>
              ))}
            </div>

            {currentOpenGroup && (
              <div className="py-3">
                {currentOpenGroup.items.map((item) => (
                  <label className="checkbox-container block" key={item.value}>
                    <input
                      checked={value?.item === item.value && value.group === currentOpenGroup.value}
                      type="checkbox"
                      onChange={() =>
                        handleChange({ group: currentOpenGroup.value, item: item.value })
                      }
                    />
                    <span className="checkmark"></span>
                    <span>{item.label}</span>
                  </label>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
