import { useState } from 'react';
import { Link, matchPath, useLocation } from 'react-router-dom';
import { UserType } from '~/api/data-contracts';
import Counts from '~/components/Counts/Counts';
import { Icon } from '~/components/Icon/Icon';
import { useAuth } from '~/context/AuthContext';
import { locationCounterModel } from '~/models/locationCounterModel/locationCounterModel';
import { ROUTES } from '~/router/Routes';

export default function Sidebar() {
  const { hasOneOfRoles, getHomePath } = useAuth();
  const [searchTerm] = useState('');
  const { pathname } = useLocation();

  const matchesReviews = matchPath({ path: ROUTES.REVIEWS_LIST }, pathname);

  return (
    <aside className="sticky top-0 self-start hidden z-10 flex-1 flex-col flex-shrink-0 bg-primary max-w-[270px] xl:max-w-[320px] min-w-[270px] min-h-screen overflow-auto xl:flex shadow-[30px_0px_30px_rgba(0,0,0,0.15)]">
      <Link to={getHomePath()} className="w-full h-20 flex items-center justify-center">
        <Icon alt="Logo" icon="logo" className="w-[140px] h-[40px]" />
      </Link>
      {/* <div className="relative group search-input h-12">
        <input
          type="text"
          className="form-control-input bg-transparent w-full h-full pl-5 pr-20 py-4 text-sm text-white placeholder:text-gray border-transparent focus:border-transparent focus:ring-0 placeholder:text-[#999999]"
          placeholder="Paieška"
        />
        <Icon
          alt="Search"
          icon="icon-search"
          className="absolute h-4 w-4 top-1/2 right-5 transform -translate-y-1/2 text-[#999999]"
        />
        <div className="absolute top-0 left-0 w-full h-full bg-secondary -z-10"></div>
        <div className="absolute w-full h-1 bottom-0 search-gradient hidden group-focus-within:block"></div>
      </div> */}
      {hasOneOfRoles([UserType.Reviewer]) && matchesReviews ? (
        <Counts
          fetchFn={locationCounterModel.reviewCounts}
          path={ROUTES.REVIEWS_LIST}
          searchTerm={searchTerm}
          cacheKey="reviews"
        />
      ) : (
        <Counts
          fetchFn={locationCounterModel.adCounts}
          path={ROUTES.AD_LIST}
          searchTerm={searchTerm}
          cacheKey="ads"
        />
      )}
    </aside>
  );
}
