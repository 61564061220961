import { useMutation, useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { GetPremiumTimeQueryResult } from '~/api/data-contracts';
import { Loader } from '~/components/Loader/Loader';
import { useAuth } from '~/context/AuthContext';
import { useModals } from '~/context/ModalContext';
import CodeConfirmFlow, {
  StepContext,
} from '~/features/twoFactor/components/CodeConfirmFlow/CodeConfirmFlow';
import { premiumModel } from '~/models/premiumModel/premiumModel';
import { showFirstOrGeneric } from '~/utils/error';

export default function BuyPremiumModal() {
  const { t } = useTranslation();
  const { hideModal } = useModals();
  const { fetchUser, user } = useAuth();
  const { data: premium, isLoading: isPremiumLoading } = useQuery(
    ['premium'],
    premiumModel.getPremium,
  );
  const {
    mutate: postPremium,
    isLoading: isPremiumPostLoading,
    isSuccess: isPremiumPostSuccess,
  } = useMutation(premiumModel.postPremium, {
    onSuccess: () => {
      fetchUser();
    },
    onError: showFirstOrGeneric,
  });

  const handleConfirm = (item: GetPremiumTimeQueryResult) => {
    return postPremium({
      premiumFeatureNo: item.no,
      // verificationCode: '',
    });
  };

  const handleEnd = () => {
    hideModal();
  };

  const getSteps = ({ nextStep }: StepContext<GetPremiumTimeQueryResult>) => [
    {
      hideActions: true,
      content: (
        <div className="flex flex-col gap-6">
          <header className="flex w-full items-center justify-start">
            <span className="text-xl font-semibold text-white">{t('premium.buyPremium')}</span>
          </header>

          {isPremiumLoading ? (
            <Loader />
          ) : (
            <div className="flex flex-col gap-3">
              {premium?.items.map((item) => (
                <div
                  className="flex items-center min-h-[50px] bg-secondary rounded-md py-2"
                  key={item.no}
                >
                  <div className="flex flex-1 pr-2 xl:pr-5 border-r border-pink">
                    <span className="text-white text-xl">
                      {item.premiumTimeInDays}{' '}
                      <span className="text-gray text-sm">{t('premium.days')}</span>
                    </span>
                  </div>

                  <div className="flex flex-1 pl-2 xl:pl-5">
                    <span className="text-white text-xl">
                      {item.cost.toFixed(2)}{' '}
                      <span className="text-gray text-sm">{t('credits.credits')}</span>
                    </span>
                  </div>

                  <div className="pl-2 xl:pl-5">
                    <button
                      className="btn-rounded h-12 w-auto bg-pink transition duration-150 ease-linear hover:bg-[#C60D4F] px-4"
                      role="button"
                      disabled={user && user.balance.balance < item.cost}
                      onClick={() => nextStep(item)}
                    >
                      {t('premium.buy')}
                    </button>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      ),
    },
  ];

  return (
    <div
      className="relative max-h-[90vh] w-full overflow-auto rounded-lg bg-primary text-center"
      role="dialog"
      aria-modal="true"
    >
      <CodeConfirmFlow
        getSteps={getSteps}
        onConfirm={handleConfirm}
        onEnd={handleEnd}
        confirmMessage={(item) =>
          t('premium.buyConfirm', { days: item.premiumTimeInDays, cost: item.cost })
        }
        successMessage={(item) => t('premium.buySuccess', { days: item.premiumTimeInDays })}
        isLoading={isPremiumPostLoading}
        isSuccess={isPremiumPostSuccess}
      />
    </div>
  );
}
