import { ActionMap, CurrentUser } from './auth';

export enum AuthTypes {
  Initial = 'INITIALIZE',
  Login = 'LOGIN',
  Logout = 'LOGOUT',
}

export type AuthPayload = {
  [AuthTypes.Initial]: {
    isAuthenticated: boolean;
    user: CurrentUser;
  };
  [AuthTypes.Login]: {
    user: CurrentUser;
  };
  [AuthTypes.Logout]: undefined;
};

export type AuthActions = ActionMap<AuthPayload>[keyof ActionMap<AuthPayload>];
