import { useNavigate } from 'react-router-dom';
import { useAuth } from '~/context/AuthContext';
import UserProfile from '~/features/auth/components/UserProfile/UserProfile';

export default function UserProfilePage() {
  const { user, getHomePath } = useAuth();

  const navigate = useNavigate();

  if (!user?.id) {
    navigate(getHomePath());
    return null;
  }

  return <UserProfile userId={user.id} user={user} />;
}
