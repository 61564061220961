import { Route, Routes } from 'react-router-dom';

import { UserType } from '~/api/data-contracts';
import Layout from '~/components/Layout/Layout';
import Authorized from '~/features/auth/components/Authorized/Authorized';
import AdCreatePage from '~/pages/AdCreatePage/AdCreatePage';
import AdDetailsPage from '~/pages/AdDetailsPage/AdDetailsPage';
import AdEditPage from '~/pages/AdEditPage/AdEditPage';
import AdsPage from '~/pages/AdsPage/AdsPage';
import AdsPersonalPage from '~/pages/AdsPersonalPage/AdsPersonalPage';
import Home from '~/pages/Home/Home';
import { NotFound } from '~/pages/NotFound/NotFound';

import ResetPasswordPage from '~/pages/ResetPasswordPage/ResetPasswordPage';
import ReviewCreatePage from '~/pages/ReviewCreatePage/ReviewCreatePage';
import ReviewDetailsPage from '~/pages/ReviewDetailsPage/ReviewDetailsPage';
import ReviewEditPage from '~/pages/ReviewEditPage/ReviewEditPage';
import ReviewInternalCreatePage from '~/pages/ReviewInternalCreatePage/ReviewInternalCreatePage';
import ReviewsPage from '~/pages/ReviewsPage/ReviewsPage';
import ReviewsPersonalPage from '~/pages/ReviewsPersonalPage/ReviewsPersonalPage';
import UserPage from '~/pages/UserPage/UserPage';
import UserProfilePage from '~/pages/UserProfilePage/UserProfilePage';
import UserSettingsPage from '~/pages/UserSettingsPage/UserSettingsPage';
import WallofHonorPage from '~/pages/WallOfHonorPage/WallOfHonorPage';
import { ROUTES } from './Routes';

export const Router = () => (
  <Layout>
    <Routes>
      <Route path={ROUTES.DEFAULT} element={<Home />} />
      <Route path={ROUTES.AD_LIST} element={<AdsPage />} />
      <Route
        path={ROUTES.AD_LIST_PERSONAL}
        element={
          <Authorized roles={[UserType.Agency, UserType.Independent]}>
            <AdsPersonalPage />
          </Authorized>
        }
      />
      <Route
        path={ROUTES.AD_CREATE}
        element={
          <Authorized roles={[UserType.Agency, UserType.Independent]}>
            <AdCreatePage />
          </Authorized>
        }
      />
      <Route path={ROUTES.AD_DETAILED} element={<AdDetailsPage />} />
      <Route
        path={ROUTES.AD_EDIT}
        element={
          <Authorized roles={[UserType.Agency, UserType.Independent]}>
            <AdEditPage />
          </Authorized>
        }
      />

      <Route
        path={ROUTES.REVIEWS_LIST}
        element={
          <Authorized roles={[UserType.Reviewer]}>
            <ReviewsPage />
          </Authorized>
        }
      />

      <Route
        path={ROUTES.REVIEW_LIST_PERSONAL}
        element={
          <Authorized roles={[UserType.Reviewer]}>
            <ReviewsPersonalPage />
          </Authorized>
        }
      />
      <Route
        path={ROUTES.REVIEW_DETAILED}
        element={
          <Authorized roles={[UserType.Reviewer]}>
            <ReviewDetailsPage />
          </Authorized>
        }
      />
      <Route
        path={ROUTES.REVIEW_CREATE_INTERNAL}
        element={
          <Authorized roles={[UserType.Reviewer]}>
            <ReviewInternalCreatePage />
          </Authorized>
        }
      />
      <Route
        path={ROUTES.REVIEW_CREATE_EXTERNAL}
        element={
          <Authorized roles={[UserType.Reviewer]}>
            <ReviewCreatePage />
          </Authorized>
        }
      />
      <Route
        path={ROUTES.REVIEW_EDIT}
        element={
          <Authorized roles={[UserType.Reviewer]}>
            <ReviewEditPage />
          </Authorized>
        }
      />

      <Route
        path={ROUTES.USER}
        element={
          <Authorized>
            <UserPage />
          </Authorized>
        }
      />

      <Route
        path={ROUTES.USER_PROFILE}
        element={
          <Authorized>
            <UserProfilePage />
          </Authorized>
        }
      />

      <Route
        path={ROUTES.HONOR}
        element={
          <Authorized>
            <WallofHonorPage />
          </Authorized>
        }
      />

      <Route
        path={ROUTES.USER_SETTINGS}
        element={
          <Authorized allowInactive>
            <UserSettingsPage />
          </Authorized>
        }
      />

      <Route path={ROUTES.RESET_PASSWORD} element={<ResetPasswordPage />} />

      <Route path="*" element={<NotFound />} />
    </Routes>
  </Layout>
);
