import { V1LocationsLanguagesListData, V1LocationsLanguagesListParams } from '~/api/data-contracts';
import { GetLocationsResponse, GetTownsRequest, GetTownsResponse } from '~/types/location';
import { api } from '~/utils/api';

const URL = {
  LANGUAGES: '/locations/languages',
  LOCATIONS_COUNTRIES: '/locations/countries',
  TOWNS: (nationalityNo: string) => `/locations/${nationalityNo}/towns`,
};

export const locationModel = {
  languages: (request: V1LocationsLanguagesListParams) =>
    api.get<V1LocationsLanguagesListData>(URL.LANGUAGES, { params: request }),
  locations: (search: string) =>
    api.get<GetLocationsResponse>(`${URL.LOCATIONS_COUNTRIES}?Search=${search}`),
  towns: (request: GetTownsRequest) =>
    api.get<GetTownsResponse>(URL.TOWNS(request.nationalityNo.toString())),
};
