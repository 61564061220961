import i18next from 'i18next';
import { DateTime } from 'luxon';
import i18n from '~/i18n';

export const formatDate = (date: Date) =>
  new Date(date).toLocaleDateString(i18next.language, {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });

export const formatDateTime = (date: Date) =>
  new Date(date).toLocaleDateString(i18next.language, {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: false,
  });

export const formatTime = (date: Date) =>
  new Date(date)
    .toLocaleDateString(i18next.language, {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
    })
    .slice(-5);

export const formatDateShort = (date: Date | string, format = 'yyyy.MM.dd') =>
  DateTime.fromISO(date.toString()).toFormat(format);

export const getDateDifferenceInDays = (date: Date | string) => {
  const today = new Date();
  const diffTime = Math.abs(today.getTime() - new Date(date).getTime());
  const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
  return diffDays;
};

export const getDayDifferenceText = (date: string) => {
  const dayDiff = getDateDifferenceInDays(date);

  if (dayDiff === 0) {
    return i18n.t('date.at', { date: formatTime(new Date(date)) });
  }

  if (dayDiff === 1) {
    return i18n.t('date.yesterday');
  }

  return i18n.t('date.daysAgo', { days: dayDiff });
};
