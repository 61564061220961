import { api } from '~/utils/api';

const URL = {
  FOLLOW_AD: (id: string) => `/followings/alpha/${id}/follow`,
  UNFOLLOW_AD: (id: string) => `/followings/alpha/${id}/unfollow`,
  FOLLOW_REVIEW: (id: string) => `/followings/reviews/${id}/follow`,
  UNFOLLOW_REVIEW: (id: string) => `/followings/reviews/${id}/unfollow`,
  LIKE_REVIEW: (id: string) => `/likes/reviews/${id}/like`,
  DISLIKE_REVIEW: (id: string) => `/likes/reviews/${id}/dislike`,
  UNLIKE_REVIEW: (id: string) => `/likes/reviews/${id}/unlike`,
};

export const followModel = {
  followAd: (id: string) => api.patch(URL.FOLLOW_AD(id)),
  unfollowAd: (id: string) => api.patch(URL.UNFOLLOW_AD(id)),
  followReview: (id: string) => api.patch(URL.FOLLOW_REVIEW(id)),
  unfollowReview: (id: string) => api.patch(URL.UNFOLLOW_REVIEW(id)),
  likeReview: (id: string) => api.patch(URL.LIKE_REVIEW(id)),
  dislikeReview: (id: string) => api.patch(URL.DISLIKE_REVIEW(id)),
  unlikeReview: (id: string) => api.patch(URL.UNLIKE_REVIEW(id)),
};
