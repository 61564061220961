import axios from 'axios';
import { V1AuthLoginCreateData } from '~/api/data-contracts';
import { localStorageKeys } from '~/config/consts';
import { ROUTES } from '~/router/Routes';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_ROOT,
  headers: {
    accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

axiosInstance.interceptors.request.use(
  (config) => {
    const auth = localStorage.getItem(localStorageKeys.auth);

    const authJson: V1AuthLoginCreateData = auth ? JSON.parse(auth) : null;

    if (authJson?.accessToken) {
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${authJson.accessToken}`,
      };
    }

    return config;
  },
  (error) => Promise.reject(error),
);

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    // if (error instanceof AxiosError) {
    //   console.log(error);
    // }

    if (error?.response?.status === 401) {
      localStorage.removeItem(localStorageKeys.auth);
      if (window.location.pathname !== ROUTES.DEFAULT) {
        window.location.href = ROUTES.DEFAULT;
      }
    }

    return Promise.reject(error);
  },
);

export { axiosInstance };
