import { ButtonHTMLAttributes, ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
// import CodeConfirmForm, { ConfirmFormValues } from '../CodeConfirmForm/CodeConfirmForm';
import SetupSuccess from '../SetupTwoFactorModal/SetupSuccess';
import ConfirmForm from '../CodeConfirmForm/ConfirmForm';

type Step = {
  content: ReactNode;
  formId?: string;
  hideActions?: boolean;
};

export type StepContext<TCtx> = {
  nextStep: (ctx: TCtx) => void;
  prevStep: () => void;
};

type CodeConfirmFlowProps<TCtx> = {
  getSteps: (ctx: StepContext<TCtx>) => Step[];
  onConfirm: (ctx: TCtx /* , values: ConfirmFormValues */) => void;
  onEnd: () => void;
  confirmMessage: (ctx: TCtx) => string;
  successMessage: (ctx: TCtx) => string;
  isLoading: boolean;
  isSuccess: boolean;
};

export default function CodeConfirmFlow<TCtx>({
  getSteps,
  onConfirm,
  onEnd,
  confirmMessage,
  successMessage,
  isLoading,
  isSuccess,
}: CodeConfirmFlowProps<TCtx>) {
  const { t } = useTranslation();

  const [showSuccess, setShowSuccess] = useState<boolean>(false);
  const [index, setIndex] = useState<number>(0);
  const [ctx, setCtx] = useState<TCtx | null>(null);

  const nextStep = (c: TCtx) => {
    setCtx(c);
    setIndex((s) => s + 1);
  };

  const prevStep = () => {
    setIndex((s) => s - 1);
  };

  const steps = getSteps({ nextStep, prevStep });
  const currentStep = steps[index];

  const confirmationFormId = 'confirmation-form';

  useEffect(() => {
    if (isSuccess) {
      setShowSuccess(true);
    }
  }, [isSuccess]);

  const getActions = (step: Step) => {
    if (step.hideActions) {
      return null;
    }

    const nextStepProps: Partial<ButtonHTMLAttributes<HTMLButtonElement>> = step.formId
      ? { type: 'submit', form: step.formId }
      : { onClick: () => ctx && nextStep(ctx) };

    return (
      <div className="flex w-full gap-3.5">
        {index !== 0 && (
          <button
            type="button"
            className="!h-[40px] btn-rounded border-transparent bg-[#6B6B6B] transition duration-150 ease-linear hover:bg-gray"
            disabled={isLoading}
            onClick={prevStep}
          >
            {t('twoFactor.previous')}
          </button>
        )}

        <button
          className="!h-[40px] btn-rounded border-transparent bg-pink transition duration-150 ease-linear hover:bg-[#C60D4F]"
          disabled={isLoading}
          {...nextStepProps}
        >
          {t('twoFactor.next')}
        </button>
      </div>
    );
  };

  if (showSuccess) {
    return <SetupSuccess onClose={onEnd} message={ctx ? successMessage(ctx) : ''} />;
  }

  if (index >= steps.length) {
    return (
      <div className="flex flex-col px-4 py-6 gap-6">
        {/* <CodeConfirmForm
          formId={confirmationFormId}
          onSubmit={async (values) => {
            ctx && (await onConfirm(ctx, values));
            setShowSuccess(true);
          }}
          phone="+370123132132"
        /> */}
        <ConfirmForm
          formId={confirmationFormId}
          message={ctx ? confirmMessage(ctx) : ''}
          onSubmit={() => {
            if (ctx) {
              onConfirm(ctx);
            }
          }}
        />
        {getActions({
          content: null,
          formId: confirmationFormId,
        })}
      </div>
    );
  }

  return (
    <div className="flex flex-col px-4 py-6 gap-6">
      <div className="text-white">{currentStep?.content}</div>
      {getActions(currentStep)}
    </div>
  );
}
