import { yupResolver } from '@hookform/resolvers/yup';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ReviewStatus } from '~/api/data-contracts';
import FormField from '~/components/form/FormField';
import FormFieldArrayError from '~/components/form/FormFieldArrayError';
import FormSelect from '~/components/form/FormSelect';
import FormTextarea from '~/components/form/FormTextarea';
import { locationModel } from '~/models/locationModel/locationModel';
import { reviewModel } from '~/models/reviewModel/reviewModel';
import { ReviewInternalFormValues, reviewInternalSchema } from './reviewSchema';
import { Loader } from '~/components/Loader/Loader';

const ratings = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

interface ReviewFormProps {
  onSubmit: (values: ReviewInternalFormValues) => Promise<void>;
  defaultValues: ReviewInternalFormValues;
  title: string;
  onActivateClick?: () => Promise<void>;
  onDeactivateClick?: () => Promise<void>;
}

export default function ReviewForm({
  onActivateClick,
  onDeactivateClick,
  onSubmit,
  defaultValues,
  title,
}: ReviewFormProps) {
  const [isLoading, setIsloading] = useState<boolean>();

  const handleActivateClick = async () => {
    if (!onActivateClick) {
      return;
    }

    try {
      setIsloading(true);
      await onActivateClick();
    } finally {
      setIsloading(false);
    }
  };

  const handleDeactivateClick = async () => {
    if (!onDeactivateClick) {
      return;
    }

    try {
      setIsloading(true);
      await onDeactivateClick();
    } finally {
      setIsloading(false);
    }
  };

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors, isSubmitting },
  } = useForm<ReviewInternalFormValues>({
    defaultValues,
    resolver: yupResolver<ReviewInternalFormValues>(reviewInternalSchema),
    shouldFocusError: true,
  });

  const { t } = useTranslation();

  const countryNo = watch('countryNo');

  const { data: locations } = useQuery(['locations'], () => locationModel.locations('lithuania'));
  const { data: sections } = useQuery(['review sections'], reviewModel.reviewSections);
  const { data: towns } = useQuery(
    ['towns', countryNo],
    () => locationModel.towns({ nationalityNo: Number(countryNo) || 0 }),
    { enabled: !!countryNo },
  );

  if (!locations || !sections) {
    return <Loader />;
  }

  const countryOptions = locations?.items?.map((location) => ({
    label: location.name || '',
    value: location.no || 0,
  }));

  const townOptions = towns?.items.map((town) => ({
    label: town.name,
    value: town.no || 0,
  }));

  return (
    <form
      className="relative flex flex-1 flex-col gap-14 py-14 px-2.5 lg:px-18"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="flex justify-between align-center">
        <span className="text-lg font-medium text-white lg:text-2xl">{title}</span>

        <div>
          {defaultValues.status === ReviewStatus.Active && (
            <button
              key="activate"
              type="button"
              className="btn-rounded h-12 w-auto bg-pink transition duration-150 ease-linear hover:bg-[#C60D4F] px-6"
              onClick={handleDeactivateClick}
              disabled={isLoading}
            >
              {t('reviews.deactivate')}
            </button>
          )}
          {defaultValues.status === ReviewStatus.Disabled && (
            <button
              key="activate"
              type="button"
              className="btn-rounded h-12 w-auto bg-[#0E9B57] transition duration-150 ease-linear hover:bg-[#0C844A] px-6"
              onClick={handleActivateClick}
              disabled={isLoading}
            >
              {t('reviews.activate')}
            </button>
          )}
        </div>
      </div>

      <div className="flex flex-col">
        <div className="grid grid-cols-1 gap-2.5 rounded-lg bg-primary p-7 lg:flex-initial lg:grid-cols-2 lg:p-14">
          <div className="grid gap-2.5 lg:col-span-2">
            <FormField control={control} name="name" placeholder={t('reviews.escortName')} />
          </div>

          <div className="grid gap-2.5">
            <FormSelect
              name="countryNo"
              control={control}
              options={countryOptions}
              placeholder={t('reviews.selectCountry')}
            />
          </div>

          <div className="grid gap-2.5">
            <FormSelect
              name="locationNo"
              control={control}
              options={townOptions}
              placeholder={t('reviews.selectTown')}
            />
          </div>
        </div>
      </div>

      <Controller
        render={({ field }) => (
          <div className="flex flex-col" ref={field.ref} tabIndex={-1}>
            <span className="mb-5 text-base font-medium text-white">
              {t('reviews.serviceRatings')}
            </span>
            <div className="grid grid-cols-1 gap-7 rounded-lg bg-primary p-7 lg:grid-cols-2 lg:p-14">
              {sections.items?.map((section, index) => (
                <div className="flex flex-col gap-2.5" key={index}>
                  <span className="text-sm text-[#999999]">
                    {t(`sections.${section.name}`)} ({t('reviews.rate')}):
                  </span>
                  <div className="inline-flex flex-1 overflow-hidden rounded-lg bg-secondary">
                    {ratings.map((rating, i) => (
                      <label className="radio-rating-container" key={i}>
                        <input
                          type="radio"
                          checked={field.value[index]?.rate === rating}
                          onChange={() => {
                            const newValue = [...field.value];

                            newValue[index] = {
                              sectionNo: section.no,
                              rate: rating,
                            };

                            field.onChange(newValue);
                          }}
                        />
                        <span>{rating}</span>
                        <div></div>
                      </label>
                    ))}
                  </div>
                </div>
              ))}
            </div>
            <FormFieldArrayError error={errors.sections} />
          </div>
        )}
        name="sections"
        control={control}
      />

      <div className="flex flex-col">
        <span className="mb-5 text-base font-medium text-white">
          {t('reviews.describeExperience')}
        </span>
        <div className="flex flex-1 flex-shrink-0 flex-col gap-2.5 rounded-lg bg-primary p-7 lg:flex-initial lg:p-14">
          <FormTextarea
            control={control}
            name="description"
            placeholder={t('reviews.yourReview')}
            rows={15}
          />
        </div>
      </div>

      <div className="-mt-10 flex flex-1 justify-end">
        <button
          className="btn-rounded bg-[#0E9B57] text-base transition duration-150 ease-linear hover:bg-[#0C844A] md:w-1/2 2xl:w-1/3"
          disabled={isSubmitting}
          type="submit"
        >
          {t('reviews.uploadReview')}
        </button>
      </div>
    </form>
  );
}
