import classNames from 'classnames';
import { Icon } from '../Icon/Icon';

const MAX_PAGES = 10;
const EXTRA_TRUNCATED_PAGES = 3;

type AdListProps = {
  itemCount: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (page: number) => void;
};

export default function Pagination({ itemCount, page, rowsPerPage, onPageChange }: AdListProps) {
  const pageCount = Math.ceil(itemCount / rowsPerPage);

  if (pageCount === 0) {
    return null;
  }

  const pages = Array.from({ length: pageCount }, (_, i) => ({
    value: i + 1,
    label: i + 1,
  }));

  const nextDisabled = page === pageCount;
  const prevDisabled = page === 1;

  const nextClassName = classNames('pagination-chevron pagination-chevron-right', {
    'pagination-chevron-inactive': nextDisabled,
  });
  const prevClassName = classNames('pagination-chevron pagination-chevron-left', {
    'pagination-chevron-inactive': prevDisabled,
  });

  const itemClassName = (p: number) =>
    classNames('pagination-link', {
      'pagination-link-selected': p === page,
    });

  const handleNextClick = () => {
    onPageChange(page + 1);
  };

  const handlePrevClick = () => {
    onPageChange(page - 1);
  };

  const getTruncatedStart = () => {
    return page <= EXTRA_TRUNCATED_PAGES + 1;
  };

  const getTruncatedEnd = () => {
    return page + EXTRA_TRUNCATED_PAGES >= pages.length;
  };

  const getTruncatedPages = () => {
    const totalPages = EXTRA_TRUNCATED_PAGES * 2 + 1;

    const isTruncatedStart = getTruncatedStart();
    if (isTruncatedStart) {
      return [...pages].slice(1, totalPages);
    }

    const isTruncatedEnd = getTruncatedEnd();
    if (isTruncatedEnd) {
      return [...pages].slice(pages.length - totalPages, pages.length - 1);
    }

    return [...pages].slice(page - EXTRA_TRUNCATED_PAGES - 1, page + EXTRA_TRUNCATED_PAGES);
  };

  return (
    <div className="flex justify-between bg-primary rounded-lg overflow-hidden text-white h-12">
      <button
        className={prevClassName}
        disabled={prevDisabled}
        aria-disabled={prevDisabled}
        onClick={handlePrevClick}
      >
        <Icon alt="Chevron" icon="icon-chevron" />
      </button>
      <div className="flex flex-1 shrink justify-center items-center sm:max-w-lg">
        {pages.length <= MAX_PAGES ? (
          pages.map((p) => (
            <button
              key={p.value}
              className={itemClassName(p.value)}
              onClick={() => onPageChange(p.value)}
            >
              <span>{p.label}</span>
            </button>
          ))
        ) : (
          <>
            <button
              key={1}
              className={itemClassName(pages[0].value)}
              onClick={() => onPageChange(pages[0].value)}
            >
              <span>{pages[0].label}</span>
            </button>

            {!getTruncatedStart() && <button className="pagination-link-truncated">...</button>}

            {getTruncatedPages().map((p) => (
              <button
                key={p.value}
                className={itemClassName(p.value)}
                onClick={() => onPageChange(p.value)}
              >
                <span>{p.label}</span>
              </button>
            ))}

            {!getTruncatedEnd() && <button className="pagination-link-truncated">...</button>}

            <button
              key={pages[pages.length - 1].value}
              className={itemClassName(pages[pages.length - 1].value)}
              onClick={() => onPageChange(pages[pages.length - 1].value)}
            >
              <span>{pages[pages.length - 1].label}</span>
            </button>
          </>
        )}
      </div>
      <button
        className={nextClassName}
        disabled={nextDisabled}
        aria-disabled={nextDisabled}
        onClick={handleNextClick}
      >
        <Icon alt="Chevron" icon="icon-chevron" />
      </button>
    </div>
  );
}
