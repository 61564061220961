import { useEffect, useState } from 'react';

export const useDebounce = <T>(value: T, time = 300) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const timeout = setTimeout(() => setDebouncedValue(value), time);

    return () => clearTimeout(timeout);
  }, [value]);

  return debouncedValue;
};
