import { Trans, useTranslation } from 'react-i18next';

export default function BuyCreditsModal() {
  const { t } = useTranslation();

  const amounts = [50, 100, 200, 300, 400];
  const rate = 0.34525;

  return (
    <div
      className="relative max-h-[90vh] w-full overflow-auto rounded-lg bg-primary text-center"
      role="dialog"
      aria-modal="true"
    >
      <div className="flex flex-col px-4 py-6 gap-6">
        <header className="flex w-full items-center justify-start">
          <span className="text-xl font-semibold text-white">{t('credits.buyCredits')}</span>
        </header>

        <div className="flex flex-col gap-3">
          {amounts.map((ammount) => (
            <div className="flex items-center h-[50px] bg-secondary rounded-md" key={ammount}>
              <div className="flex flex-1 pr-2 xl:pr-5 border-r border-pink">
                <span className="text-white text-xl">
                  {ammount} <span className="text-gray text-sm">{t('credits.credits')}</span>
                </span>
              </div>

              <div className="flex flex-1 pl-2 xl:pl-5">
                <span className="text-white text-xl">
                  {(ammount * rate).toFixed(2)} <span className="text-gray text-sm">EUR</span>
                </span>
              </div>
            </div>
          ))}
        </div>

        <p className="text-white text-center">
          <Trans i18nKey="credits.contactAdmin">
            Or contact
            <a
              className="link pt-5"
              href={process.env.REACT_APP_ADMIN_LINK}
              target="_blank"
              rel="noreferrer"
            >
              Administrator
            </a>
          </Trans>
        </p>
      </div>
    </div>
  );
}
