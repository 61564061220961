type IconProps = {
  alt: string;
  className?: string;
  icon: string;
  id?: string;
};

export function Icon({ alt, className, icon, id }: IconProps) {
  return (
    <>
      <span className="sr-only">{alt}</span>
      <svg id={id} className={className}>
        <use href={`/assets/icons/sprite.svg#${icon}`}></use>
      </svg>
    </>
  );
}
