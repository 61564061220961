import {
  CreateReviewCommentRequest,
  V1CommentsReviewsCreateData,
  V1CommentsReviewsListData,
  V1CommentsReviewsListParams,
} from '~/api/data-contracts';
import { QueryParamsType } from '~/api/http-client';
import { api } from '~/utils/api';

const URL = {
  COMMENTS: (params: QueryParamsType) =>
    `/comments/reviews?${new URLSearchParams(params).toString()}`,
  CREATE_COMMENT: '/comments/reviews',
  LIKE_COMMENT: (id: number) => `/likes/comments/${id}/like`,
  DISLIKE_COMMENT: (id: number) => `/likes/comments/${id}/dislike`,
  UNLIKE_COMMENT: (id: number) => `/likes/comments/${id}/unlike`,
};

export const commentsModel = {
  reviewComments: (request: V1CommentsReviewsListParams) =>
    api.get<V1CommentsReviewsListData>(URL.COMMENTS(request)),
  createReviewComment: (request: CreateReviewCommentRequest) =>
    api.post<V1CommentsReviewsCreateData>(URL.CREATE_COMMENT, request),
  createAdComment: (request: CreateReviewCommentRequest) =>
    api.post<V1CommentsReviewsCreateData>(URL.CREATE_COMMENT, request),
  likeComment: (id: number) => api.patch(URL.LIKE_COMMENT(id)),
  dislikeComment: (id: number) => api.patch(URL.DISLIKE_COMMENT(id)),
  unlikeComment: (id: number) => api.patch(URL.UNLIKE_COMMENT(id)),
};
