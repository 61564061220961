import { InferType, ObjectSchema, array, lazy, mixed, number, object, string } from 'yup';
import { RateTime, ReviewStatus } from '~/api/data-contracts';
import { ImageType } from '~/types/sharedTypes';
import i18n from '~/i18n';

const requiredMessage = i18n.t('validation.required');

const fileSchema: ObjectSchema<ImageType> = object().shape({
  file: lazy((value) => {
    if (value instanceof File) {
      return mixed<File>().required(requiredMessage);
    }

    return string().required(requiredMessage);
  }),
  url: string().required(requiredMessage),
});

const sectionSchema = object({
  sectionNo: number(),
  rate: number(),
});

const rateSchema = object({
  time: mixed<RateTime>().oneOf(Object.values(RateTime)).nullable(),
  incall: string(),
});

export const reviewSchema = object({
  adId: string().nullable(),
  externalUrl: string().when('adId', ([adId], schema) => {
    if (!adId) {
      return schema.required(requiredMessage);
    }
    return schema;
  }),
  status: mixed<ReviewStatus>().nullable().oneOf(Object.values(ReviewStatus)),
  name: string().required(requiredMessage),
  description: string().required(requiredMessage),
  countryNo: number().required(requiredMessage).moreThan(0, requiredMessage),
  locationNo: number().required(requiredMessage).moreThan(0, requiredMessage),
  currencyNo: number().required(requiredMessage).moreThan(0, requiredMessage),
  files: array().of(fileSchema).required(requiredMessage).min(1, i18n.t('validation.oneImage')),
  sections: array()
    .of(sectionSchema)
    .required(requiredMessage)
    .test({
      message: i18n.t('validation.oneRating'),
      test: (rates) => rates.some((rating) => rating && rating.rate && rating.sectionNo),
    }),
  rates: array()
    .of(rateSchema)
    .required()
    .test({
      message: i18n.t('validation.oneRate'),
      test: (rates) => rates.some((rate) => rate && rate.incall && rate.time),
    }),
});

export type ReviewFormValues = InferType<typeof reviewSchema>;

export const reviewInternalSchema = object({
  status: mixed<ReviewStatus>().nullable().oneOf(Object.values(ReviewStatus)),
  name: string().required(requiredMessage),
  description: string().required(requiredMessage),
  countryNo: number().required(requiredMessage).moreThan(0, requiredMessage),
  locationNo: number().required(requiredMessage).moreThan(0, requiredMessage),
  sections: array()
    .of(sectionSchema)
    .required(requiredMessage)
    .test({
      message: i18n.t('validation.oneRating'),
      test: (rates) => rates.some((rating) => rating && rating.rate && rating.sectionNo),
    }),
});

export type ReviewInternalFormValues = InferType<typeof reviewInternalSchema>;
