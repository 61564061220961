import { toast } from 'material-react-toastify';
import { useNavigate } from 'react-router-dom';
import {
  AdType,
  AvailableFor,
  BreastSize,
  BreastType,
  Ethnicity,
  EyeColor,
  HairColor,
  HairLenght,
  MeetingWith,
  Orientation,
  Piercing,
  PubicHair,
  RateTime,
  Smoker,
  Travel,
} from '~/api/data-contracts';
import { adModel } from '~/models/adModel/adModel';
import { ROUTES } from '~/router/Routes';
import { prepareFormDataForImages } from '../../utils/images';
import AdForm from '../AdForm/AdForm';
import { AdFormValues } from '../AdForm/adSchema';
import { useTranslation } from 'react-i18next';

export default function CreateAd() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const defaultValues: AdFormValues = {
    adType: AdType.Female,
    age: 25,
    availableFor: AvailableFor.Incall,
    breastSize: BreastSize.B,
    breastType: BreastType.Natural,
    countryNo: 0,
    description: '',
    dickSize: null,
    ethnicity: Ethnicity.Caucasian,
    eyeColor: EyeColor.Brown,
    hairColor: HairColor.Brown,
    hairLenght: HairLenght.Medium,
    height: 170,
    isPornStar: false,
    locationNo: 0,
    meetingWith: MeetingWith.Man,
    name: '',
    nationalityNo: 0,
    orientation: Orientation.Straight,
    piercing: Piercing.No,
    pubicHair: PubicHair.Natural,
    smoker: Smoker.No,
    tattoo: false,
    travel: Travel.Countrywide,
    weight: 60,
    currencyNo: 0,
    languages: [],
    files: [],
    services: [
      {
        serviceNo: 0,
        price: null,
      },
    ],
    rates: [
      {
        time: RateTime.HalfAHour,
        incall: null,
        outcall: null,
      },
    ],
    phones: [
      {
        countryNo: 0,
        isTelegram: false,
        isWhatsApp: false,
        isWeChat: false,
        isViber: false,
        isLine: false,
        isSignal: false,
        number: '',
      },
    ],
  };

  const handleSubmit = async (values: AdFormValues) => {
    try {
      const { id } = await adModel.createAdBase({
        adType: values.adType,
        age: values.age,
        availableFor: values.availableFor,
        breastSize: values.breastSize,
        breastType: values.breastType,
        currencNo: values.currencyNo,
        description: values.description,
        dickSize: values.dickSize,
        ethnicity: values.ethnicity,
        eyeColor: values.eyeColor,
        hairColor: values.hairColor,
        hairLenght: values.hairLenght,
        height: values.height,
        isPornStar: values.isPornStar,
        locationNo: values.locationNo,
        meetingWith: values.meetingWith,
        name: values.name,
        nationalityNo: values.nationalityNo,
        orientation: values.orientation,
        piercing: values.piercing,
        pubicHair: values.pubicHair,
        smoker: values.smoker,
        travel: values.travel,
        weight: values.weight,
        tattoo: values.tattoo,
        languages: values.languages,
        services: values.services,
        rates: values.rates,
        phones: values.phones,
      });

      await adModel.editAdImages(id, prepareFormDataForImages(values.files));

      toast.success(t('ads.createAdSuccess'));
      navigate(ROUTES.AD_LIST_PERSONAL);
    } catch (e) {
      toast.error(t('general.error'));
    }
  };

  return <AdForm defaultValues={defaultValues} onSubmit={handleSubmit} title={t('ads.createAd')} />;
}
