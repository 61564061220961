import { useState } from 'react';
import Drawer from 'react-modern-drawer';
import { Icon } from '~/components/Icon/Icon';
import useScreenSize from '~/hooks/useScreenSIze';
import { useReviewFilters } from '../../hooks/useReviewFilters/useReviewFilters';
import ReviewListFilteringForm, { ReviewListFilteringFormValues } from './ReviewListFilteringForm';

export default function ReviewListFiltering() {
  const [isOpen, setIsOpen] = useState(false);
  const { isAnyFilterSet, formValue, onSubmit } = useReviewFilters();
  const { height } = useScreenSize();

  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };

  const handleCancel = () => {
    toggleDrawer();
  };

  const handleSubmit = (values: ReviewListFilteringFormValues) => {
    toggleDrawer();
    onSubmit(values);
  };

  const handleReset = (values: ReviewListFilteringFormValues) => {
    toggleDrawer();
    onSubmit(values);
  };

  return (
    <div>
      <button
        className="relative flex h-[60px] w-[60px] items-center justify-center rounded-lg bg-primary text-[#999999] cursor-pointer"
        onClick={toggleDrawer}
      >
        {isAnyFilterSet && <span className="absolute bg-pink w-2 h-2 rounded-full top-2 right-2" />}
        <Icon alt="Sorting" icon="icon-filter" className="w-6 h-6" />
      </button>

      <Drawer
        open={isOpen}
        onClose={toggleDrawer}
        className="!bg-secondary !w-full xl:!w-[500px]"
        style={{ height }}
        direction="right"
        zIndex={10002}
        lockBackgroundScroll
      >
        <ReviewListFilteringForm
          key={isOpen.toString()}
          onCancel={handleCancel}
          onReset={handleReset}
          onSubmit={handleSubmit}
          value={formValue}
        />
      </Drawer>
    </div>
  );
}
