import { QueryObserverResult, useMutation } from '@tanstack/react-query';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  LimitedResultGetReviewCommentsQueryResult,
  V1CommentsReviewsListData,
} from '~/api/data-contracts';
import { useAuth } from '~/context/AuthContext';
import { commentsModel } from '~/models/commentsModel/commentsModel';
import { getAvatarImageUrl } from '~/utils/images';
import Comment, { CommentType } from './Comment';

type CommentProps = {
  data: V1CommentsReviewsListData;
  onCreate: (comment: string, parentNo?: number) => Promise<void>;
  onLike: () => Promise<QueryObserverResult<LimitedResultGetReviewCommentsQueryResult, unknown>>;
};

const Comments = ({ data, onCreate, onLike }: CommentProps) => {
  const [comment, setComment] = useState('');
  const { user } = useAuth();

  const { mutateAsync: likeComment } = useMutation(commentsModel.likeComment);
  const { mutateAsync: dislikeComment } = useMutation(commentsModel.dislikeComment);
  const { mutateAsync: unlikeComment } = useMutation(commentsModel.unlikeComment);

  const { t } = useTranslation();

  const handleLike = async (currentComment: CommentType) => {
    if (!currentComment.no) {
      return;
    }
    if (currentComment.isCurrentUserLiked === true) {
      await unlikeComment(currentComment.no);
    } else {
      await likeComment(currentComment.no);
    }
    onLike();
  };

  const handleDislike = async (currentComment: CommentType) => {
    if (!currentComment.no) {
      return;
    }
    if (currentComment.isCurrentUserLiked === false) {
      await unlikeComment(currentComment.no);
    } else {
      await dislikeComment(currentComment.no);
    }
    onLike();
  };

  return (
    <div className="flex flex-1 flex-shrink-0 flex-col overflow-hidden rounded-lg bg-primary lg:flex-initial">
      <div className="inline-flex h-12 w-full items-center justify-center border-b-[1px] border-secondary text-[#999999] text-xs font-medium">
        Komentarai
      </div>
      <div className="inline-flex flex-1 gap-2.5 px-7 pt-7">
        <div className="flex-shrink-0">
          <img
            className="mt-1 inline-block h-8 w-8 rounded-full"
            src={
              user?.avatar ? getAvatarImageUrl(user.avatar) : '/assets/images/user-placeholder.jpg'
            }
            alt={t('comments.profilePicture')}
          />
        </div>
        <div className="flex-1">
          <textarea
            name="comment"
            id="comment"
            rows={3}
            className="form-control-input w-full rounded-lg border-[#272727] bg-secondary text-left text-white placeholder:text-[#999999]"
            placeholder={t('comments.whatYouThink')}
            value={comment}
            onChange={(e) => {
              setComment(e.target.value);
            }}
          />
          {comment && (
            <button
              className="btn-rounded float-right w-1/2 border-transparent bg-[#0E9B57] py-2.5 text-sm transition duration-150 ease-linear hover:bg-[#0C844A]"
              onClick={async () => {
                await onCreate(comment);
                setComment('');
              }}
            >
              {t('comments.post')}
            </button>
          )}
        </div>
      </div>

      <div className="inline-flex items-center justify-between p-7">
        <span className="text-sm text-[#999999]">
          {t('comments.comments', { count: data.totalCount })}
        </span>
        {/* <div className="dropdown relative z-[999]" data-comment="true" data-hover="true">
          <div className="dropdown-toggle inline-flex h-9 items-center rounded-full bg-[#272727] px-5 text-white">
            <span className="text-sm font-medium">Nuo naujausių</span>
            <Icon
              alt="caret"
              icon="icon-caret"
              id="caret"
              className="ml-5 h-2.5 w-2.5 text-[#999999] dropdown-caret"
            />
          </div>
          <div className="dropdown-content absolute top-9 right-0 flex hidden w-full flex-col overflow-hidden rounded-b-lg border-t-[1px] border-primary bg-[#272727]">
            <a
              href="#"
              className="font-regular flex flex-1 items-center py-2.5 px-5 text-xs text-[#999999] transition duration-150 ease-linear hover:text-white"
            >
              Nuo naujausių
            </a>
            <a
              href="#"
              className="font-regular flex flex-1 items-center py-2.5 px-5 text-xs text-[#999999] transition duration-150 ease-linear hover:text-white"
            >
              Nuo seniausių
            </a>
            <a
              href="#"
              className="font-regular flex flex-1 items-center py-2.5 px-5 text-xs text-[#999999] transition duration-150 ease-linear hover:text-white"
            >
              Daugiausiai įvertinti
            </a>
          </div>
        </div>*/}
      </div>

      {data.items?.map((currentComment, index) => (
        <Comment
          comment={currentComment}
          handleLike={handleLike}
          handleDislike={handleDislike}
          onCreate={onCreate}
          key={index}
        />
      ))}
    </div>
  );
};

export default Comments;
