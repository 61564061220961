import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { localStorageKeys } from '~/config/consts';
import { languages, LanguageType } from '~/i18n/i18nConfig';

type MobileLanguageProps = {
  isOpen: boolean;
  onOpen: (value: boolean) => void;
};

export function MobileLanguage({ isOpen, onOpen }: MobileLanguageProps) {
  const { i18n, t } = useTranslation();
  const currentLanguage = i18n.language as LanguageType;

  const changeLanguage = (language: LanguageType) => {
    i18n.changeLanguage(language);
    localStorage.setItem(localStorageKeys.language, language);
    onOpen(false);
  };

  const flagMap = {
    lt: 'lt',
    en: 'gb',
    uk: 'ua',
    ru: 'ru',
    pl: 'pl',
  };

  const getFlag = (lang: LanguageType) => flagMap[lang] || lang;

  return (
    <ul className="mobile-nav-items py-8">
      <li className="mobile-nav-header">{t('header.language')}</li>
      <li className={classnames('mobile-nav-item mobile-nav-expand', { active: isOpen })}>
        <button
          className="mobile-nav-link active mobile-nav-expand-link"
          onClick={() => onOpen(true)}
        >
          <span className={`fi fi-${getFlag(currentLanguage)} h-4 w-4`} />
          <span>{currentLanguage.toUpperCase()}</span>
        </button>
        <ul className="mobile-nav-items mobile-nav-expand-content">
          <li className="mobile-nav-header">{t('header.language')}</li>

          {languages.map((language) => (
            <li
              className={classnames('mobile-nav-item', {
                'mobile-nav-selected': currentLanguage === language,
              })}
              key={language}
            >
              <button onClick={() => changeLanguage(language)} className="mobile-nav-link active">
                <span className={`fi fi-${getFlag(language)} h-4 w-4`} />
                <span>{language.toUpperCase()}</span>
              </button>
            </li>
          ))}
        </ul>
      </li>
    </ul>
  );
}
