import { QueryParamsType } from '~/api/http-client';
import { V1AlphaListParams, V1AlphaPersonalListParams } from '~/api/data-contracts';
import {
  CreateAdBaseRequest,
  CreateAdBaseResponse,
  EditAdBaseRequest,
  GetAdResponse,
  GetAdsPersonalResponse,
  GetAdsResponse,
  GetPersonalAdResponse,
  GetRatesResponse,
  GetServicesResponse,
} from '~/types/ads';
import { Phone, Rate, Service } from '~/types/sharedTypes';
import { api } from '~/utils/api';

const URL = {
  ADS: (params: QueryParamsType) => `/alpha?${new URLSearchParams(params).toString()}`,
  ADS_PERSONAL: (params: QueryParamsType) =>
    `/alpha/personal?${new URLSearchParams(params).toString()}`,
  AD_DETAILED: (id: string) => `/alpha/${id}`,
  AD_ACTIVATE: (id: string) => `/alpha/${id}/activate`,
  AD_DEACTIVATE: (id: string) => `/alpha/${id}/disable`,
  CURRENCIES: '/currencies',
  RATES: '/alpha/rate-time',
  SERVICES: '/alpha/services',
  PERSONAL_AD: (id: string) => `/alpha/${id}/personal`,
  AD_BASE: (id: string) => `/alpha/${id}/base`,
  AD_IMAGES: (id: string) => `/alpha/${id}/images`,
  AD_SERVICES: (id: string) => `/alpha/${id}/services`,
  AD_RATES: (id: string) => `/alpha/${id}/rates`,
  AD_PHONES: (id: string) => `/alpha/${id}/phones`,
  AD_CREATE: '/alpha',
};

export const adModel = {
  ads: (request: V1AlphaListParams) => api.get<GetAdsResponse>(URL.ADS(request)),
  adsPersonal: (request: V1AlphaPersonalListParams) =>
    api.get<GetAdsPersonalResponse>(URL.ADS_PERSONAL(request)),
  adDetailed: (id: string) => api.get<GetAdResponse>(URL.AD_DETAILED(id)),
  adActivate: (id: string) => api.patch(URL.AD_ACTIVATE(id)),
  adDeactivate: (id: string) => api.patch(URL.AD_DEACTIVATE(id)),
  createAdBase: (request: CreateAdBaseRequest) =>
    api.post<CreateAdBaseResponse>(URL.AD_CREATE, request),
  adRates: () => api.get<GetRatesResponse>(URL.RATES),
  adServices: () => api.get<GetServicesResponse>(URL.SERVICES),
  personalAd: (id: string) => api.get<GetPersonalAdResponse>(URL.PERSONAL_AD(id)),
  editAdBase: (id: string, request: EditAdBaseRequest) => api.put(URL.AD_BASE(id), request),
  editAdImages: (id: string, request: FormData) => api.put(URL.AD_IMAGES(id), request),
  editAdServices: (id: string, request: { services: Service[] }) =>
    api.put(URL.AD_SERVICES(id), JSON.stringify(request)),
  editAdRates: (id: string, request: { rates: Rate[] }) =>
    api.put(URL.AD_RATES(id), JSON.stringify(request)),
  editAdPhones: (id: string, request: { phones: Phone[] }) =>
    api.put(URL.AD_PHONES(id), JSON.stringify(request)),
  // -------
  // updateProfile: (data: Partial<CurrentUser>) =>
  //   api.patch(URL.CURRENT_USER, data, {
  //     headers: {
  //       'Content-Type': 'multipart/form-data',
  //     },
  //   }),
  // createNewPassword: (
  //   password: string,
  //   passwordConfirmation: string,
  //   uuid: string | null,
  //   token: string | null,
  //   fromMobileApp: boolean,
  // ) =>
  //   api.post<Partial<ResponseWithDetail>>(
  //     generatePath(fromMobileApp ? URL.CONFIRM_PASSWORD_MOBILE_APP : URL.CONFIRM_PASSWORD, {
  //       uuid,
  //       token,
  //     }),
  //     {
  //       password,
  //       confirm_password: passwordConfirmation,
  //     },
  //   ),
};
