interface GradientProps {
  likes: number;
  dislikes: number;
}

const Gradient = ({ likes, dislikes }: GradientProps) => {
  const total = likes + dislikes || 1;
  const likesPercentage = (likes / total) * 100;
  const dislikesPercentage = 100 - (dislikes / total) * 100 + 15;

  return (
    <div
      style={{
        background: `linear-gradient(90deg, rgba(17, 141, 240, 1) ${likesPercentage}%, rgba(255, 48, 79, 1) ${dislikesPercentage}%)`,
      }}
      className="flex-shrink-0 w-full h-0.5 gradient"
    />
  );
};

export default Gradient;
