import { InputHTMLAttributes } from 'react';
import { Controller, FieldPath, FieldValues, UseControllerProps } from 'react-hook-form';

type FormRadioProps<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = {
  label: string;
  onChange?: (value: boolean) => void;
} & UseControllerProps<TFieldValues, TName> &
  Omit<InputHTMLAttributes<HTMLInputElement>, 'value' | 'onChange'>;

const FormCheckbox = <
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  control,
  label,
  name,
  onChange,
  ...inputProps
}: FormRadioProps<TFieldValues, TName>) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        return (
          <div className="form-check pl-5">
            <input
              className="form-check-input"
              type="checkbox"
              id={name}
              checked={field.value}
              onChange={(e) => field.onChange(e.target.checked)}
              {...inputProps}
            />
            <label className="form-check-label pl-2 text-[#999999] text-sm" htmlFor={name}>
              {label}
            </label>
          </div>
        );
      }}
    />
  );
};

export default FormCheckbox;
