import { V1ReputationListData } from '~/api/data-contracts';
import { api } from '~/utils/api';

const URL = {
  REPUTATION: '/reputation',
};

export const reputationModel = {
  reputation: () => api.get<V1ReputationListData>(URL.REPUTATION),
};
