import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { Link, generatePath } from 'react-router-dom';
import { GetCurrentUserQueryResult, UserType, V1UsersDetailData } from '~/api/data-contracts';
import { ReactComponent as Badge } from '~/assets/badges/badge (14).svg';
import { Icon } from '~/components/Icon/Icon';
import { Loader } from '~/components/Loader/Loader';
import { MODAL_IDS } from '~/config/consts';
import { useAuth } from '~/context/AuthContext';
import { useModals } from '~/context/ModalContext';
import { getAdImageUrl } from '~/features/ads/utils/images';
import { getReviewImageUrl } from '~/features/reviews/utils/images';
import { adModel } from '~/models/adModel/adModel';
import { reviewModel } from '~/models/reviewModel/reviewModel';
import { ROUTES } from '~/router/Routes';
import { formatDateShort, formatDateTime } from '~/utils/formatDate';
import { getAvatarImageUrl } from '~/utils/images';

type UserProfileProps = {
  userId: string;
  user: V1UsersDetailData | GetCurrentUserQueryResult;
};

export default function UserProfile({ user, userId }: UserProfileProps) {
  const { hasOneOfRoles, user: currentUser } = useAuth();
  const { showModal } = useModals();

  const { data: reviews, isLoading: reviewsIsLoading } = useQuery(
    [userId],
    () => reviewModel.reviews({ Limit: 10, UserId: userId }),
    { enabled: user.userType === UserType.Reviewer && hasOneOfRoles([UserType.Reviewer]) },
  );

  const { data: ads, isLoading: adsIsLoading } = useQuery(
    [userId],
    () => adModel.ads({ Limit: 10, UserId: userId }),
    { enabled: user.userType === UserType.Agency || user.userType === UserType.Independent },
  );

  const { t } = useTranslation();

  return (
    <main className="relative flex flex-1 flex-col gap-14 py-14 px-2.5 lg:px-14 xl:gap-16">
      <span className="text-lg font-medium text-white lg:text-2xl">{t('userProfile.title')}</span>
      <div className="flex flex-col items-start gap-7 lg:flex-row">
        <div className="flex w-full flex-col lg:w-5/12 gap-7">
          <div className="flex w-full flex-col overflow-hidden rounded-lg bg-primary">
            <div className="flex flex-1 flex-col items-center justify-center p-7">
              <div className="h-32 w-32 overflow-hidden rounded-full">
                <img
                  alt="Profile Picture"
                  src={
                    user?.avatar
                      ? getAvatarImageUrl(user.avatar)
                      : '/assets/images/user-placeholder.jpg'
                  }
                  className="object-cover h-full w-full"
                />
              </div>

              <div className="space-y-2.5 pt-7">
                <div className="flex justify-center gap-2.5 break-all">
                  <span className="block text-center text-xl font-medium text-white">
                    {user?.nickName}
                  </span>
                  {user.isVeteran && (
                    <Badge className="text-pink h-8 w-8" title={t('honor.veteran')} />
                  )}
                </div>

                {'id' in user && (
                  <span className="block text-center text-sm font-medium text-[#999999] select-all">
                    {user?.id}
                  </span>
                )}

                <span className="block text-center text-sm font-medium text-[#999999]">
                  {t('userProfile.registered')}: {user?.created && formatDateShort(user.created)}
                </span>
                {'expireAt' in user && (
                  <span className="block text-center text-sm font-medium text-[#999999]">
                    {t('userProfile.expiresAt')}:{' '}
                    {user?.expireAt ? formatDateShort(user?.expireAt) : 'Never activated'}
                  </span>
                )}
              </div>
            </div>
            <div className="flex flex-1 divide-x-[1px] divide-secondary border-t-[1px] border-b-[1px] border-secondary">
              {user.userType === UserType.Reviewer && (
                <div className="flex flex-1 flex-col gap-1.5 py-5 transition duration-150 ease-linear">
                  <div className="flex flex-col items-center gap-1">
                    <Icon
                      alt={t('userProfile.reviewsPublished')}
                      icon="icon-messages-star"
                      className="block w-4 h-4 text-[#999999]"
                    />
                    <span className="block text-center text-sm font-medium text-[#999999] backface-hidden">
                      {t('userProfile.reviewsPublished')}
                    </span>
                  </div>
                  <span className="text-center text-base font-medium text-white backface-hidden">
                    {user?.reviewData?.currentlyPublished}
                  </span>
                </div>
              )}
              {(user.userType === UserType.Agency || user.userType === UserType.Independent) && (
                <div className="flex flex-1 flex-col gap-1.5 py-5 transition duration-150 ease-linear">
                  <div className="flex flex-col items-center gap-1">
                    <Icon
                      alt={t('userProfile.adsPublished')}
                      icon="icon-megaphone"
                      className="block w-4 h-4 text-[#999999]"
                    />
                    <span className="block text-center text-sm font-medium text-[#999999] backface-hidden">
                      {t('userProfile.adsPublished')}
                    </span>
                  </div>
                  <span className="text-center text-base font-medium text-white backface-hidden">
                    {user?.adData?.currentlyPublished}
                  </span>
                </div>
              )}
              <div className="flex flex-1 flex-col gap-1.5 py-5 transition duration-150 ease-linear">
                <div className="flex flex-col items-center gap-1">
                  <Icon alt="Flower" icon="icon-forum" className="block w-4 h-4 text-[#999999]" />
                  <span className="block text-center text-sm font-medium text-[#999999] backface-hidden">
                    {t('userProfile.comments')}
                  </span>
                </div>
                <span className="text-center text-base font-medium text-white backface-hidden">
                  {user?.commentData?.currentlyCreatedComments}
                </span>
              </div>
              <div className="flex flex-1 flex-col gap-1.5 py-5 transition duration-150 ease-linear">
                <div className="flex flex-col items-center gap-1">
                  <Icon
                    alt="Flower"
                    icon="icon-star-filled"
                    className="block w-4 h-4 text-[#FCC417]"
                  />
                  <span className="block text-center text-sm font-medium text-[#999999] backface-hidden">
                    {t('userProfile.followedReviews')}
                  </span>
                </div>
                <span className="text-center text-base font-medium text-white backface-hidden">
                  {user?.reviewData?.followingReviews}
                </span>
              </div>
            </div>

            {hasOneOfRoles([UserType.Reviewer]) && userId !== currentUser?.id && (
              <div className="flex flex-col gap-3 py-7 px-3.5">
                <button
                  className="btn-rounded h-12 w-fit bg-pink transition duration-150 ease-linear hover:bg-[#C60D4F] px-6"
                  onClick={() =>
                    showModal(MODAL_IDS.transferCredits, { userId, userName: user.nickName })
                  }
                  role="button"
                >
                  {t('credits.supportAction')}
                </button>
                {/* <a
              href="#"
              className="btn-rounded border-transparent bg-[#0E9B57] transition duration-150 ease-linear hover:bg-[#0C844A]"
            >
              Siųsti žinutę
            </a> */}
              </div>
            )}
          </div>

          {userId === currentUser?.id && (
            <div className="flex w-full flex-col overflow-hidden rounded-lg bg-primary">
              <div className="w-full py-3.5 text-center text-xs font-medium text-[#999999]">
                {t('credits.creditBalance')}
              </div>

              {'balance' in user && (
                <div className="flex items-center justify-center w-full h-[50px]">
                  <div>
                    <span className="text-2xl text-white mr-1">
                      {user.balance.balance.toFixed(2)}
                    </span>
                    <span className="text-sm text-gray">{t('credits.credits')}</span>
                  </div>
                </div>
              )}

              <div className="flex flex-col gap-3 py-7 px-3.5">
                <button
                  className="btn-rounded h-12 w-fit bg-pink transition duration-150 ease-linear hover:bg-[#C60D4F] px-6"
                  onClick={() => showModal(MODAL_IDS.buyCredits)}
                  role="button"
                >
                  {t('credits.buyCredits')}
                </button>
              </div>
            </div>
          )}
        </div>

        {user.userType === UserType.Reviewer && hasOneOfRoles([UserType.Reviewer]) && (
          <div className="flex w-full flex-col gap-7 lg:w-7/12">
            <div className="flex w-full flex-col divide-y-[1px] divide-secondary overflow-hidden rounded-lg bg-primary">
              <div className="w-full py-3.5 text-center text-xs font-medium text-[#999999]">
                {t('userProfile.userReviews')}
              </div>

              {reviewsIsLoading ? (
                <Loader />
              ) : (
                reviews?.items?.map((review, index) => (
                  <Link
                    key={index}
                    className="group inline-flex items-center gap-3 lg:gap-5 py-5 px-3 lg:px-7 transition duration-150 ease-linear hover:bg-secondary"
                    to={generatePath(ROUTES.REVIEW_DETAILED, { id: review.id })}
                  >
                    <img
                      alt="Review Picture"
                      src={
                        review.image && review.id
                          ? getReviewImageUrl(review.id, review.image)
                          : '/assets/images/image-placeholder.jpg'
                      }
                      className="object-cover h-16 w-16 rounded-md"
                    />
                    <div className="flex flex-1 flex-col gap-2.5">
                      <div className="text-base font-medium text-white transition duration-150 ease-linear backface-hidden group-hover:text-pink">
                        {review.name}
                      </div>
                      <div className="inline-flex items-center gap-5 text-[#999999] transition duration-150 ease-linear group-hover:text-white">
                        <div className="inline-flex items-center gap-1">
                          <Icon alt="Location" icon="icon-location" className="block w-4 h-4" />
                          <span className="text-xs backface-hidden">
                            {review.country}, {review.town}
                          </span>
                        </div>
                        <div className="inline-flex items-center gap-1">
                          <Icon alt="Location" icon="icon-time" className="block w-4 h-4 " />
                          <span className="text-xs backface-hidden">
                            {review.createdAt && formatDateTime(new Date(review.createdAt))}
                          </span>
                        </div>
                      </div>
                    </div>
                  </Link>
                ))
              )}
            </div>
          </div>
        )}

        {(user.userType === UserType.Agency || user.userType === UserType.Independent) && (
          <div className="flex w-full flex-col gap-7 lg:w-7/12">
            <div className="flex w-full flex-col divide-y-[1px] divide-secondary overflow-hidden rounded-lg bg-primary">
              <div className="w-full py-3.5 text-center text-xs font-medium text-[#999999]">
                {t('userProfile.userAds')}
              </div>

              {adsIsLoading ? (
                <Loader />
              ) : (
                ads?.items?.map((ad, index) => (
                  <Link
                    key={index}
                    className="group inline-flex items-center gap-3 lg:gap-5 py-5 px-3 lg:px-7 transition duration-150 ease-linear hover:bg-secondary"
                    to={generatePath(ROUTES.AD_DETAILED, { id: ad.id })}
                  >
                    <img
                      alt="Review Picture"
                      src={
                        ad.image
                          ? getAdImageUrl(ad.id, ad.image)
                          : '/assets/images/image-placeholder.jpg'
                      }
                      className="object-cover h-16 w-16 rounded-md"
                    />
                    <div className="flex flex-1 flex-col gap-2.5">
                      <div className="text-base font-medium text-white transition duration-150 ease-linear backface-hidden group-hover:text-pink">
                        {ad.name}
                      </div>
                      <div className="inline-flex items-center gap-5 text-[#999999] transition duration-150 ease-linear group-hover:text-white">
                        <div className="inline-flex items-center gap-1">
                          <Icon alt="Location" icon="icon-location" className="block w-4 h-4" />
                          <span className="text-xs backface-hidden">
                            {ad.country}, {ad.town}
                          </span>
                        </div>
                        <div className="inline-flex items-center gap-1">
                          <Icon alt="Location" icon="icon-time" className="block w-4 h-4 " />
                          <span className="text-xs backface-hidden">
                            {ad.createdAt && formatDateTime(new Date(ad.createdAt))}
                          </span>
                        </div>
                      </div>
                    </div>
                  </Link>
                ))
              )}
            </div>
          </div>
        )}
      </div>
    </main>
  );
}
