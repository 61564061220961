export const ROUTES = {
  DEFAULT: '/',

  AD_LIST: '/ads',
  AD_LIST_PERSONAL: '/ads/personal',
  AD_DETAILED: '/ads/:id',
  AD_CREATE: '/ads/create',
  AD_EDIT: '/ads/:id/edit',

  REVIEWS_LIST: '/reviews',
  REVIEW_LIST_PERSONAL: '/reviews/personal',
  REVIEW_DETAILED: '/reviews/:id',
  REVIEW_CREATE_INTERNAL: '/reviews/create/:adId',
  REVIEW_CREATE_EXTERNAL: '/reviews/create',
  REVIEW_EDIT: '/reviews/:id/edit',

  RESET_PASSWORD: '/reset-password/:id',

  USER: '/user/:id',
  USER_PROFILE: '/user-profile',
  USER_SETTINGS: '/user-settings',

  HONOR: '/honor',

  ERROR_404: '/404',
};
