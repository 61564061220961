import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import UserSettingsActivation from './UserSettingsActivation';
import UserSettingsDropdown from './UserSettingsDropdown';
import UserSettingsImage from './UserSettingsImage';
import UserSettingsPassword from './UserSettingsPassword';
import UserSettingsTwoFactor from './UserSettingsTwoFactor';

export default function UserSettings() {
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();

  const tab = searchParams.get('tab');

  const getTab = () => {
    switch (tab) {
      default:
      case 'picture':
        return <UserSettingsImage />;
      case 'password':
        return <UserSettingsPassword />;
      case 'activate':
        return <UserSettingsActivation />;
      // case 'twoFactor':
      //   return <UserSettingsTwoFactor />;
    }
  };

  return (
    <div className="relative flex flex-1 flex-col gap-14 py-14 px-2.5 lg:px-14 xl:gap-16">
      <span className="text-lg font-medium text-white lg:text-2xl">
        {t('userProfile.myProfile')}
      </span>
      <div className="flex flex-col items-start gap-7 lg:flex-row">
        <div className="flex w-full flex-col overflow-hidden rounded-lg bg-primary lg:w-5/12">
          <UserSettingsDropdown key={tab} />
        </div>

        <div className="flex w-full flex-col gap-7 lg:w-7/12">{getTab()}</div>
      </div>
    </div>
  );
}
