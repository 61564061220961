import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import classNames from 'classnames';
import { toast } from 'material-react-toastify';
import { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Icon } from '~/components/Icon/Icon';
import { useAuth } from '~/context/AuthContext';
import { usersModel } from '~/models/usersModel/usersModel';
import { getAvatarImageUrl } from '~/utils/images';
import { useTranslation } from 'react-i18next';

export default function UserSettingsImage() {
  const { user, fetchUser } = useAuth();
  const { t } = useTranslation();
  const [file, setFile] = useState<{ file: File; url: string } | null>(null);
  const { mutate, isLoading } = useMutation(usersModel.userAvatar);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      'image/png': ['.png'],
      'image/jpg': ['.jpg'],
      'image/jpeg': ['.jpeg'],
    },
    multiple: false,
    onDrop: (acceptedFiles) => {
      const f = acceptedFiles[0];

      if (!f) {
        return;
      }

      setFile({
        file: f,
        url: URL.createObjectURL(f),
      });
    },
  });

  const { className: rootClassName, ...rootProps } = getRootProps({ className: 'dropzone' });
  const { style, ...inputProps } = getInputProps();

  const handleSave = async () => {
    if (file) {
      const formData = new FormData();

      formData.append('file', file.file);

      mutate(formData, {
        onError: (e) => {
          if (e instanceof AxiosError) {
            const responseErrors = e.response?.data?.errors;

            if (!responseErrors) {
              return;
            }

            if (Array.isArray(responseErrors)) {
              toast.error(responseErrors[0].message);
            }
          } else {
            toast.error(t('general.error'));
          }
        },
        onSuccess: async () => {
          toast.success(t('userProfile.saveProfilePictureSuccess'));
          await fetchUser();
          setFile(null);
        },
      });
    }
  };

  const avatarUrl = file
    ? file.url
    : user?.avatar
    ? getAvatarImageUrl(user.avatar)
    : '/assets/images/user-placeholder.jpg';

  return (
    <div className="flex w-full flex-col divide-y-[1px] divide-secondary overflow-hidden rounded-lg bg-primary">
      <div className="w-full py-3.5 text-center text-xs font-medium text-[#999999]">
        {t('userProfile.profilePicture')}
      </div>
      <div className="flex flex-col items-center gap-7 px-14 pt-7 pb-14">
        <div className="h-60 w-60 overflow-hidden rounded-full">
          <img alt="Profile Picture" src={avatarUrl} className="object-cover h-full w-full" />
        </div>
        <div
          className={classNames(
            'relative rounded-lg border-[2px] border-dashed border-[#272727] transition duration-150 ease-linear hover:bg-secondary',
            rootClassName,
          )}
          {...rootProps}
        >
          <input
            type="file"
            multiple
            className="relative z-50 block cursor-pointer opacity-0 h-48 w-full"
            {...inputProps}
          />
          <div className="absolute top-0 right-0 left-0 m-auto flex h-full w-full flex-col items-center justify-center gap-3.5">
            <Icon alt="FileUpload" icon="icon-file-upload" className="h-8 w-8 text-white" />
            <div className="hidden flex-col items-center lg:flex">
              <p className="font-regular text-white text-center">{t('imageUpload.dropzone')}</p>
            </div>
            <div className="flex flex-col items-center lg:hidden">
              <p className="font-bold text-white text-center">{t('imageUpload.selectFiles')}</p>
            </div>
          </div>
        </div>
        <button
          className="btn-rounded border-transparent bg-[#0E9B57] transition duration-150 ease-linear hover:bg-[#0C844A]"
          disabled={!file || isLoading}
          onClick={handleSave}
        >
          {t('userProfile.saveProfilePicture')}
        </button>
      </div>
    </div>
  );
}
